import React from 'react';
import { SerializerContextGroupProps } from './SerializerContextGroup.types';
import { ConfigEntryView } from '../ConfigEntryView';

const SerializerContextGroup: React.FC<SerializerContextGroupProps> = ({
                                                                           id,
                                                                           label,
                                                                           params,
                                                                           onAddItem,
                                                                           onChange,
                                                                           onDelete
                                                                       }) => {
    const handleOnChange = (key: string, value: string) => {
        if (onChange) {
            onChange(key, value);
        }
    };

    const handleOnDelete = (key: string) => {
        if (onDelete) {
            onDelete(key);
        }
    };

    const configs = params.map(entry => {
        return (
            <li key={`li|${entry.key}`}>
                <ConfigEntryView key={entry.key}
                                 id={entry.key}
                                 value={entry.value}
                                 onChange={event => handleOnChange(entry.key, event.target.value)}
                                 onDelete={() => handleOnDelete(entry.key)}/>
            </li>
        );
    }) as React.ReactElement[];

    return (
        <div className='mgmt-serializer-form-group'>
            <label htmlFor={id}>{label}</label>
            <ul id={id}>
                {configs}
                <li><input type='button' value='Add' onClick={onAddItem}/></li>
            </ul>
        </div>
    );
}

export default SerializerContextGroup;
