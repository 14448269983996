import React from "react";
import { CollapseIconProps, Direction } from "./MenuCollapseIcon.types";

import './MenuCollapseIcon.css';

const styleForDirection = (direction: Direction) => {
    return {
        transform: `rotate(${direction.toString()}deg)`,
    }
}

const MenuCollapseIcon: React.FC<CollapseIconProps> = ({direction = Direction.Down, color = "#FFFFFF"}) => {
    return (
        <div className="mgmt-menu-icon-wrapper">
            <svg className="mgmt-menu-icon" style={styleForDirection(direction)} viewBox="0 0 24 24">
                <path fill={color} d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
            </svg>
        </div>
    );
}


export default MenuCollapseIcon;
