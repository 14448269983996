import React from 'react';
import { BaseModalDialogProps } from "./BaseModalDialog.types";

import './BaseModalDialog.css';

const BaseModalDialog: React.FC<BaseModalDialogProps> = ({isOpen, children, onCancel}) => {
    const [dialogOpen, setDialogOpen] = React.useState(isOpen);
    const modalRef = React.useRef<HTMLDialogElement | null>(null);

    React.useEffect(() => {
        setDialogOpen(isOpen);
    }, [isOpen]);

    React.useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            if (dialogOpen) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }
    }, [dialogOpen]);

    const onKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            if (onCancel) {
                onCancel();
            }
        }
    };

    return (
        <dialog ref={modalRef} className="mgmt-dialog-modal" onKeyDown={onKeyDown}>
            {children}
        </dialog>
    );
}

export default BaseModalDialog;
