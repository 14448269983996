import React, { useEffect } from 'react';

import { FormGroupProps } from './FormGroup.types';

import './FormGroup.css';
import '../../../common.css';

const FormGroup: React.FC<FormGroupProps> = (props) => {
    const ref = React.useRef({} as HTMLInputElement);
    const customValidate = props.customValidate;
    const value = props.value;
    useEffect(() => {
        const element = ref.current;
        if (customValidate) {
            customValidate(element);
        }

        const handleValidate = () => {
            if (customValidate) {
                customValidate(element);
            }
        }
        element.addEventListener('input', handleValidate);
        return () => {
            element.removeEventListener('input', handleValidate);
        }
    }, [customValidate, value]);

    const labelClass = props.requiredField ? 'mgmt-required-field' : '';
    const checked = props.inputType == 'checkbox' && props.value == 'true';

    return (
        <div className='mgmt-form-group'>
            <label htmlFor={props.id} className={labelClass}>{props.label}</label>
            <input ref={ref}
                type={props.inputType}
                id={props.id}
                name={props.name}
                value={value}
                placeholder={props.placeholder}
                required={props.required}
                onChange={props.onChange}
                disabled={props.disabled}
                step={props.step}
                checked={checked}
                min={props.min}
                pattern={props.pattern}></input>
        </div>
    )
};

export default FormGroup;
