import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { AppContext } from '../context';
import { FlagsType } from './Logs.types';
import { LogSearchEntry } from '../../services/api.types';
import { Log } from '../../services/log.types';
import { LoadingSpinner, ShowIf } from '../../components/common';
import { PaginatedTable, SearchBar } from '../../components/Logs';
import { Menu } from '../../components';

import './Logs.css';

const LogsPage = () => {
	const [isMenuOpen, setMenuOpen] = React.useState(false);
	const [isManagementOpen, setIsManagementOpen] = React.useState(true);
	const [isMonitoringOpen, setMonitoringOpen] = React.useState(true);
	const [isLogoffConfirmationDialogOpen, setIsLogoffConfirmationDialogOpen] = React.useState(false);

	const [logs, setLogs] = useState<Log[]>([]);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [objectId, setObjectId] = useState('');
	const [updateId, setUpdateId] = useState('');
	const [context, setContext] = useState('');
	const [flags, setFlags] = useState<FlagsType>({
		CONFIRMATION: true,
		DISCARDED: true,
		ERROR: true,
		INFO: true,
		WARNING: true
	});
	const [selectAll, setSelectAll] = useState(true);

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 20;
	const searchWindowThreshold = 10;
	const loadedPages = useRef(new Set<number>());
	const abortControllerRef = useRef<AbortController | null>(null);

	const { services: { apiService } } = useContext(AppContext);

	const convertToLog = (searchEntry: LogSearchEntry): Log => {
		return {
			timestamp: searchEntry.timestamp,
			layer: searchEntry.layer,
			state: searchEntry.status,
			message: searchEntry.message,
			updateId: searchEntry.correlationid,
			objectId: searchEntry.objectid,
			component: searchEntry.component,
			context: searchEntry.context
		};
	};

	const handleFlagChange = (flag: keyof FlagsType | 'All') => {
		if (flag === 'All') {
			const newSelectAll = !selectAll;
			setSelectAll(newSelectAll);
			setFlags(Object.keys(flags).reduce((acc: FlagsType, key) => ({
				...acc,
				[key]: newSelectAll
			}), {} as FlagsType));
		} else {
			setFlags(prevFlags => {
				const updatedFlags = {
					...prevFlags,
					[flag]: !prevFlags[flag]
				};
				const allSelected = Object.keys(updatedFlags).map(key => updatedFlags[key as keyof FlagsType]).every(value => value);
				setSelectAll(allSelected);
				return updatedFlags;
			});
		}
	};

    const fetchLogs = useCallback(async (startPage: number, endPage: number) => {
        if (!abortControllerRef.current) {
            return;
        }
        const signal = abortControllerRef.current.signal;
        const areAllFlagsFalse = Object.keys(flags).map(key => flags[key as keyof FlagsType]).every(value => !value);
        if (areAllFlagsFalse) {
            setLogs([]);
            setLoading(false);
            return;
        }

        setLoadingMessage('Loading...');
        setLoading(true);
        setError(false);
        setErrorMessage('');

        try {
            for (let page = startPage; page <= endPage; page++) {
                if (loadedPages.current.has(page)) {
                    continue;
                }

                const offset = (page - 1) * itemsPerPage;
                const requestParams = {
                    offset: offset.toString(),
                    pagesize: itemsPerPage.toString(),
                    objectId,
                    updateId,
                    flags: JSON.stringify(flags),
                    context
                };
                const searchResponse = await apiService.searchLogs(requestParams, signal);
                setLoading(false);
                if (searchResponse.entries.length > 0) {
                    const fetchedLogs = searchResponse.entries.map(convertToLog);
                    setLogs(prevLogs => [...prevLogs, ...fetchedLogs]);
                    loadedPages.current.add(page);
                    if (fetchedLogs.length < itemsPerPage) {
                        break;
                    }
                } else {
                    break;
                }
            }
        } catch (err) {
            if (isAxiosError(err) && err.code === 'ERR_CANCELED') {
                console.log('Fetch aborted');
            } else {
                setError(true);
                setErrorMessage('Failed to fetch logs.');
                console.error(err);
            }
        } finally {
            setLoading(false);
        }
    }, [apiService, itemsPerPage, objectId, updateId, flags, context]);

	const isAxiosError = (error: unknown): error is { code: string } => {
		return typeof error === 'object' && error !== null && 'code' in error;
	};

    const initializeLogs = useCallback(() => {
        setLogs([]);
        setCurrentPage(1);
        loadedPages.current.clear();
        fetchLogs(1, searchWindowThreshold);
    }, [fetchLogs]);

    useEffect(() => {
        abortControllerRef.current = new AbortController();
        initializeLogs();
        return () => {
            abortControllerRef.current?.abort();
        };
    }, [apiService, objectId, updateId, context, flags, initializeLogs]);

    const handleRefresh = useCallback(() => {
        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();
        initializeLogs();
    }, [initializeLogs]);

	const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        const endPage = pageNumber + searchWindowThreshold - 1;
        fetchLogs(pageNumber, endPage);
    };

	return (
		<div className="mgmt-logs">
			<Menu
                isMenuOpen={isMenuOpen}
                setMenuOpen={setMenuOpen}
                isManagementOpen={isManagementOpen}
                setIsManagementOpen={setIsManagementOpen}
                isMonitoringOpen={isMonitoringOpen}
                setMonitoringOpen={setMonitoringOpen}
                isLogoffConfirmationDialogOpen={isLogoffConfirmationDialogOpen}
                setIsLogoffConfirmationDialogOpen={setIsLogoffConfirmationDialogOpen}
            />
            <div className="mgmt-logs-content">
                <div className="mgmt-logs-section-title">
                    <h1>Monitoring</h1>
                </div>
                <div className="mgmt-logs-section-search-form">
                    <SearchBar
                        objectId={objectId}
                        setObjectId={setObjectId}
                        updateId={updateId}
                        setUpdateId={setUpdateId}
                        context={context}
                        setContext={setContext}
                        flags={flags}
                        handleFlagChange={handleFlagChange}
                        selectAll={selectAll}
                        handleRefresh={handleRefresh}
                    />
                </div>
                <div className="mgmt-logs-section-content">
                    <ShowIf expression={isLoading && !loadedPages.current.has(currentPage)}>
                        <LoadingSpinner message={loadingMessage} />
                    </ShowIf>
                    <ShowIf expression={!isLoading && logs.length > 0}>
                        <PaginatedTable
                            logs={logs}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            paginate={paginate}
                        />
                    </ShowIf>
                    <ShowIf expression={!isLoading && logs.length === 0 && !error}>
                        <div>No logs found.</div>
                    </ShowIf>
                    <ShowIf expression={error}>
                        <div>{errorMessage}</div>
                    </ShowIf>
                </div>
            </div>
		</div>
	);
}

export default LogsPage;
