import React, { ChangeEvent } from 'react';
import { TransferHandlerViewProps } from './TransferHandlerView.types';
import { Accordion, ConfirmationDialog, FormGroup } from '../../common';
import SftpTransferHandler from '../SftpTransferHandlerView';
import ZipSftpTransferHandler from '../ZipSftpTransferHandler';
import ZipS3TransferHandler from '../ZipS3TransferHandler';
import S3TransferHandler from '../S3TransferHandlerView';
import AzureTransferHandler from '../AzureTransferHandlerView';
import { TransferHandler } from '../../../services';
import { ConfigItemAction } from '../ConfigItem';
import { ShowIf } from '../../common/Condition';
import { HANDLER_PATTERN } from '../constants';

const getHandlerTitle = (name: string) => {
    return name === '' ? '<UNNAMED>' : name;
}

function getHandlerView(handler: TransferHandler,
    dispatch: (action: ConfigItemAction) => void,
    getFieldId: (fieldName: string) => string) {
    switch (handler.transportType) {
        case 'sftp':
            return (<SftpTransferHandler handler={handler} dispatch={dispatch} getFieldId={getFieldId} />);
        case 'zipsftp':
            return (<ZipSftpTransferHandler handler={handler} dispatch={dispatch} getFieldId={getFieldId} />);
        case 'zips3':
            return (<ZipS3TransferHandler handler={handler} dispatch={dispatch} getFieldId={getFieldId} />);
        case 's3':
            return (<S3TransferHandler handler={handler} dispatch={dispatch} getFieldId={getFieldId} />);
        case 'azure':
            return (<AzureTransferHandler handler={handler} dispatch={dispatch} getFieldId={getFieldId} />);
    }
}

const TransferHandlerView: React.FC<TransferHandlerViewProps> = ({ configId, handler, dispatch }) => {
    const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const getFieldId = (fieldName: string): string => {
        return `${configId}|${handler.id!}|${fieldName}`;
    };
    const handlerView = getHandlerView(handler, dispatch, getFieldId);

    const isHandlerViewOpenToggle = () => {
        dispatch({ type: 'editHandlerOpen', id: handler.id!, isOpen: !handler.isOpen })
    };

    const deleteHandlerConfirmationMessage = `Are you sure you want to delete handler '${getHandlerTitle(handler.name)}' from config '${configId}'?`;

    return (
        <div className='mgmt-handler-wrapper'>
            <Accordion title={getHandlerTitle(handler.name)}
                isOpen={!!handler.isOpen}
                onOpen={isHandlerViewOpenToggle}
                onClose={isHandlerViewOpenToggle}>
                <FormGroup id={getFieldId('name')}
                    label='Name'
                    inputType='text'
                    required={true}
                    pattern={HANDLER_PATTERN}
                    value={`${handler.name}`}
                    onChange={(event) => dispatch({
                        type: 'editHandlerName',
                        id: handler.id!,
                        newName: event.target.value
                    })} />
                <FormGroup id={getFieldId('enabled')}
                    label='Enabled?'
                    inputType='checkbox'
                    value={`${handler.enabled}`}
                    onChange={() => dispatch({
                        type: 'editHandlerEnabled',
                        id: handler.id!,
                        enabled: !handler.enabled,
                    })} />
                <FormGroup id={getFieldId('filter')} label='Filter' inputType='text' value={handler.filter}
                    customValidate={(element: HTMLInputElement) => {
                        try {
                            new RegExp(element.value);
                            element.setCustomValidity('');
                        } catch (e) {
                            if (e instanceof SyntaxError) {
                                element.setCustomValidity('Invalid RegExp value. Error: ' + e.message);
                            } else {
                                element.setCustomValidity('Please provide a valid RegExp value');
                            }

                        }
                    }}
                    onChange={(event) => dispatch({
                        type: 'editHandlerFilter',
                        id: handler.id!,
                        filter: event.target.value,
                    })} />
                <div className='mgmt-form-group'>
                    <label htmlFor={getFieldId('type')}>Type</label>
                    <select id={getFieldId('type')}
                        value={handler.transportType.toLowerCase()}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            dispatch({
                                type: 'editHandlerTransportType',
                                id: handler.id!,
                                transportType: event.target.value,
                            });
                        }}>
                        <option value='sftp'>SFTP</option>
                        <option value='zipsftp'>SFTP (Compressed Batches)</option>
                        <option value='s3'>S3</option>
                        <option value='zips3'>S3 (Compressed Batches)</option>
                        <option value='azure'>Azure</option>
                    </select>
                </div>
                {handlerView}
                <div className='mgmt-form-group'>
                    <label id={getFieldId('delete_btn')} ></label>
                    <input id={getFieldId('delete_btn')} type='button' value='Delete'
                        onClick={() => setDeleteDialogOpen(true)}></input>
                </div>
            </Accordion>
            <ShowIf expression={isDeleteDialogOpen}>
                <ConfirmationDialog message={deleteHandlerConfirmationMessage}
                    onClose={() => { setDeleteDialogOpen(false) }}
                    onConfirm={() => { dispatch({ type: 'deleteHandler', id: handler.id! }) }}
                    isOpen={isDeleteDialogOpen} />
            </ShowIf>

        </div>
    );
}

export default TransferHandlerView;
