import React from 'react';
import { ConfigurationProps } from './Configuration.props';
import { TableSelect } from './TableSelect';
import { SerializerSelect } from './SerializerSelect';

import './Configuration.css';

const Configuration: React.FC<ConfigurationProps> = ({
    tableSelectList,
    setTableSelectList,
    tableClearList,
    setTableClearList,
    objectIdContextsList,
    setObjectIdContextsList,
    contextsList,
    setContextsList,
    serializerSelectList,
    setSerializerSelectList
}) => {
    return (
        <div className="mgmt-tsunami-configuration">
            <div className="mgmt-tsunami-configuration-header">
                CONFIGURATION
            </div>
            <div className="mgmt-tsunami-configuration-body">
                <div className="mgmt-tsunami-configuration-body-left">
                    <div className="mgmt-tsunami-configuration-body-section">
                        <label className="mgmt-tsunami-configuration-section-header">Choose tables to run tsunami against</label>
                        <TableSelect tableList={tableSelectList} setTableList={setTableSelectList} />
                    </div>
                    <div className="mgmt-tsunami-configuration-body-section">
                        <label className="mgmt-tsunami-textarea-label">List the product IDs/contexts to be processed (line or comma separated, e.g. PRD-675876#en_US)</label>
                        <textarea
                            className="mgmt-tsunami-textarea"
                            value={objectIdContextsList}
                            onChange={(e) => setObjectIdContextsList(e.target.value)}
                            cols={66}
                            rows={5}
                        />
                    </div>
                    <div className="mgmt-tsunami-configuration-body-section">
                        <label className="mgmt-tsunami-textarea-label">Contexts (line or comma separated, e.g. en_AA,en_US)</label>
                        <textarea
                            className="mgmt-tsunami-textarea"
                            value={contextsList}
                            onChange={(e) => setContextsList(e.target.value)}
                            cols={20}
                            rows={4}
                        />
                    </div>
                    <SerializerSelect
                        serializerSelectList={serializerSelectList}
                        setSerializerSelectList={setSerializerSelectList}
                    />
                </div>
                <div className="mgmt-tsunami-configuration-body-right">
                    <label className="mgmt-tsunami-configuration-section-header">Choose tables to clear before running tsunami</label>
                    <TableSelect tableList={tableClearList} setTableList={setTableClearList} />
                </div>
            </div>
        </div>
    );
};

export default Configuration;
