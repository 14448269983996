import * as React from 'react';
import { useState } from 'react';
import { Accordion, FormGroup } from "../../../common";
import { ChannelActionType } from "../../ChannelComponent/ChannelComponent.types";
import { ProductTypesComponentProps } from "./ProductTypesComponent.types";

const ProductTypesComponent: React.FC<ProductTypesComponentProps> = (props: ProductTypesComponentProps) => {
    const {getId, onChange, data} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }
    return (
        <Accordion title={"Product Types"} isOpen={isOpen} onOpen={toggleIsOpen} onClose={toggleIsOpen}>
            <FormGroup id={getId('accessory')}
                       label="Accessory"
                       inputType="checkbox"
                       value={`${data.accessory}`}
                       onChange={() => {
                           onChange({type: ChannelActionType.TOGGLE_PRODUCT_TYPES_ACCESSORY});
                       }}/>
            <FormGroup id={getId('coil')}
                       label="Coil"
                       inputType="checkbox"
                       value={`${data.coil}`}
                       onChange={() => {
                           onChange({type: ChannelActionType.TOGGLE_PRODUCT_TYPES_COIL});
                       }}/>
            <FormGroup id={getId('normal')}
                       label="Normal"
                       inputType="checkbox"
                       value={`${data.normal}`}
                       onChange={() => {
                           onChange({type: ChannelActionType.TOGGLE_PRODUCT_TYPES_NORMAL});
                       }}/>
            <FormGroup id={getId('software')}
                       label="Software"
                       inputType="checkbox"
                       value={`${data.software}`}
                       onChange={() => {
                           props.onChange({type: ChannelActionType.TOGGLE_PRODUCT_TYPES_SOFTWARE});
                       }}/>
            <FormGroup id={getId('software_application')}
                       label="Software Application"
                       inputType="checkbox"
                       value={`${data.softwareApplication}`}
                       onChange={() => {
                           props.onChange({type: ChannelActionType.TOGGLE_PRODUCT_TYPES_SOFTWARE_APPLICATION});
                       }}/>
            <FormGroup id={getId('software_platform')}
                       label="Software Platform"
                       inputType="checkbox"
                       value={`${data.softwarePlatform}`}
                       onChange={() => {
                           props.onChange({type: ChannelActionType.TOGGLE_PRODUCT_TYPES_SOFTWARE_PLATFORM});
                       }}/>
            <FormGroup id={getId('spare_part')}
                       label="Spare Part"
                       inputType="checkbox"
                       value={`${data.sparePart}`}
                       onChange={() => {
                           props.onChange({type: ChannelActionType.TOGGLE_PRODUCT_TYPES_SPARE_PART});
                       }}/>
            <FormGroup id={getId('transducer')}
                       label="Transducer"
                       inputType="checkbox"
                       value={`${data.transducer}`}
                       onChange={() => {
                           props.onChange({type: ChannelActionType.TOGGLE_PRODUCT_TYPES_TRANSDUCER});
                       }}/>
        </Accordion>
    );
};

export default ProductTypesComponent;