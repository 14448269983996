import React, { useEffect, useState } from 'react';
import { AuthProviderProps } from './AuthProvider.types';
import { useMsal } from '@azure/msal-react';
import { AppProvider } from '../../pages/context';
import { ShowIf } from '../common';
import { SilentRequest } from '@azure/msal-browser';

const REFRESH_TOKEN_TIME_GAP_MILLIS = 300000;

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [jwt, setJwt] = useState('');
    const [tokenRefreshTime, setTokenRefreshTime] = useState(0);

    const { instance } = useMsal();

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account) {
            const clientId = instance.getConfiguration().auth.clientId;
            const request: SilentRequest = { scopes: [`${clientId}/.default`], account };
            instance.acquireTokenSilent(request)
                .then(result => {
                    setJwt(result.accessToken);
                    setTokenRefreshTime(result.expiresOn?.getTime() ?? 3600);
                });
        }
    }, [instance, setJwt, setTokenRefreshTime]);

    const tokenSupplier = async (): Promise<string> => {
        const currentTime = (new Date()).getTime();
        if ((tokenRefreshTime - currentTime) < REFRESH_TOKEN_TIME_GAP_MILLIS) {
            // it is not possible to execute the code if User is not signed in.
            const account = instance.getActiveAccount();
            const clientId = instance.getConfiguration().auth.clientId;
            const request: SilentRequest = {
                scopes: [`${clientId}/.default`],
                forceRefresh: true,
                account: account!
            };
            const result = await instance.acquireTokenSilent(request);
            setJwt(result.accessToken);
            setTokenRefreshTime(result.expiresOn?.getTime() ?? 3600);
            return result.accessToken;
        }
        return jwt;
    };

    return (
        <ShowIf expression={jwt !== ''}>
            <AppProvider jwtSupplier={tokenSupplier}>
                {children}
            </AppProvider>
        </ShowIf>
    );
}

export default AuthProvider;
