import { ChannelTypes, Channel, ExportType } from "../services/channel.types";

const isObject = (value: ChannelTypes): boolean => {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
}

/**
 * Converts null values to empty strings or vice versa, based on the "direction" parameter.
 *
 * This conversion is required for React to set empty strings as values to fields that are actually not populated.
 * Backward convertion "toNull" is required in order to send a proper json to backend, which expects nulls as values
 * for not populated fields.
* */
export const cleanChannelData = (data: {[key: string]: any}, direction: 'toEmptyStr' | 'toNull') => {
    if (!data) {
        return data;
    }
    const substitute = direction === 'toEmptyStr' ? '' : null;
    const cleanedData: {[key: string]: any} = Array.isArray(data) ? [] : {};
    Object.keys(data).forEach(key => {
        const value = data[key];
        if ((direction === 'toEmptyStr' && value === null) || (direction === 'toNull' && value === '')) {
            cleanedData[key] = substitute;
        } else if (isObject(value)) {
            cleanedData[key] = cleanChannelData(value, direction); // Recursion for nested objects
        } else {
            cleanedData[key] = value;
        }
    });
    return cleanedData;
};

/**
 * Validate if channel, created by "Create New Channel" button, contains all required fields.
* */
export const validateNewChannel = (channel: Channel) => {
    const errors = [];

    if (!channel.channelId) {
        errors.push("Channel ID is required.");
    }
    if (!channel.name) {
        errors.push("Channel Name is required.");
    }
    if (!channel.exportType) {
        errors.push("Export Type is required.");
    }
    if (!channel.timing) {
        errors.push("Timing is required.");
    }
    if (!channel.filter || !channel.filter.contexts) {
        errors.push("Filter Contexts is required.");
    }
    if (!channel.filter || (!channel.filter.trees && channel.exportType === ExportType.XUCDM_CLASSIFICATION)) {
        errors.push("Filter Objects to Export is required.");
    }
    if (!channel.filter || (!channel.filter.catalogs && channel.exportType === ExportType.XUCDM_PRODUCT)) {
        errors.push("Filter Catalog codes is required.");
    }
    if (!channel.output || !channel.output.fileNamePattern) {
        errors.push("Output Filename Pattern is required.");
    }

    return errors;
}