import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import PageNotFound from "./pages/NotFound";
import { ManagementPage, ManagementPageType } from "./pages/Management";
import ObjectTransportServicePage from "./pages/ObjectTransportService/ObjectTransportService";
import Users from "./pages/Users/Users";
import SerializerContexts from "./pages/SerializerContexts/SerializerContexts";
import ReprocessProductPage from "./pages/ReprocessProduct/ReprocessProduct";
import RunChannel from "./pages/RunChannel/RunChannel";
import HomePage from "./pages/Home";
import { TimedPage } from "./pages/Timed";
import { ChannelsPage } from "./pages/Channels";
import { LogsPage } from "./pages/Logs";
import { TsunamiPage } from "./pages/Tsunami";
import { AutoscalingOverridePage } from "./pages/AutoscalingOverride";
import { PATHS } from './config/paths';

const timedRoute = () => (
    <ManagementPage selectedItemType={ManagementPageType.Timed}>
        <TimedPage />
    </ManagementPage>
);

const transferRoute = () => (
    <ManagementPage selectedItemType={ManagementPageType.OTS}>
        <ObjectTransportServicePage />
    </ManagementPage>
);

const usersRoute = () => (
    <ManagementPage selectedItemType={ManagementPageType.Users}>
        <Users />
    </ManagementPage>
);

const serializerContextsRoute = () => (
    <ManagementPage selectedItemType={ManagementPageType.SerializerContexts}>
        <SerializerContexts />
    </ManagementPage>
);

const reprocessProductRoute = () => (
    <ManagementPage selectedItemType={ManagementPageType.IngestProductsBatch}>
        <ReprocessProductPage />
    </ManagementPage>
);

const runChannelRoute = () => (
    <ManagementPage selectedItemType={ManagementPageType.RunChannel}>
        <RunChannel />
    </ManagementPage>
);

const channelsRoute = () => (
    <ManagementPage selectedItemType={ManagementPageType.Channels}>
        <ChannelsPage />
    </ManagementPage>
);

const tsunamiRoute = () => (
    <ManagementPage selectedItemType={ManagementPageType.Tsunami}>
        <TsunamiPage />
    </ManagementPage>
);

const autoscalingOverrideRoute = () => (
    <ManagementPage selectedItemType={ManagementPageType.AutoscalingOverride}>
        <AutoscalingOverridePage />
    </ManagementPage>
);

export const createAppRouter = () => {
    return createBrowserRouter([
        {path: PATHS.MANAGEMENT.TIMED, element: timedRoute()},
        {path: PATHS.MANAGEMENT.TRANSFER, element: transferRoute()},
        {path: PATHS.MANAGEMENT.USERS, element: usersRoute()},
        {path: PATHS.MANAGEMENT.SERIALIZER_CONTEXTS, element: serializerContextsRoute()},
        {path: PATHS.MANAGEMENT.REPROCESS_INGEST_PRODUCTS_BATCH, element: reprocessProductRoute()},
        {path: PATHS.MANAGEMENT.RUN_CHANNEL, element: runChannelRoute()},
        {path: PATHS.MANAGEMENT.CHANNELS, element: channelsRoute()},
        {path: PATHS.MANAGEMENT.TSUNAMI, element: tsunamiRoute()},
        {path: PATHS.MANAGEMENT.AUTOSCALING_OVERRIDE, element: autoscalingOverrideRoute()},
        {path: PATHS.MONITORING.LOGS, element: <LogsPage />},
        {path: PATHS.HOME, element: <HomePage />},
        {path: PATHS.PAGE_NOT_FOUND, element: <PageNotFound />},
        {path: '*', element: <Navigate replace={true} to={PATHS.PAGE_NOT_FOUND} />}
    ], {
        future: {
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_relativeSplatPath: true,
            v7_skipActionErrorRevalidation: true,
        },
    });
};
