import { ChannelFilter } from "../../../services/channel.types";
import { ChannelFilterAction } from "../../../pages/Channels/Channels.types";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";

export function filtersReducer(state: ChannelFilter, action: ChannelFilterAction): ChannelFilter {
    switch (action.type) {
        case ChannelActionType.SET_AUDIENCE: {
            return {...state, audience: action.payload};
        }
        case ChannelActionType.SET_CONTEXTS: {
            return {...state, contexts: action.payload};
        }
        case ChannelActionType.SET_TREES: {
            return {...state, trees: action.payload};
        }
        case ChannelActionType.SET_REFURBISHED: {
            return {...state, refurbished: action.payload};
        }
        case ChannelActionType.SET_CATALOGS: {
            return {...state, catalogs: action.payload};
        }
        case ChannelActionType.SET_SOP_OFFSET: {
            return {...state, sopOffsetInDays: action.payload};
        }
        case ChannelActionType.SET_SOMP_OFFSET: {
            return {...state, sompOffsetInDays: action.payload};
        }
        case ChannelActionType.SET_EOP_OFFSET: {
            return {...state, eopOffsetInDays: action.payload};
        }
        case ChannelActionType.SET_ORGANIZATION_CODE_TYPE: {
            return {...state, organizationCode: {...state.organizationCode, type: action.payload}};
        }
        case ChannelActionType.SET_ORGANIZATION_CODE_CODES: {
            return {...state, organizationCode: {...state.organizationCode, codes: action.payload}};
        }
        case ChannelActionType.TOGGLE_OBJECT_TYPES_PRODUCT: {
            return {...state, objectTypes: {...state.objectTypes, product: !state.objectTypes?.product}};
        }
        case ChannelActionType.TOGGLE_OBJECT_TYPES_CONSUMABLE: {
            return {...state, objectTypes: {...state.objectTypes, consumable: !state.objectTypes?.consumable}};
        }
        case ChannelActionType.TOGGLE_OBJECT_TYPES_MIN_PRODUCT: {
            return {
                ...state,
                objectTypes: {
                    ...state.objectTypes, minimumproduct: !state.objectTypes?.minimumproduct
                }
            };
        }
        case ChannelActionType.TOGGLE_OBJECT_TYPES_SALES_PRODUCT: {
            return {
                ...state,
                objectTypes: {
                    ...state.objectTypes,
                    salesproduct: !state.objectTypes?.salesproduct
                }
            };
        }
        case ChannelActionType.TOGGLE_OBJECT_TYPES_RANGE: {
            return {...state, objectTypes: {...state.objectTypes, range: !state.objectTypes?.range}};
        }
        case ChannelActionType.TOGGLE_OBJECT_TYPES_VARIATION: {
            return {...state, objectTypes: {...state.objectTypes, variation: !state.objectTypes?.variation}};
        }
        case ChannelActionType.TOGGLE_PRODUCT_TYPES_ACCESSORY: {
            return {
                ...state,
                productTypes: {
                    ...state.productTypes,
                    accessory: !state.productTypes?.accessory
                }
            };
        }
        case ChannelActionType.TOGGLE_PRODUCT_TYPES_COIL: {
            return {
                ...state,
                productTypes: {
                    ...state.productTypes,
                    coil: !state.productTypes?.coil
                }
            };
        }
        case ChannelActionType.TOGGLE_PRODUCT_TYPES_NORMAL: {
            return {
                ...state,
                productTypes: {
                    ...state.productTypes,
                    normal: !state.productTypes?.normal
                }
            };
        }
        case ChannelActionType.TOGGLE_PRODUCT_TYPES_SOFTWARE: {
            return {
                ...state,
                productTypes: {
                    ...state.productTypes,
                    software: !state.productTypes?.software
                }
            };
        }
        case ChannelActionType.TOGGLE_PRODUCT_TYPES_SOFTWARE_APPLICATION: {
            return {
                ...state,
                productTypes: {
                    ...state.productTypes,
                    softwareApplication: !state.productTypes?.softwareApplication
                }
            };
        }
        case ChannelActionType.TOGGLE_PRODUCT_TYPES_SOFTWARE_PLATFORM: {
            return {
                ...state,
                productTypes: {
                    ...state.productTypes,
                    softwarePlatform: !state.productTypes?.softwarePlatform
                }
            };
        }
        case ChannelActionType.TOGGLE_PRODUCT_TYPES_SPARE_PART: {
            return {
                ...state,
                productTypes: {
                    ...state.productTypes,
                    sparePart: !state.productTypes?.sparePart
                }
            };
        }
        case ChannelActionType.TOGGLE_PRODUCT_TYPES_TRANSDUCER: {
            return {
                ...state,
                productTypes: {
                    ...state.productTypes,
                    transducer: !state.productTypes?.transducer
                }
            };
        }
        case ChannelActionType.SET_WEB_NAV_GROUPS: {
            return {
                ...state,
                webNavigation: {
                    ...state.webNavigation,
                    groups: action.payload
                }
            };
        }
        case ChannelActionType.SET_WEB_NAV_CATEGORIES: {
            return {
                ...state,
                webNavigation: {
                    ...state.webNavigation,
                    categories: action.payload
                }
            };
        }
        case ChannelActionType.SET_WEB_NAV_SUB_CATEGORIES: {
            return {
                ...state,
                webNavigation: {
                    ...state.webNavigation,
                    subCategories: action.payload
                }
            };
        }
        case ChannelActionType.SET_PRODUCT_HIERARCHY_TYPE: {
            return {
                ...state,
                productHierarchy: {
                    ...state.productHierarchy,
                    type: action.payload
                }
            };
        }
        case ChannelActionType.SET_PRODUCT_HIERARCHY_BG: {
            return {
                ...state,
                productHierarchy: {
                    ...state.productHierarchy,
                    businessGroups: action.payload
                }
            };
        }
        case ChannelActionType.SET_PRODUCT_HIERARCHY_BU: {
            return {
                ...state,
                productHierarchy: {
                    ...state.productHierarchy,
                    business: action.payload
                }
            };
        }
        case ChannelActionType.SET_PRODUCT_HIERARCHY_MAG: {
            return {
                ...state,
                productHierarchy: {
                    ...state.productHierarchy,
                    majorArticleGroups: action.payload
                }
            };
        }
        case ChannelActionType.SET_PRODUCT_HIERARCHY_AG: {
            return {
                ...state,
                productHierarchy: {
                    ...state.productHierarchy,
                    articleGroups: action.payload
                }
            };
        }
        default:
            return state;
    }
}