import { TransferConfig, TransferHandler } from "../../../services";
import {
    AzureTransferHandlerConfig,
    S3TransferHandlerConfig,
    SftpTransferHandlerConfig,
    ZipS3TransferHandlerConfig,
    ZipSftpTransferHandlerConfig
} from "../../../services/ots.types";

/**
 * Creates a copy of the transfer configuration with the given array of handlers.
 * @param config transfer configuration.
 * @param handlers list of handlers to be set to the transfer configuration.
 * @returns transfer configuration.
 */
export const copyConfig = (config: TransferConfig, handlers: TransferHandler[]) => {
    return {
        id: config.id,
        type: config.type,
        handlers: handlers,
        enabled: config.enabled,
    }
}

/**
 * Creates a clean copy of the given transfer handler configuration.
 * Please be noted that only properties related to the `transportType` will be copied.
 * @param handler transfer handler.
 * @param cloneForUI the flag indicates if the cloned handler should include custom `id` and `isOpen` properties which is used on UI and is not used on Backend.
 * @returns a copy of the handler configuration.
 */
function cloneHandler(handler: TransferHandler, cloneForUI: boolean = true) {
    let config;
    switch (handler.transportType) {
        case "sftp": {
            const sourceConfig = handler.transportConfiguration;
            config = {
                authData: sourceConfig.authData,
                authMethod: sourceConfig.authMethod,
                host: sourceConfig.host,
                port: sourceConfig.port,
                user: sourceConfig.user,
                workingDir: sourceConfig.workingDir,
                pemFile: sourceConfig.pemFile,
            } as SftpTransferHandlerConfig;
            break;
        }
        case "zipsftp": {
            const sourceConfig = handler.transportConfiguration;
            config = {
                authData: sourceConfig.authData,
                authMethod: sourceConfig.authMethod,
                host: sourceConfig.host,
                port: sourceConfig.port,
                user: sourceConfig.user,
                workingDir: sourceConfig.workingDir,
                pemFile: sourceConfig.pemFile,
                zipFileName: sourceConfig.zipFileName,
            } as ZipSftpTransferHandlerConfig;
            break;
        }
        case "s3": {
            const sourceConfig = handler.transportConfiguration;
            config = {
                bucket: sourceConfig.bucket,
                keyPrefix: sourceConfig.keyPrefix,
                region: sourceConfig.region,
                roleArn: sourceConfig.roleArn,
            } as S3TransferHandlerConfig;
            break;
        }
        case "zips3": {
            const sourceConfig = handler.transportConfiguration;
            config = {
                bucket: sourceConfig.bucket,
                keyPrefix: sourceConfig.keyPrefix,
                region: sourceConfig.region,
                roleArn: sourceConfig.roleArn,
                zipFileName: sourceConfig.zipFileName,
            } as ZipS3TransferHandlerConfig;
            break;
        }
        case "azure": {
            const sourceConfig = handler.transportConfiguration;
            config = {
                accountKey: sourceConfig.accountKey,
                accountName: sourceConfig.accountName,
                containerName: sourceConfig.containerName,
                path: sourceConfig.path,
            } as AzureTransferHandlerConfig;
        }
    }
    const newHandler = {
        name: handler.name,
        enabled: handler.enabled,
        filter: handler.filter,
        transportType: handler.transportType,
        transportConfiguration: config,
    } as TransferHandler;
    if (cloneForUI) {
        newHandler.isOpen = false;
        newHandler.id = handler.id;
    }
    return newHandler;
}

export function cloneConfig(config: TransferConfig, cloneForUI: boolean = true) {
    return {
        id: config.id,
        type: config.type,
        enabled: config.enabled,
        handlers: config.handlers.map(handler => cloneHandler(handler, cloneForUI)),
    } as TransferConfig;
}
