import React from 'react';
import { v4 as uuid } from 'uuid';

import './ObjectTransportService.css';

import {
    ObjectTransportServiceProps,
    TransferConfigAction,
    TransferConfigViewModel
} from './ObjectTransportService.types';
import { AppContext } from '../context';
import { ConfigItem } from '../../components';
import { sortAlphanumericIgnoreCase } from '../../utils';
import { LoadingSpinner, ShowIf } from '../../components/common';
import Snackbar from '../../components/common/Snackbar';

function configsViewReducer(state: Map<string, TransferConfigViewModel>,
                            action: TransferConfigAction): Map<string, TransferConfigViewModel> {
    switch (action.type) {
        case 'setAll': {
            return new Map<string, TransferConfigViewModel>(action.payload);
        }
        default: {
            throw Error(`unsupported action ${action}`);
        }
    }
}

const ObjectTransportServicePage: React.FC<ObjectTransportServiceProps> = () => {
    const [isLoading, setLoading] = React.useState(false);
    const [lastLoadTime, setLastLoadTime] = React.useState((new Date()).getTime());
    const [newConfigName, setNewConfigName] = React.useState('');
    const [configError, setConfigError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('A configuration with this name already exists');
    const [configsView, dispatch] = React.useReducer(configsViewReducer, new Map<string, TransferConfigViewModel>());

    const {services: {apiService}} = React.useContext(AppContext);

    React.useEffect(() => {
        setLoading(true);
        apiService.listTransferConfigs().then(response => {
            const configs = response.configs || [];
            configs.sort((c1, c2) => sortAlphanumericIgnoreCase(c1.id, c2.id));

            const configsViewMap: Map<string, TransferConfigViewModel> = new Map<string, TransferConfigViewModel>([]);
            configs.forEach(config => {
                // generate IDs for all handlers, so, they will be correctly rendered
                config.handlers.forEach(handler => {
                    handler.id = uuid();
                    handler.isOpen = false;
                });
                config.handlers.sort((h1, h2) => sortAlphanumericIgnoreCase(h1.name, h2.name))
                configsViewMap.set(config.id, {config: config, isOpen: false} as TransferConfigViewModel);
            });

            dispatch({
                type: 'setAll',
                payload: configsViewMap,
            } as TransferConfigAction);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, [apiService, lastLoadTime]);

    const reloadConfigs = () => setLastLoadTime((new Date()).getTime());

    const handleNewConfig = () => {
        const existingNames = Array.from(configsView.values()).map(vc => vc.config.id);
        if (existingNames.indexOf(newConfigName) !== -1) {
            setConfigError(true);
            return;
        }

        setConfigError(false);
        const newConfig = { id: newConfigName, type: 'CHANNEL', handlers: [], enabled: false };
        apiService.addTransferConfig(newConfig)
            .then(() => {
                const updatedConfigs = new Map(configsView);
                updatedConfigs.set(newConfig.id, { config: newConfig, isOpen: false });
                dispatch({ type: 'setAll', payload: updatedConfigs });
                setNewConfigName('');
            })
            .catch (error => {
                console.log(error);
                setErrorMessage('An error occurred while creating the new transfer configuration');
                setConfigError(true);
            });
    };

    const onConfigNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewConfigName(e.target.value);
        setConfigError(false);
    };

    const transferConfigListView = Array.from(configsView.values()).map(view =>
        <ConfigItem key={view.config.id} view={view} onSave={reloadConfigs} onDelete={reloadConfigs} />
    );

    return (
        <div>
            <h2>Object Transport Service</h2>
            <ShowIf expression={isLoading}>
                <LoadingSpinner/>
            </ShowIf>
            <ShowIf expression={!isLoading}>
                <div>
                    <p className='mgmt-form-note'>Note: All configs marked with <span
                        style={{fontWeight: 'bold'}}>*</span> are modified</p>
                    <div className='mgmt-ots-form-container'>
                        Configuration Name:
                        <input
                            type='text'
                            value={newConfigName}
                            onChange={onConfigNameChange}
                            className='mgmt-ots-form-item'
                        />
                        <button
                            onClick={handleNewConfig}
                            disabled={!newConfigName.trim()}
                            title={!newConfigName.trim() ? "Type a Configuration Name" : ""}
                            className='mgmt-ots-form-item'>
                            Create New Configuration
                        </button>
                        <Snackbar message={errorMessage} isShown={configError}/>
                    </div>
                    {transferConfigListView}
                </div>
            </ShowIf>
        </div>
    );
}

export default ObjectTransportServicePage;
