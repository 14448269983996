import React, { ChangeEvent } from "react";
import { FullFeedType, RunChannelProps } from "./RunChannel.types";
import { ConfirmationDialog, LoadingSpinner, ModalDialog, ShowIf } from "../../components/common";
import { AppContext } from "../context";
import { sortAlphanumericIgnoreCase } from "../../utils";
import { Channel, RunChannelConfig } from "../../services/channel.types";

import './RunChannel.css';

function emptyProps() {
    return {
        channelId: '',
        prefix: '',
        contextList: '',
        productList: '',
        modifiedSince: '',
        fullFeedType: FullFeedType.CHANNEL_CONFIG,
    } as RunChannelProps;
}

const RunChannelPage = () => {
    const [loadingMessage, setLoadingMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const [channels, setChannels] = React.useState([] as Channel[]);
    const [props, setProps] = React.useState(emptyProps());

    const [showErrorDialog, setShowErrorDialog] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const [showChannelTriggeredDialog, setShowChannelTriggeredDialog] = React.useState(false);

    const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);

    const { services: { apiService } } = React.useContext(AppContext);

    React.useEffect(() => {
        setLoadingMessage('Loading...');
        setLoading(true);
        apiService.listChannels()
            .then(response => {
                const loadedChannels = response.channels || [];
                loadedChannels.sort((c1, c2) => sortAlphanumericIgnoreCase(c1.channelId, c2.channelId));
                setChannels(loadedChannels);
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                setErrorMessage(err.message);
                setShowErrorDialog(true);
            })

    }, [apiService, setLoading, setChannels]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setShowConfirmationDialog(true);
    }

    function getFullFeedConfig() {
        switch (props.fullFeedType) {
            case FullFeedType.NO: {
                return false;
            }
            case FullFeedType.YES: {
                return true;
            }
            case FullFeedType.CHANNEL_CONFIG: {
                for (const channel of channels) {
                    if (channel.channelId === props.channelId) {
                        return channel?.output?.fullFeed || false;
                    }
                }
                return false;
            }
        }
    }

    const handleRun = () => {
        setLoadingMessage(`Triggering Channel '${props.channelId}'...`)
        setLoading(true);
        apiService.runChannel({
            selectedChannelName: props.channelId!,
            feedPrefix: props.prefix,
            contextsList: props.contextList,
            productList: props.productList,
            modifiedSince: props.modifiedSince,
            fullFeed: getFullFeedConfig(),
        } as RunChannelConfig)
            .then(data => {
                setLoading(false);
                if (data.status === 'ERROR') {
                    setErrorMessage(data.message || `Failed to trigger Channel ${props.channelId}`);
                    setShowErrorDialog(true);
                } else {
                    setShowChannelTriggeredDialog(true);
                }
            })
            .catch(err => {
                setLoading(false);
                setErrorMessage(err.message);
                setShowErrorDialog(true);
            });
    }

    const channelOptions = [] as React.ReactElement[];
    if (channels.length > 0) {
        for (const channel of channels) {
            channelOptions.push(
                (<option key={`channel|id|option|${channel.channelId!}`}
                    value={channel.channelId!}>{channel.channelId}</option>)
            )
        }
    }

    return (
        <div>
            <h2>Run Channel</h2>
            <ShowIf expression={loading}>
                <LoadingSpinner message={loadingMessage} />
            </ShowIf>
            <ShowIf expression={!loading}>
                <div className='mgmt-run-channel-form'>
                    <form onSubmit={handleSubmit}>
                        <div className='mgmt-run-channel-form-element'>
                            <label htmlFor='channel|id'>Choose a Channel</label>
                            <select id='channel|id'
                                required={true}
                                name='channelId'
                                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                    setProps({ ...props, channelId: event.target.value });
                                }}>
                                <option value='' disabled={true} selected={true}>Please select a Channel</option>
                                {channelOptions}
                            </select>
                        </div>
                        <div className='mgmt-run-channel-form-element'>
                            <label htmlFor='channel|prefix'>Prefix for the feed file names, e.g. AUTOMATION)</label>
                            <input id='channel|prefix'
                                name='prefix'
                                type='text'
                                value={props.prefix}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setProps({ ...props, prefix: event.target.value });
                                }}></input>
                        </div>
                        <div className='mgmt-run-channel-form-element'>
                            <label htmlFor='channel|contexts'>Contexts (line or comma separated, e.g. en_AA,en_US)</label>
                            <textarea id='channel|contexts'
                                name='contexts'
                                value={props.contextList}
                                cols={20}
                                rows={4}
                                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                    setProps({ ...props, contextList: event.target.value });
                                }}></textarea>
                        </div>
                        <div className='mgmt-run-channel-form-element'>
                            <label htmlFor='channel|productIds'>Export by ID (list of IDs without context)</label>
                            <textarea id='channel|productIds'
                                name='productIds'
                                value={props.productList}
                                cols={20}
                                rows={4}
                                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                    setProps({ ...props, productList: event.target.value });
                                }}></textarea>
                        </div>
                        <div className='mgmt-run-channel-form-element'>
                            <label htmlFor='channel|modifiedSince'>Items modified since (time in UTC)</label>
                            <input id='channel|modifiedSince'
                                name='modifiedSince'
                                type='datetime-local'
                                step='1'
                                value={props.modifiedSince || ''}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setProps({ ...props, modifiedSince: event.target.value });
                                }}></input>
                        </div>
                        <div className='mgmt-run-channel-form-element'>
                            <label htmlFor='channel|fullFeedType'>Full Feed?</label>
                            <select id='channel|fullFeedType'
                                required={true}
                                name='fullFeedType'
                                value={props.fullFeedType}
                                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                    setProps({ ...props, fullFeedType: event.target.value as FullFeedType });
                                }}>
                                <option value={FullFeedType.CHANNEL_CONFIG}>Follow channel config</option>
                                <option value={FullFeedType.YES}>Yes</option>
                                <option value={FullFeedType.NO}>No</option>
                            </select>
                        </div>
                        <div className='mgmt-run-channel-form-element'>
                            {/* Hidden label for proper rendering */}
                            <label htmlFor='run-channel-submit' hidden={true}></label>
                            <input id='run-channel-submit' type='submit' value="Let's go!"></input>
                        </div>
                    </form>
                </div>
            </ShowIf>
            <ShowIf expression={showConfirmationDialog}>
                <ConfirmationDialog
                    message={`Would you like to run the Channel '${props.channelId}' with the given configuration?`}
                    onClose={() => { setShowConfirmationDialog(false) }}
                    onConfirm={() => { handleRun() }}
                    isOpen={showConfirmationDialog} />
            </ShowIf>
            <ShowIf expression={showErrorDialog}>
                <ModalDialog isOpen={showErrorDialog} onClose={() => setShowErrorDialog(false)}>
                    <p>Failed to perform an operation</p>
                    <p>Error: {errorMessage}</p>
                </ModalDialog>
            </ShowIf>
            <ShowIf expression={showChannelTriggeredDialog}>
                <ModalDialog isOpen={showChannelTriggeredDialog} onClose={() => {
                    setShowChannelTriggeredDialog(false);
                    setProps(emptyProps());
                }}>
                    <p>Channel &#39;{props.channelId}&#39; is successfully triggered</p>
                </ModalDialog>
            </ShowIf>
        </div>
    );
}

export default RunChannelPage;
