import { Channel, ExportType } from "../../../services/channel.types";
import { ChannelViewModel } from "../../../pages/Channels/Channels.types";

export interface ChannelProps {
    view: ChannelViewModel;
    onCancel?: ((channelId: string) => void) | undefined;
    onSave?: (() => void) | undefined;
    onDelete?: (() => void) | undefined;
}

export enum ChannelActionType {
    TOGGLE_PARALLEL,
    TOGGLE_ENABLED,
    SET_EXPORT_TYPE,
    SET_ID,
    SET_NAME,
    SET_DESCRIPTION,
    SET_TARGET_FOLDER,
    SET_TIMING,
    SET_AUDIENCE,
    SET_CONTEXTS,
    SET_TREES,
    SET_REFURBISHED,
    SET_CATALOGS,
    SET_SOP_OFFSET,
    SET_SOMP_OFFSET,
    SET_EOP_OFFSET,
    SET_ORGANIZATION_CODE_TYPE,
    SET_ORGANIZATION_CODE_CODES,
    TOGGLE_OBJECT_TYPES_PRODUCT,
    TOGGLE_OBJECT_TYPES_CONSUMABLE,
    TOGGLE_OBJECT_TYPES_MIN_PRODUCT,
    TOGGLE_OBJECT_TYPES_SALES_PRODUCT,
    TOGGLE_OBJECT_TYPES_RANGE,
    TOGGLE_OBJECT_TYPES_VARIATION,
    TOGGLE_PRODUCT_TYPES_ACCESSORY,
    TOGGLE_PRODUCT_TYPES_COIL,
    TOGGLE_PRODUCT_TYPES_NORMAL,
    TOGGLE_PRODUCT_TYPES_SOFTWARE,
    TOGGLE_PRODUCT_TYPES_SOFTWARE_APPLICATION,
    TOGGLE_PRODUCT_TYPES_SOFTWARE_PLATFORM,
    TOGGLE_PRODUCT_TYPES_SPARE_PART,
    TOGGLE_PRODUCT_TYPES_TRANSDUCER,
    SET_WEB_NAV_GROUPS,
    SET_WEB_NAV_CATEGORIES,
    SET_WEB_NAV_SUB_CATEGORIES,
    SET_PRODUCT_HIERARCHY_TYPE,
    SET_PRODUCT_HIERARCHY_BG,
    SET_PRODUCT_HIERARCHY_BU,
    SET_PRODUCT_HIERARCHY_MAG,
    SET_PRODUCT_HIERARCHY_AG,
    SET_OUTPUT_XUCDM_LEVEL,
    SET_OUTPUT_XUCDM_VERSION,
    SET_OUTPUT_TRANSPORT_FORMAT,
    SET_OUTPUT_HEADER_LINE,
    SET_OUTPUT_FOOTER_LINE,
    SET_OUTPUT_FILENAME_PATTERN,
    SET_OUTPUT_BATCH_SIZE,
    TOGGLE_OUTPUT_FULL_FEED,
    TOGGLE_OUTPUT_INCLUDE_INACTIVE,
    TOGGLE_OUTPUT_CONSOLIDATED_FEED,
    SET_OUTPUT_LOCALE_MAPPING,
    TOGGLE_ASSETS_NATIVE_LANGUAGE_ONLY,
    SET_ASSETS_INCLUDED_TYPES,
    SET_ASSETS_EXCLUDED_TYPES,
    TOGGLE_ASSETS_INCLUDED_LEVELS_PUBLIC,
    TOGGLE_ASSETS_INCLUDED_LEVELS_SECURE,
    TOGGLE_ASSETS_INCLUDED_LEVELS_INTERNAL,
    TOGGLE_ASSETS_EXCLUDED_LEVELS_PUBLIC,
    TOGGLE_ASSETS_EXCLUDED_LEVELS_SECURE,
    TOGGLE_ASSETS_EXCLUDED_LEVELS_INTERNAL,
    SET_TRANSFORMATIONS_PARAMETERS,
    SET_TRANSFORMATIONS_APPLY_ARRAY,
    RESET
}

export interface ToggleIsParallelAction {
    type: ChannelActionType.TOGGLE_PARALLEL;
}

export interface ToggleIsEnabledAction {
    type: ChannelActionType.TOGGLE_ENABLED;
}

export interface SetExportTypeAction {
    type: ChannelActionType.SET_EXPORT_TYPE;
    payload: ExportType;
}

export interface SetChannelIdAction {
    type: ChannelActionType.SET_ID;
    payload: string;
}

export interface SetChannelNameAction {
    type: ChannelActionType.SET_NAME;
    payload: string;
}

export interface SetChannelDescriptionAction {
    type: ChannelActionType.SET_DESCRIPTION;
    payload: string;
}

export interface SetTargetFolderAction {
    type: ChannelActionType.SET_TARGET_FOLDER;
    payload: string;
}

export interface SetTimingAction {
    type: ChannelActionType.SET_TIMING;
    payload: string;
}

export interface SetAudienceAction {
    type: ChannelActionType.SET_AUDIENCE;
    payload: string;
}

export interface SetContextsAction {
    type: ChannelActionType.SET_CONTEXTS;
    payload: string;
}

export interface SetTreesAction {
    type: ChannelActionType.SET_TREES;
    payload: string;
}

export interface SetRefurbishedAction {
    type: ChannelActionType.SET_REFURBISHED;
    payload: string;
}

export interface SetCatalogsAction {
    type: ChannelActionType.SET_CATALOGS;
    payload: string;
}

export interface SetSopOffsetAction {
    type: ChannelActionType.SET_SOP_OFFSET;
    payload: number | null;
}

export interface SetSompOffsetAction {
    type: ChannelActionType.SET_SOMP_OFFSET;
    payload: number | null;
}

export interface SetEopOffsetAction {
    type: ChannelActionType.SET_EOP_OFFSET;
    payload: number | null;
}

export interface SetOrganizationCodeTypeAction {
    type: ChannelActionType.SET_ORGANIZATION_CODE_TYPE;
    payload: string;
}

export interface SetOrganizationCodeCodesAction {
    type: ChannelActionType.SET_ORGANIZATION_CODE_CODES;
    payload: string;
}

export interface ToggleObjectTypesProductAction {
    type: ChannelActionType.TOGGLE_OBJECT_TYPES_PRODUCT;
}

export interface ToggleObjectTypesConsumableAction {
    type: ChannelActionType.TOGGLE_OBJECT_TYPES_CONSUMABLE;
}

export interface ToggleObjectTypesMinProductAction {
    type: ChannelActionType.TOGGLE_OBJECT_TYPES_MIN_PRODUCT;
}

export interface ToggleObjectTypesSalesProductAction {
    type: ChannelActionType.TOGGLE_OBJECT_TYPES_SALES_PRODUCT;
}

export interface ToggleObjectTypesRangeAction {
    type: ChannelActionType.TOGGLE_OBJECT_TYPES_RANGE;
}

export interface ToggleObjectTypesVariationAction {
    type: ChannelActionType.TOGGLE_OBJECT_TYPES_VARIATION;
}

export interface ToggleProductTypesAccessoryAction {
    type: ChannelActionType.TOGGLE_PRODUCT_TYPES_ACCESSORY;
}

export interface ToggleProductTypesCoilAction {
    type: ChannelActionType.TOGGLE_PRODUCT_TYPES_COIL;
}

export interface ToggleProductTypesNormalAction {
    type: ChannelActionType.TOGGLE_PRODUCT_TYPES_NORMAL;
}

export interface ToggleProductTypesSoftwareAction {
    type: ChannelActionType.TOGGLE_PRODUCT_TYPES_SOFTWARE;
}

export interface ToggleProductTypesSoftwareApplicationAction {
    type: ChannelActionType.TOGGLE_PRODUCT_TYPES_SOFTWARE_APPLICATION;
}

export interface ToggleProductTypesSoftwarePlatformAction {
    type: ChannelActionType.TOGGLE_PRODUCT_TYPES_SOFTWARE_PLATFORM;
}

export interface ToggleProductTypesSparePartAction {
    type: ChannelActionType.TOGGLE_PRODUCT_TYPES_SPARE_PART;
}

export interface ToggleProductTypesTransducerAction {
    type: ChannelActionType.TOGGLE_PRODUCT_TYPES_TRANSDUCER;
}

export interface SetWebNavigationGroupsAction {
    type: ChannelActionType.SET_WEB_NAV_GROUPS,
    payload: string;
}

export interface SetWebNavigationCategoriesAction {
    type: ChannelActionType.SET_WEB_NAV_CATEGORIES,
    payload: string;
}

export interface SetWebNavigationSubCategoriesAction {
    type: ChannelActionType.SET_WEB_NAV_SUB_CATEGORIES,
    payload: string;
}

export interface SetProductHierarchyTypeAction {
    type: ChannelActionType.SET_PRODUCT_HIERARCHY_TYPE,
    payload: string;
}

export interface SetProductHierarchyBusinessGroupAction {
    type: ChannelActionType.SET_PRODUCT_HIERARCHY_BG,
    payload: string;
}

export interface SetProductHierarchyBusinessUnitAction {
    type: ChannelActionType.SET_PRODUCT_HIERARCHY_BU,
    payload: string;
}

export interface SetProductHierarchyMainArticleGroupAction {
    type: ChannelActionType.SET_PRODUCT_HIERARCHY_MAG,
    payload: string;
}

export interface SetProductHierarchyArticleGroupAction {
    type: ChannelActionType.SET_PRODUCT_HIERARCHY_AG,
    payload: string;
}

export interface SetOutputXucdmLevelAction {
    type: ChannelActionType.SET_OUTPUT_XUCDM_LEVEL;
    payload: string;
}

export interface SetOutputXucdmVersionAction {
    type: ChannelActionType.SET_OUTPUT_XUCDM_VERSION;
    payload: string;
}

export interface SetOutputTransportFormatAction {
    type: ChannelActionType.SET_OUTPUT_TRANSPORT_FORMAT;
    payload: string;
}

export interface SetOutputHeaderLineAction {
    type: ChannelActionType.SET_OUTPUT_HEADER_LINE;
    payload: string;
}

export interface SetOutputFooterLineAction {
    type: ChannelActionType.SET_OUTPUT_FOOTER_LINE;
    payload: string;
}

export interface SetOutputFilenamePatternAction {
    type: ChannelActionType.SET_OUTPUT_FILENAME_PATTERN;
    payload: string;
}

export interface SetOutputBatchSizeAction {
    type: ChannelActionType.SET_OUTPUT_BATCH_SIZE;
    payload: number | null;
}

export interface ToggleOutputFullFeedAction {
    type: ChannelActionType.TOGGLE_OUTPUT_FULL_FEED;
}

export interface ToggleOutputIncludeInactiveAction {
    type: ChannelActionType.TOGGLE_OUTPUT_INCLUDE_INACTIVE;
}

export interface ToggleOutputConsolidatedFeedAction {
    type: ChannelActionType.TOGGLE_OUTPUT_CONSOLIDATED_FEED;
}

export interface SetOutputLocaleMappingAction {
    type: ChannelActionType.SET_OUTPUT_LOCALE_MAPPING;
    payload: string;
}

export interface ToggleAssetsNativeLanguageOnlyAction {
    type: ChannelActionType.TOGGLE_ASSETS_NATIVE_LANGUAGE_ONLY;
}

export interface SetAssetsIncludedTypesAction {
    type: ChannelActionType.SET_ASSETS_INCLUDED_TYPES;
    payload: string;
}

export interface SetAssetsExcludedTypesAction {
    type: ChannelActionType.SET_ASSETS_EXCLUDED_TYPES;
    payload: string;
}

export interface ToggleAssetsIncludedLevelsPublicAction {
    type: ChannelActionType.TOGGLE_ASSETS_INCLUDED_LEVELS_PUBLIC;
}

export interface ToggleAssetsIncludedLevelsSecureAction {
    type: ChannelActionType.TOGGLE_ASSETS_INCLUDED_LEVELS_SECURE;
}

export interface ToggleAssetsIncludedLevelsInternalAction {
    type: ChannelActionType.TOGGLE_ASSETS_INCLUDED_LEVELS_INTERNAL;
}

export interface ToggleAssetsExcludedLevelsPublicAction {
    type: ChannelActionType.TOGGLE_ASSETS_EXCLUDED_LEVELS_PUBLIC;
}

export interface ToggleAssetsExcludedLevelsSecureAction {
    type: ChannelActionType.TOGGLE_ASSETS_EXCLUDED_LEVELS_SECURE;
}

export interface ToggleAssetsExcludedLevelsInternalAction {
    type: ChannelActionType.TOGGLE_ASSETS_EXCLUDED_LEVELS_INTERNAL;
}

export interface SetTransformationsParametersAction {
    type: ChannelActionType.SET_TRANSFORMATIONS_PARAMETERS;
    payload: string;
}

export interface SetTransformationsApplyArrayAction {
    type: ChannelActionType.SET_TRANSFORMATIONS_APPLY_ARRAY;
    payload: string[];
}

export interface Reset {
    type: ChannelActionType.RESET;
    payload: Channel;
}
