import { Accordion, FormGroup, ShowIf } from "../../common";
import React, { ChangeEvent, useState } from "react";
import { ChannelPropertiesProps } from "./ChannelProperties.types";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";
import { ExportType } from "../../../services/channel.types";

const ChannelProperties: React.FC<ChannelPropertiesProps> = (props: ChannelPropertiesProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    const getId = (fieldName: string): string => {
        return `${props.channelId}|${fieldName}`;
    };

    const toggleIsEnabled = (): void => {
        props.onChange({type: ChannelActionType.TOGGLE_ENABLED});
    }

    const toggleIsParallel = (): void => {
        props.onChange({type: ChannelActionType.TOGGLE_PARALLEL});
    }

    const setExportType = (exportType: ExportType): void => {
        props.onChange({type: ChannelActionType.SET_EXPORT_TYPE, payload: exportType});
    }

    const setTiming = (timing: string): void => {
        props.onChange({type: ChannelActionType.SET_TIMING, payload: timing});
    }

    const setChannelId = (event: ChangeEvent<HTMLInputElement>): void => {
        props.onChange({type: ChannelActionType.SET_ID, payload: event.target.value});
    }

    const setChannelName = (event: ChangeEvent<HTMLInputElement>): void => {
        props.onChange({type: ChannelActionType.SET_NAME, payload: event.target.value});
    }

    const setChannelDescription = (event: ChangeEvent<HTMLInputElement>): void => {
        props.onChange({type: ChannelActionType.SET_DESCRIPTION, payload: event.target.value});
    }

    const setTargetFolder = (event: ChangeEvent<HTMLInputElement>): void => {
        props.onChange({type: ChannelActionType.SET_TARGET_FOLDER, payload: event.target.value});
    }

    return (
        <Accordion title={"Channel Properties"} isOpen={isOpen} onOpen={toggleIsOpen} onClose={toggleIsOpen}>
            <ShowIf expression={props.isNewChannel}>
                <div>Please fill in all required fields marked with an asterisk (*).</div>
            </ShowIf>
            <FormGroup id={getId('channelId')}
                       label="Channel ID"
                       inputType="text"
                       required={true}
                       value={props.channelId}
                       requiredField={props.isNewChannel}
                       disabled={!props.isNewChannel}
                       onChange={setChannelId}
            />
            <FormGroup id={getId('name')}
                       label="Channel Name"
                       inputType="text"
                       value={props.name}
                       requiredField={props.isNewChannel}
                       onChange={setChannelName}
            />
            <FormGroup id={getId('description')}
                       label="Description"
                       inputType="text"
                       value={props.description}
                       onChange={setChannelDescription}
            />
            <FormGroup id={getId('enabled')}
                       label="Enable channel"
                       inputType="checkbox"
                       value={`${props.enabled}`}
                       onChange={toggleIsEnabled}
            />
            <FormGroup id={getId('parallel')}
                       label="Parallel"
                       inputType="checkbox"
                       value={`${props.parallel}`}
                       onChange={toggleIsParallel}
            />
            <FormGroup id={getId('targetFolder')}
                       label="Target Folder"
                       inputType="text"
                       value={props.targetFolder}
                       onChange={setTargetFolder}
            />
            <div className='mgmt-form-group'>
                <label className={props.isNewChannel ? 'mgmt-required-field' : ''}
                       htmlFor='channel|exportType'>Export Type</label>
                <select id='channel|exportType'
                        required={true}
                        name='exportType'
                        value={props.exportType}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            setExportType(event.target.value as unknown as ExportType);
                        }}>
                    <option value={ExportType.XUCDM_PRODUCT}>Product</option>
                    <option value={ExportType.XUCDM_PRODUCTGROUP}>Product Group</option>
                    <option value={ExportType.XUCDM_CLASSIFICATION}>Classification</option>
                </select>
            </div>
            <FormGroup id={getId('timing')}
                       label="Timing"
                       inputType="text"
                       required={true}
                       value={`${props.timing}`}
                       requiredField={props.isNewChannel}
                       onChange={(event: ChangeEvent<HTMLInputElement>) => {
                           setTiming(event.target.value);
                       }}
            />
        </Accordion>
    );
}

export default ChannelProperties;