// Disabling eslint because the given patterns must use escape characters
// to make them working with React.
/* eslint-disable no-useless-escape */
export const BUCKET_NAME_PATTERN = /^[a-z0-9][a-z0-9\-]{2,62}$/.source;
export const ROLE_ARN_PATTERN = /^arn:aws:iam::[0-9]{12}:role\/[a-zA-Z0-9_\-]+$/.source;
export const S3_KEY_PREFIX_PATTERN = /^[a-zA-Z0-9\-\/_]+\/$/.source;
export const REGION_NAME_PATTERN = /^[a-z]{2}-[a-z]{4,9}-[1-2]$/.source;
export const ZIP_FILENAME_PATTERN = /^[a-zA-Z0-9_\{\}]+.zip$/.source;
export const WORKING_DIR_PATTERN = /^[a-zA-Z0-9\/_\-]{1,500}$/.source;
export const USER_PATTERN = /^[a-zA-Z0-9._\-]{1,255}$/.source;
export const PORT_PATTERN = /^[0-9]+$/.source;
export const PEM_FILE_PATTERN = /^[a-zA-Z0-9.\/_\-]{1,500}$/.source;
export const HOSTNAME_PATTERN = /^[a-zA-Z0-9._\-]{1,255}$/.source;
export const HANDLER_PATTERN = /^[^ ][a-zA-Z0-9_\-\(\) ]{1,255}[^ ]$/.source;
