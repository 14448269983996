import React, { FC } from "react";

import './Accordion.css';

import { AccordionProps } from "./Accordion.types";
import { CollapseIcon, Direction } from "../icons";

const Accordion: FC<AccordionProps> = ({ title, isOpen, children, onOpen, onClose }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleOpen = () => {
        if (onOpen) {
            onOpen();
        }
    }

    return (
        <div>
            <div className="mgmt-collapsable">
                <div className='mgmt-section-title' style={isOpen ? { backgroundColor: '#37659DFF' } : {}}
                    onClick={() => isOpen ? handleClose() : handleOpen()}>
                    <label>{title}</label>
                    <CollapseIcon direction={isOpen ? Direction.Down : Direction.Left} />
                </div>
                <div className="mgmt-section-body"
                     style={isOpen
                         ? { maxHeight: '4000px', padding: '0.5em 0.5rem' }
                         : { maxHeight: '0', padding: '0' }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
