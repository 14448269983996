import React from 'react';
import { SerializerConfigRenderView, SerializerConfigViewProps } from './SerializerConfigView.types';
import { Accordion, ConfirmationDialog, ModalDialog, ShowIf } from '../../common';
import {
    DeleteSerializerConfigRequest,
    PutSerializerConfigRequest,
    SerializerConfiguration
} from '../../../services/serializer-contexts.types';
import { sortAlphanumericIgnoreCase } from '../../../utils';

import './SerializerConfigView.css';
import { v4 as uuid } from 'uuid';
import { ConfigEntry, SerializerContextGroup } from '../SerializerParametersGroup';
import { AppContext } from "../../../pages/context";

const copyConfigEntryArray = (entries: ConfigEntry[]) => {
    return entries.map(entry => {
        return {...entry};
    });
}

const cloneConfig = (config: SerializerConfigRenderView): SerializerConfigRenderView => {
    const catalogs = copyConfigEntryArray(config.catalogs);
    const contexts = copyConfigEntryArray(config.contexts);
    const customParams = copyConfigEntryArray(config.customParams);
    return {
        name: config.name,
        catalogs: catalogs,
        contexts: contexts,
        customParams: customParams,
        isNew: config.isNew,
    } as SerializerConfigRenderView;
}

const prepareEntries = (values: string[]): ConfigEntry[] => {
    const entries = values.map((value) => ({key: uuid(), value: value} as ConfigEntry));
    entries.sort((c1, c2) => sortAlphanumericIgnoreCase(c1.value, c2.value));
    return entries;
}

const prepareView = (config: SerializerConfiguration): SerializerConfigRenderView => {
    const catalogs = config.catalogs ?? [];
    const contexts = config.contexts ?? [];
    const customParams = config.customParams ?? [];
    return {
        name: config.name,
        catalogs: prepareEntries(catalogs),
        contexts: prepareEntries(contexts),
        customParams: prepareEntries(customParams),
        isNew: config.isNew ?? false,
    } as SerializerConfigRenderView;
}


const SerializerConfigView: React.FC<SerializerConfigViewProps> = ({config, onSave, onDelete}) => {
    const [initialConfig] = React.useState(cloneConfig(prepareView(config)));
    const [currentConfig, setCurrentConfig] = React.useState(cloneConfig(initialConfig));

    const [open, setOpen] = React.useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);

    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([] as string[]);

    const {services: {apiService}} = React.useContext(AppContext);

    const deleteConfigConfirmationMessage = `Are you sure you want to delete serializer context config '${currentConfig.name}'?`;
    const cancelConfigChangesConfirmationMessage = `Are you sure you want to rollback all changes in serializer context config '${currentConfig.name}'?`;
    const saveConfigChangesConfirmationMessage = `Are you sure you want to save changes in serializer context config '${currentConfig.name}'?`;

    const formRef = React.useRef({} as HTMLFormElement);

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setSaveDialogOpen(true);
    }

    const isConfigChanged = initialConfig.isNew || JSON.stringify(currentConfig) !== JSON.stringify(initialConfig)
    const getConfigTitle = () => {
        if (isConfigChanged) {
            return `${currentConfig.name} *`;
        }
        return currentConfig.name;
    };

    const openToggle = () => setOpen(!open);

    const handleCancel = () => setCurrentConfig(cloneConfig(initialConfig));
    const handleDelete = () => {
        const deleteRequestData = {name: currentConfig.name} as DeleteSerializerConfigRequest;
        apiService.deleteSerializerConfig(deleteRequestData)
            .then((data) => {
                setDeleteDialogOpen(false);
                if (data.status === 'OK') {
                    if (onDelete) {
                        onDelete();
                    }
                } else {
                    setErrorMessages([data.message ?? `Failed to delete Serializer Configuration '${currentConfig.name}'. Please contact technical support.`])
                    setDeleteDialogOpen(false);
                    setErrorDialogOpen(true);
                }
            })
            .catch(() => {
                setErrorMessages([`Unexpected error happened while deleting Serializer Configuration '${currentConfig.name}'.`])
                setDeleteDialogOpen(false);
                setErrorDialogOpen(true);
            })
    };

    const getDefaultSaveErrorMessage = () => {
        return `Failed to save Serializer Configuration '${currentConfig.name}'. Please contact technical support.`;
    }

    const handleSave = () => {
        const putRequestData = {
            name: currentConfig.name,
            catalogs: currentConfig.catalogs.map(entry => entry.value) ?? [],
            contexts: currentConfig.contexts.map(entry => entry.value) ?? [],
            customParams: currentConfig.customParams.map(entry => entry.value) ?? [],
        } as PutSerializerConfigRequest;
        apiService.putSerializerConfig(putRequestData)
            .then((data) => {
                setSaveDialogOpen(false);

                if (data.status === 'OK') {
                    if (onSave) {
                        onSave();
                    }
                } else if (data.status === 'VALIDATION_FAILED') {
                    if (data.validationMessages) {
                        setErrorMessages(data.validationMessages);
                    } else {
                        setErrorMessages([getDefaultSaveErrorMessage()])
                    }
                    setDeleteDialogOpen(false);
                    setErrorDialogOpen(true);
                } else {
                    setErrorMessages([data.message ?? getDefaultSaveErrorMessage()]);
                    setErrorDialogOpen(true);
                }
            })
            .catch(() => {
                setErrorMessages([`Unexpected error happened while saving Serializer Configuration '${currentConfig.name}'. Please contact technical support.`])
                setSaveDialogOpen(false);
                setErrorDialogOpen(true);
            })
    };

    const onAddItem = (configGroupMapper: (config: SerializerConfigRenderView) => ConfigEntry[]) => {
        const config = cloneConfig(currentConfig);
        const group = configGroupMapper(config);
        group.push({key: uuid(), value: ''})
        setCurrentConfig(config);
    };

    const onChange = (key: string, value: string, configGroupMapper: (config: SerializerConfigRenderView) => ConfigEntry[]) => {
        const config = cloneConfig(currentConfig);
        const group = configGroupMapper(config);
        const entry = group.find(item => item.key === key);
        if (entry) {
            entry.value = value;
        }
        setCurrentConfig(config);
    };

    const onConfigDelete = (key: string, configGroupMapper: (config: SerializerConfigRenderView) => ConfigEntry[]) => {
        const config = cloneConfig(currentConfig);
        const group = configGroupMapper(config);
        group.forEach((item, index) => {
            if (item.key === key) {
                group.splice(index, 1);
            }
        })
        setCurrentConfig(config);
    };

    return (
        <div className='mgmt-serializer-context-config'>
            <form ref={formRef} onSubmit={handleSubmit}>
                <Accordion title={getConfigTitle()} isOpen={open} onOpen={openToggle} onClose={openToggle}>
                    <div className='mgmt-serializer-context-config-items'>
                        <SerializerContextGroup
                            id={`${currentConfig.name}|contexts`}
                            label='Contexts'
                            params={currentConfig.contexts}
                            onAddItem={() => onAddItem(config => config.contexts)}
                            onChange={(key: string, value: string) => onChange(key, value, config => config.contexts)}
                            onDelete={(key: string) => onConfigDelete(key, config => config.contexts)}/>
                        <SerializerContextGroup
                            id={`${currentConfig.name}|catalogs`}
                            label='Catalogs'
                            params={currentConfig.catalogs}
                            onAddItem={() => onAddItem(config => config.catalogs)}
                            onChange={(key: string, value: string) => onChange(key, value, config => config.catalogs)}
                            onDelete={(key: string) => onConfigDelete(key, config => config.catalogs)}/>
                        <SerializerContextGroup
                            id={`${currentConfig.name}|params`}
                            label='Custom parameters'
                            params={currentConfig.customParams}
                            onAddItem={() => onAddItem(config => config.customParams)}
                            onChange={(key: string, value: string) => onChange(key, value, config => config.customParams)}
                            onDelete={(key: string) => onConfigDelete(key, config => config.customParams)}/>
                    </div>

                    <div className='mgmt-serializer-context-config-btn-panel'>
                        <input className='mgmt-serializer-context-config-btn'
                               disabled={!isConfigChanged}
                               title={isConfigChanged ? 'Rollback changes in configuration' : 'Not available. No changes detected'}
                               type='button'
                               value='Cancel'
                               onClick={() => setCancelDialogOpen(true)}></input>
                        <input className='mgmt-serializer-context-config-btn'
                               disabled={!isConfigChanged}
                               title={isConfigChanged ? 'Save changes in configuration' : 'Not available. No changes detected'}
                               type='submit'
                               value='Save'></input>
                        <input className='mgmt-serializer-context-config-btn' type='button' value='Delete'
                               onClick={() => setDeleteDialogOpen(true)}></input>
                    </div>
                </Accordion>
            </form>
            <ShowIf expression={deleteDialogOpen}>
                <ConfirmationDialog message={deleteConfigConfirmationMessage}
                                    onClose={() => setDeleteDialogOpen(false)}
                                    onConfirm={handleDelete}
                                    isOpen={deleteDialogOpen}/>
            </ShowIf>
            <ShowIf expression={cancelDialogOpen}>
                <ConfirmationDialog message={cancelConfigChangesConfirmationMessage}
                                    onClose={() => setCancelDialogOpen(false)}
                                    onConfirm={handleCancel}
                                    isOpen={cancelDialogOpen}/>
            </ShowIf>
            <ShowIf expression={saveDialogOpen}>
                <ConfirmationDialog message={saveConfigChangesConfirmationMessage}
                                    onClose={() => setSaveDialogOpen(false)}
                                    onConfirm={handleSave}
                                    isOpen={saveDialogOpen}/>
            </ShowIf>
            <ShowIf expression={errorDialogOpen}>
                <ModalDialog isOpen={errorDialogOpen} onClose={() => {
                    setErrorDialogOpen(false);
                    setErrorMessages([]);
                }}>
                    <p style={{margin: 0}}>Operation failed</p>
                    <div>
                        {errorMessages
                            && errorMessages.length > 0
                            && errorMessages.map(message => (<p key={uuid()}>{message}</p>))}
                    </div>
                </ModalDialog>
            </ShowIf>
        </div>
    );
}

export default SerializerConfigView;
