import { CSSProperties } from "react";

export enum Direction {
    Up = 180,
    Down = 0,
    Left = 90,
    Right = 270,
}

export interface CollapseIconProps {
    style?: CSSProperties | undefined;
    direction: Direction;
    color?: string;
}
