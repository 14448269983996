import { Accordion, FormGroup } from "../../common";
import React, { ChangeEvent, useState } from "react";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";
import { AssetsComponentTypes } from "./AssetsComponent.types";


const AssetsComponent: React.FC<AssetsComponentTypes> = (props: AssetsComponentTypes) => {
    const {data, channelId} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }
    const [isIncludedAssetsLevelOpen, setIsIncludedAssetsLevelOpen] = useState(false);
    const toggleIsIncludedAssetsLevelOpen = () => {
        setIsIncludedAssetsLevelOpen(!isIncludedAssetsLevelOpen);
    }
    const [isExcludedAssetsLevelOpen, setIsExcludedAssetsLevelOpen] = useState(false);
    const toggleIsExcludedAssetsLevelOpen = () => {
        setIsExcludedAssetsLevelOpen(!isExcludedAssetsLevelOpen);
    }

    const getId = (fieldName: string): string => {
        return `${channelId}|${fieldName}`;
    };

    return (
        <Accordion title={"Assets"} isOpen={isOpen} onOpen={toggleIsOpen} onClose={toggleIsOpen}>
            <FormGroup id={getId('nativeLanguageOnly')}
                       label="Include Native Languages Only"
                       inputType="checkbox"
                       value={`${data.nativeLanguageOnly}`}
                       onChange={() => {
                           props.onChange({
                               type: ChannelActionType.TOGGLE_ASSETS_NATIVE_LANGUAGE_ONLY
                           });
                       }}
            />
            <div className='mgmt-form-group'>
                <label htmlFor='channel|includeTypes'>Included Types (comma or line separated)</label>
                <textarea id='channel|includeTypes'
                          name='includeTypes'
                          value={data.include?.types}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              props.onChange({
                                  type: ChannelActionType.SET_ASSETS_INCLUDED_TYPES,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|excludeTypes'>Excluded Types (comma or line separated)</label>
                <textarea id='channel|excludeTypes'
                          name='excludeTypes'
                          value={data.exclude?.types}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              props.onChange({
                                  type: ChannelActionType.SET_ASSETS_EXCLUDED_TYPES,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <Accordion title={"Included Access Levels"} isOpen={isIncludedAssetsLevelOpen}
                       onOpen={toggleIsIncludedAssetsLevelOpen} onClose={toggleIsIncludedAssetsLevelOpen}>
                <FormGroup id={getId('includedPublicLevel')}
                           label="Public"
                           inputType="checkbox"
                           value={`${data.include?.accessLevels?.publicLevel}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_ASSETS_INCLUDED_LEVELS_PUBLIC
                               });
                           }}
                />
                <FormGroup id={getId('includedSecureLevel')}
                           label="Secure"
                           inputType="checkbox"
                           value={`${data.include?.accessLevels?.secure}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_ASSETS_INCLUDED_LEVELS_SECURE
                               });
                           }}
                />
                <FormGroup id={getId('includedInternalLevel')}
                           label="Internal"
                           inputType="checkbox"
                           value={`${data.include?.accessLevels?.internal}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_ASSETS_INCLUDED_LEVELS_INTERNAL
                               });
                           }}
                />
            </Accordion>
            <Accordion title={"Excluded Access Levels"} isOpen={isExcludedAssetsLevelOpen}
                       onOpen={toggleIsExcludedAssetsLevelOpen} onClose={toggleIsExcludedAssetsLevelOpen}>
                <FormGroup id={getId('excludedPublicLevel')}
                           label="Public"
                           inputType="checkbox"
                           value={`${data.exclude?.accessLevels?.publicLevel}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_ASSETS_EXCLUDED_LEVELS_PUBLIC
                               });
                           }}
                />
                <FormGroup id={getId('excludedSecureLevel')}
                           label="Secure"
                           inputType="checkbox"
                           value={`${data.exclude?.accessLevels?.secure}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_ASSETS_EXCLUDED_LEVELS_SECURE
                               });
                           }}
                />
                <FormGroup id={getId('excludedInternalLevel')}
                           label="Internal"
                           inputType="checkbox"
                           value={`${data.exclude?.accessLevels?.internal}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_ASSETS_EXCLUDED_LEVELS_INTERNAL
                               });
                           }}
                />
            </Accordion>
        </Accordion>
    );
}

export default AssetsComponent;