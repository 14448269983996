import React, { ChangeEvent, FC, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { Accordion, ConfirmationDialog, ModalDialog, ShowIf } from '../../common';
import { ConfigItemProps, TransferConfigItemView } from './ConfigItemProps.types';

import '../TransferHandler.css';
import './ConfigItem.css';
import TransferHandlerView from '../TransferHandlerView';
import { AppContext } from '../../../pages/context';
import { cloneConfig } from './utils';
import { itemConfigReducer } from './ConfigItem.reducer';

const ConfigItem: FC<ConfigItemProps> = ({ view, onCancel, onSave, onDelete }) => {
    const formRef = React.useRef({} as HTMLFormElement);

    const [config] = React.useState(cloneConfig(view.config));
    const [currentView, dispatch] = React.useReducer(itemConfigReducer, { ...view } as TransferConfigItemView);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [isCancelDialogOpen, setCancelDialogOpen] = React.useState(false);
    const [isSaveDialogOpen, setSaveDialogOpen] = React.useState(false);
    const [isErrorDialogOpen, setErrorDialogOpen] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([] as string[]);
    const [isValidationFailed, setValidationFailed] = React.useState(false);

    const currentConfig = currentView.config;

    const { services: { apiService } } = React.useContext(AppContext);

    const handlers = currentConfig.handlers || [];
    let handlerViewArray: React.ReactNode[];
    if (handlers.length > 0) {
        handlerViewArray = handlers.map(handler => {
            const key = `${currentConfig.id}|${handler.id!}`;
            return (<TransferHandlerView key={key} configId={currentConfig.id} handler={handler} dispatch={dispatch} />)
        });
    } else {
        handlerViewArray = [];
    }

    const getId = (fieldName: string): string => {
        return `${currentConfig.id}|${fieldName}`;
    };

    const getConfigTitle = () => {
        if (JSON.stringify(cloneConfig(currentConfig)) != JSON.stringify(config)) {
            return `${currentConfig.id} *`;
        }
        return currentConfig.id;
    };

    const isOpenToggle = () => dispatch({ type: 'setOpen', payload: !currentView.isOpen });

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
        dispatch({ type: 'cancelConfigChanges', payload: config });
    };

    const updateConfigFailedErrorMessage = `Failed to update transfer configuration with id '${currentView.config.id}'`;
    const handleSave = () => {
        apiService.updateTransferConfig(cloneConfig(currentView.config, false))
            .then((response) => {
                if (response.status === 'ERROR') {
                    const message = response.message ?? updateConfigFailedErrorMessage;
                    setErrorMessages([message]);
                    setErrorDialogOpen(true);
                } else if (response.status === 'VALIDATION_FAILED') {
                    const messages = response.validationMessages;
                    setErrorMessages(messages ?? ['Failed to update transfer configuration']);
                    setErrorDialogOpen(true);
                } else if (onSave) {
                    onSave();
                }
            }).catch(() => {
                setDeleteDialogOpen(false);
                setErrorMessages([updateConfigFailedErrorMessage]);
                setErrorDialogOpen(true);
            })
    }

    const deleteConfigFailedErrorMessage = `Failed to delete transfer configuration with id '${currentView.config.id}'`;
    const handleDelete = () => {
        apiService.deleteTransferConfig(cloneConfig(currentView.config, false))
            .then(response => {
                setDeleteDialogOpen(false);
                if (response.status === 'ERROR') {
                    const message = response.message || deleteConfigFailedErrorMessage;
                    setErrorMessages([message]);
                    setErrorDialogOpen(true);
                } else if (onDelete) {
                    onDelete();
                }
            })
            .catch(() => {
                setDeleteDialogOpen(false);
                setErrorMessages([deleteConfigFailedErrorMessage]);
                setErrorDialogOpen(true);
            });
    }


    const deleteConfigConfirmationMessage = `Are you sure you want to delete config '${currentView.config.id}'?`;
    const cancelConfigChangesConfirmationMessage = `Are you sure you want to rollback all changes in config '${currentView.config.id}'?`;
    const saveConfigChangesConfirmationMessage = `Do you want to save changes in config '${currentView.config.id}'?`;

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity()) {
            setSaveDialogOpen(true);
        } else {
            const elements = form.elements;
            for (let i = 0; i < elements.length; i++) {
                const element = elements.item(i) as HTMLInputElement;
                if (element.validity.valid) {
                    continue;
                }
                const id = element.id;
                const idParts = id.split('|');
                dispatch({ type: 'editHandlerOpen', id: idParts[1], isOpen: true });
                setValidationFailed(true);
            }
        }
    }

    useEffect(() => {
        const form = formRef.current;
        if (isValidationFailed && !form.checkValidity()) {
            form.reportValidity();
            // errors are reported.
            setValidationFailed(false);
        }
    }, [currentConfig, isValidationFailed]);

    return (
        <div>
            <form ref={formRef} onSubmit={handleSubmit} noValidate={true}>
                <Accordion title={getConfigTitle()}
                    isOpen={currentView.isOpen}
                    onOpen={isOpenToggle}
                    onClose={isOpenToggle}>
                    <div className='mgmt-form-group'>
                        <label htmlFor={getId('type')}>Type</label>
                        <select id={getId('type')}
                            value={currentConfig.type?.toUpperCase()}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                dispatch({ type: 'editType', payload: event.target.value });
                            }}>
                            <option value='CHANNEL'>Channel</option>
                            <option value='SERIALIZER'>Serializer</option>
                        </select>
                    </div>
                    <div className='mgmt-form-group'>
                        <label htmlFor={getId('enabled')}>Enabled?</label>
                        <input id={getId('enabled')}
                            type='checkbox'
                            checked={currentConfig.enabled}
                            onChange={() => dispatch({ type: 'setEnabled', payload: !currentConfig.enabled })} />
                    </div>
                    {handlerViewArray}
                    <div className='mgmt-config-panel'>
                        <input className='mgmt-config-panel-btn' type='button' value='Cancel'
                            onClick={() => setCancelDialogOpen(true)}></input>
                        <input className='mgmt-config-panel-btn' type='button' value='Add Handler'
                            onClick={() => { dispatch({ type: 'addNewHandler' }) }}></input>
                        <input className='mgmt-config-panel-btn' type='submit' value='Save'></input>
                        <input className='mgmt-config-panel-btn' type='button' value='Delete'
                            onClick={() => setDeleteDialogOpen(true)}></input>
                    </div>
                </Accordion>
            </form>
            <ShowIf expression={isDeleteDialogOpen}>
                <ConfirmationDialog message={deleteConfigConfirmationMessage}
                    onClose={() => { setDeleteDialogOpen(false) }}
                    onConfirm={() => { handleDelete() }}
                    isOpen={isDeleteDialogOpen} />
            </ShowIf>
            <ShowIf expression={isCancelDialogOpen}>
                <ConfirmationDialog message={cancelConfigChangesConfirmationMessage}
                    onClose={() => { setCancelDialogOpen(false) }}
                    onConfirm={() => { handleCancel() }}
                    isOpen={isCancelDialogOpen} />
            </ShowIf>
            <ShowIf expression={isSaveDialogOpen}>
                <ConfirmationDialog message={saveConfigChangesConfirmationMessage}
                    onClose={() => { setSaveDialogOpen(false) }}
                    onConfirm={() => { handleSave() }}
                    isOpen={isSaveDialogOpen} />
            </ShowIf>
            <ShowIf expression={isErrorDialogOpen}>
                <ModalDialog isOpen={isErrorDialogOpen} onClose={() => {
                    setErrorDialogOpen(false);
                    setErrorMessages([]);
                }}>
                    <p style={{ margin: 0 }}>Operation failed</p>
                    <div>
                        {errorMessages
                            && errorMessages.length > 0
                            && errorMessages.map(message => (<p key={uuid()}>{message}</p>))}
                    </div>
                </ModalDialog>
            </ShowIf>
        </div>
    );
}

export default ConfigItem;
