import React from 'react';
import { AppContext } from '../context';
import { TimedTaskAction, TimedTaskViewModel } from "./Timed.types";
import { TimedTaskComponent } from '../../components';
import { LoadingSpinner, ShowIf } from '../../components/common';
import Snackbar from "../../components/common/Snackbar";

import './Timed.css';

function timedTasksReducer(state: Map<string, TimedTaskViewModel>,
                           action: TimedTaskAction): Map<string, TimedTaskViewModel> {
    switch (action.type) {
        case 'setAll': {
            return new Map<string, TimedTaskViewModel>(action.payload);
        }
        default: {
            throw Error(`unsupported action ${action}`);
        }
    }
}

const TimedPage = () => {
    const [isLoading, setLoading] = React.useState(false);
    const [lastLoadTime, setLastLoadTime] = React.useState((new Date()).getTime());
    const [tasksView, dispatch] = React.useReducer(timedTasksReducer, new Map<string, TimedTaskViewModel>());
    const [newTaskName, setNewTaskName] = React.useState('');
    const [selectedTaskType, setSelectedTaskType] = React.useState('generic');
    const [errorMessage, setErrorMessage] = React.useState('A task with this name already exists')
    const [taskError, setTaskError] = React.useState(false);

    const {services: {apiService}} = React.useContext(AppContext);

    React.useEffect(() => {
        setLoading(true);
        apiService.getTimedTasks().then(response => {
            const tasks = response || [];

            const tasksViewMap: Map<string, TimedTaskViewModel> = new Map<string, TimedTaskViewModel>([]);
            tasks.forEach(task => {
                // generate IDs for all handlers, so, they will be correctly rendered
                tasksViewMap.set(task.taskName, { task: task, isOpen: false } as TimedTaskViewModel);
            });

            dispatch({
                type: 'setAll',
                payload: tasksViewMap,
            } as TimedTaskAction);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, [apiService, lastLoadTime]);

    const reloadConfigs = () => setLastLoadTime((new Date()).getTime());

    const handleNewTask = () => {
        const existingNames = Array.from(tasksView.values()).map(view => view.task.taskName);
        if (existingNames.indexOf(newTaskName) !== -1) {
            setTaskError(true);
            return;
        }

        setTaskError(false);
        const newTask = {taskName: newTaskName, type: selectedTaskType};
        apiService.createTimedTask(newTask)
            .then(() => {
                const fullTaskDetails = {
                    ...newTask,
                    history: [],
                    image: '',
                    schedule: '',
                    logGroup: '',
                    serializerName: ''
                };
                const updatedTasks = new Map(tasksView);
                updatedTasks.set(newTaskName, {task: fullTaskDetails, isOpen: false});
                dispatch({type: 'setAll', payload: updatedTasks});
                setNewTaskName('');
            })
            .catch (error => {
                console.log(error);
                setErrorMessage('An error occurred while creating the timed configuration for task');
                setTaskError(true);
            });
    };

    const onTaskNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTaskName(e.target.value);
        setTaskError(false);
    };

    const transferConfigListView = Array.from(tasksView.values()).map(task =>
        <TimedTaskComponent key={task.task.taskName} view={task} onSave={reloadConfigs} />
    );

    return (
        <div>
            <h2>Timed page</h2>
            <ShowIf expression={isLoading}>
                <LoadingSpinner />
            </ShowIf>
            <ShowIf expression={!isLoading}>
                <div className='mgmt-timed-form-container'>
                    Task Name:
                    <input
                        type='text'
                        value={newTaskName}
                        onChange={onTaskNameChange}
                        className='mgmt-timed-form-item'
                    />
                    Task Type:
                    <select
                        value={selectedTaskType}
                        onChange={e => setSelectedTaskType(e.target.value)}
                        className='mgmt-timed-form-item'
                    >
                        <option value='generic'>Generic</option>
                        <option value='executor-delta'>Executor-Delta</option>
                        <option value='executor-full'>Executor-Full</option>
                    </select>
                    <button
                        onClick={handleNewTask}
                        disabled={!newTaskName.trim()}
                        title={!newTaskName.trim() ? "Type a Task Name" : ""}
                        className='mgmt-timed-form-item'>
                        Create New Task
                    </button>
                    <Snackbar message={errorMessage} isShown={taskError} />
                </div>
                {transferConfigListView}
            </ShowIf>
        </div>
    );
}

export default TimedPage;
