import { TimedTaskAction, TimedTaskViewModel } from '../../../pages/Timed/Timed.types';

export function timedTaskReducer(state: TimedTaskViewModel, action: TimedTaskAction): TimedTaskViewModel {
    switch (action.type) {
        case 'cancelTaskChanges': {
            return { task: action.payload, isOpen: true };
        }
        case 'setOpen': {
            return { ...state, isOpen: action.payload };
        }
        case 'editImage': {
            return { ...state, task: {...state.task, image: action.payload}};
        }
        case 'editSchedule': {
            return { ...state, task: {...state.task, schedule: action.payload}};
        }
        case 'editLogGroup': {
            return { ...state, task: {...state.task, logGroup: action.payload}};
        }
        case 'editSerializerName': {
            return { ...state, task: {...state.task, serializerName: action.payload}};
        }
        default:
            throw Error(`Unsupported action: '${action}'`);
    }
}