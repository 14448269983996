import React from 'react';

import './TimedTable.css';
import { TimedLogMapper, TimedTableProps } from './TimedTable.types';
import { ShowIf } from '../../common';
import { TimedLog } from "../../../services/timed.types";

const Table: React.FC<TimedTableProps> = ({ headers, logs, cellMappers }: TimedTableProps) => {
  return (
    <div className="mgmt-timed-table">
      <div className="mgmt-timed-table-headers">
        <div className="mgmt-timed-table-row">
          {headers.map((header: string, idx) => {
            const headerKey = `mgmt-TimedTableHeader-${idx}`;
            return <div key={headerKey} className="mgmt-timed-table-header">{header}</div>;
          })}
        </div>
      </div>
      <ShowIf expression={!(logs === undefined || logs.length == 0)}>
        <div className="mgmt-timed-table-body">
          {logs!.map((datum: TimedLog, rowIdx: number) => {
            const rowKey = `mgmt-TimedTableRow-${rowIdx}`;
            return (
              <div key={rowKey} className="mgmt-timed-table-row">
                {cellMappers!.map((mapper: TimedLogMapper, cellIdx) => {
                  const cellKey = `mgmt-TimedTableCell-${rowIdx}-${cellIdx}`;
                  return (
                    <div key={cellKey} className="mgmt-timed-table-cell">{mapper(datum)}</div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </ShowIf>
      <ShowIf expression={logs === undefined || logs.length == 0}>
        <div className="mgmt-timed-table-body">
          {headers.map((_, idx) => {
            const logsKey = `mgmt-TimedTableCellNoLogs-${idx}`;
            return <div key={logsKey} className="mgmt-timed-table-cell">{" - "}</div>;
          })}
        </div>
      </ShowIf>
    </div>
  );
};

export default Table;
