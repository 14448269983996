import React from 'react';
import { ManagementPageType, ManagementProps, MenuItemProps } from './Management.props';

import './ManagementPage.css';
import { Link } from "react-router-dom";
import { PATHS } from '../../config/paths';
import { Menu } from '../../components';

const getClassName = (itemType: ManagementPageType, selectedItemType: ManagementPageType) => {
    return itemType == selectedItemType ? 'mgmt-management-item-active' : 'hover-underline-animation';
}

const MenuItem: React.FC<MenuItemProps> = ({label, path, itemType, selectedItemType}) => {
    return (
        <li>
            <Link to={`${PATHS.MANAGEMENT.BASE}${path}`} className={getClassName(itemType, selectedItemType)}>
                <span className='underline-text'>{label}</span>
            </Link>
        </li>
    );
}

const ManagementPage: React.FC<ManagementProps> = ({children, selectedItemType}) => {
    const [isMenuOpen, setMenuOpen] = React.useState(false);
	const [isManagementOpen, setIsManagementOpen] = React.useState(true);
	const [isMonitoringOpen, setMonitoringOpen] = React.useState(true);
	const [isLogoffConfirmationDialogOpen, setIsLogoffConfirmationDialogOpen] = React.useState(false);

    return (
        <div className='mgmt-management'>
            <Menu
                isMenuOpen={isMenuOpen}
                setMenuOpen={setMenuOpen}
                isManagementOpen={isManagementOpen}
                setIsManagementOpen={setIsManagementOpen}
                isMonitoringOpen={isMonitoringOpen}
                setMonitoringOpen={setMonitoringOpen}
                isLogoffConfirmationDialogOpen={isLogoffConfirmationDialogOpen}
                setIsLogoffConfirmationDialogOpen={setIsLogoffConfirmationDialogOpen}
            />
            <div className="mgmt-management-content">
                <div className='mgmt-management-section-title'>
                    <h1>Management</h1>
                </div>
                <div className='mgmt-management-section-panel'>
                    <ul>
                        <MenuItem label='Timed' path='/timed/' itemType={ManagementPageType.Timed}
                                  selectedItemType={selectedItemType}/>
                        <MenuItem label='OTS' path='/transfer/' itemType={ManagementPageType.OTS}
                                  selectedItemType={selectedItemType}/>
                        <MenuItem label='Tsunami' path='/tsunami/' itemType={ManagementPageType.Tsunami}
                                  selectedItemType={selectedItemType}/>
                        <MenuItem label='Autoscaling Override' path='/autoscaling-override/'
                                  itemType={ManagementPageType.AutoscalingOverride} selectedItemType={selectedItemType}/>
                        <MenuItem label='Users' path='/users/' itemType={ManagementPageType.Users}
                                  selectedItemType={selectedItemType}/>
                        <MenuItem label='Serializer Contexts' path='/serializer-contexts/'
                                  itemType={ManagementPageType.SerializerContexts} selectedItemType={selectedItemType}/>
                        <MenuItem label='Ingest Products Batch' path='/reprocess-ingest-products-batch/'
                                  itemType={ManagementPageType.IngestProductsBatch} selectedItemType={selectedItemType}/>
                        <MenuItem label='Run Channel' path='/run-channel/' itemType={ManagementPageType.RunChannel}
                                  selectedItemType={selectedItemType}/>
                        <MenuItem label='Channels' path='/channels/' itemType={ManagementPageType.Channels}
                                  selectedItemType={selectedItemType}/>
                    </ul>
                </div>
                <div className='mgmt-management-section-content'>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ManagementPage;
