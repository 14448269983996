import axios, { AxiosRequestConfig } from 'axios';
import {
    CreateTimedTaskRequest,
    DeleteTransferConfigResponse,
    ListTablesForLayerResponse,
    LogSearchRequest,
    LogSearchResponse,
    ReprocessIngestProductsProps,
    ReprocessIngestProductsResponse,
    RestResponse,
    RunTimedTaskRequest,
    RunTimedTaskResponse,
    TsunamiStartRequest,
    TsunamiStartResponse,
    UpdateTimedTaskRequest,
    UpdateTimedTaskResponse,
    UpdateTransferConfigResponse,
} from './api.types';
import { TransferConfig, TransferListResponse } from './ots.types';
import { User } from './user.types';
import {
    Channel,
    ListChannelsResponse,
    RunChannelConfig,
    RunChannelResponse,
    UpdateChannelResponse,
    CreateChannelResponse
} from './channel.types';
import {
    DeleteSerializerConfigRequest,
    DeleteSerializerConfigResponse,
    ListSerializerConfigResponse,
    PutSerializerConfigRequest,
    PutSerializerConfigResponse,
} from './serializer-contexts.types';
import { TimedTask } from './timed.types';
import { cleanChannelData } from "../util/channelUtil";

export class ApiService {
    jwtSupplier: () => Promise<string>;

    constructor(jwtSupplier: () => Promise<string>) {
        this.jwtSupplier = jwtSupplier;
    }

    async getRequestConfig(): Promise<AxiosRequestConfig> {
        const jwt = await this.jwtSupplier();
        return {
            headers: {
                'Authorization': 'Bearer ' + jwt,
            }
        } as AxiosRequestConfig;
    }

    async reprocessIngestProducts(data: ReprocessIngestProductsProps): Promise<ReprocessIngestProductsResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<ReprocessIngestProductsResponse>('/gui/api/ingest/denormalized/reprocessing', data, config)
            .then(response => response.data)
    }

    async listUsers(): Promise<User[]> {
        const config = await this.getRequestConfig();
        return await axios.get<User[]>('/gui/api/user/list', config)
            .then(response => response.data).then(data => data || []);
    }

    async updateUser(user: User): Promise<RestResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<RestResponse>('/gui/api/user/update', user, config)
            .then(response => response.data)
    }

    async deleteUser(user: User): Promise<RestResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<RestResponse>('/gui/api/user/delete', user, config)
            .then(response => response.data)
    }

    async createUser(user: User): Promise<RestResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<RestResponse>('/gui/api/user/insert', user, config)
            .then(response => response.data)
    }

    async listTransferConfigs(): Promise<TransferListResponse> {
        const config = await this.getRequestConfig();
        return await axios.get<TransferListResponse>('/gui/api/transfer/list', config)
            .then(response => response.data);
    }

    async addTransferConfig(data: TransferConfig): Promise<RestResponse> {
        return this.getRequestConfig()
            .then(config => {
                return axios.post<RestResponse>('/gui/api/transfer/create', data, config);
            })
            .then(response => {
                if (!response || !response.data || response.data.status !== 'OK') {
                    throw Error("An error occurred while creating the new transfer configuration");
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
                throw error;
            })
    }

    async updateTransferConfig(data: TransferConfig): Promise<UpdateTransferConfigResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<UpdateTransferConfigResponse>('/gui/api/transfer/update', data, config)
            .then(response => response.data);
    }

    async deleteTransferConfig(data: TransferConfig): Promise<DeleteTransferConfigResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<UpdateTransferConfigResponse>('/gui/api/transfer/delete', data, config)
            .then(response => response.data)
    }

    async listChannels(): Promise<ListChannelsResponse> {
        const config = await this.getRequestConfig();
        return await axios.get<ListChannelsResponse>('/gui/api/channel/list', config)
            .then(response => {
                if (!response || !response.data || response.data.status !== 'OK') {
                    console.error("Backend error :", response.data);
                    throw Error(`Cannot list channels`);
                }
               if (response.data.channels) {
                   const cleanedChannels = response.data.channels.map((channel: Channel) => {
                       return cleanChannelData(channel, "toEmptyStr") as Channel;
                   });
                   return {...response.data, channels: cleanedChannels};
               }
               return response.data
            })
            .catch(error => {
                console.error(error);
                throw new Error("Cannot get channels list");
            });
    }

    async runChannel(runChannelConfig: RunChannelConfig): Promise<RunChannelResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<RunChannelResponse>('/gui/api/runchannel', runChannelConfig, config)
            .then(response => response.data);
    }

    async updateChannel(data: Channel): Promise<UpdateChannelResponse> {
        const cleanedChannel = cleanChannelData(data, 'toNull');
        return this.getRequestConfig()
            .then(config => {
                return axios.post<UpdateChannelResponse>('/gui/api/channel/update', cleanedChannel, config);
            })
            .then(response => {
                if (!response || !response.data || response.data.status !== 'OK') {
                    console.log(response);
                    if (response.data.status === "VALIDATION_FAILED") {
                        return response.data;
                    }
                    throw Error(`An error occurred while updating channel ${data.channelId}`);
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
                throw error;
            });
    }

    async addChannel(data: Channel): Promise<CreateChannelResponse> {
        const cleanedChannel = cleanChannelData(data, 'toNull');
        return this.getRequestConfig()
            .then(config => {
                return axios.post<CreateChannelResponse>('/gui/api/channel/insert', cleanedChannel, config);
            })
            .then(response => {
                if (!response || !response.data || response.data.status !== 'OK') {
                    console.log(response);
                    if (response.data.status === "VALIDATION_FAILED") {
                        return response.data;
                    }
                    throw Error(`An error occurred while creating channel ${data.channelId}`);
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
                throw error;
            });
    }

    async deleteChannel(data: Channel): Promise<RestResponse> {
        const cleanedChannel = cleanChannelData(data, 'toNull');
        return this.getRequestConfig()
            .then(config => {
                return axios.post<RestResponse>('/gui/api/channel/delete', cleanedChannel, config);
            })
            .then(response => {
                if (!response || !response.data || response.data.status !== 'OK') {
                    console.log(response);
                    throw Error(`An error occurred while deleting channel ${data.channelId}`);
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
                throw error;
            });
    }

    async listSerializerConfigs(): Promise<ListSerializerConfigResponse> {
        const config = await this.getRequestConfig();
        return await axios.get<ListSerializerConfigResponse>('/gui/api/serializer-configuration', config)
            .then(response => response.data)
    }

    async putSerializerConfig(request: PutSerializerConfigRequest): Promise<PutSerializerConfigResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<PutSerializerConfigResponse>('/gui/api/serializer-configuration', request, config)
            .then(response => response.data)
    }

    async deleteSerializerConfig(request: DeleteSerializerConfigRequest): Promise<DeleteSerializerConfigResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<DeleteSerializerConfigResponse>('/gui/api/serializer-configuration/delete', request, config)
            .then(response => response.data)
    }

    async getTimedTasks(): Promise<TimedTask[]> {
        const config = await this.getRequestConfig();
        return await axios.get<TimedTask[]>('/gui/api/timed/list', config)
            .then(response => response.data)
    }

    async createTimedTask(data: CreateTimedTaskRequest): Promise<RestResponse> {
        return this.getRequestConfig()
            .then(config => {
                return axios.post<RestResponse>('/gui/api/timed/create', data, config);
            })
            .then(response => {
                if (!response || !response.data || response.data.status !== "OK") {
                    throw Error('An error occurred while updating the timed configuration for task');
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
                throw error;
            })
    }

    async updateTimedTasks(data: UpdateTimedTaskRequest): Promise<UpdateTimedTaskResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<UpdateTimedTaskResponse>('/gui/api/timed/update', data, config)
            .then(response => response.data)
    }

    async runTimedTasks(data: RunTimedTaskRequest): Promise<RunTimedTaskResponse> {
        const config = await this.getRequestConfig();
        return await axios.post<UpdateTimedTaskResponse>('/gui/api/timed/startmanual', data, config)
            .then(response => response.data)
    }

    async searchLogs(requestParams: LogSearchRequest, signal: AbortSignal): Promise<LogSearchResponse> {
        const config = await this.getRequestConfig();
        return await axios.get<LogSearchResponse>('/gui/api/monitor/logsearch', {
            params: requestParams,
            signal: signal,
            ...config,
        })
        .then(response => response.data)
    }

async listTablesForLayer(layer: string): Promise<ListTablesForLayerResponse> {
        return this.getRequestConfig()
            .then(config => {
                return axios.get<ListTablesForLayerResponse>('/gui/api/table-metadata', {
                    params: { layer },
                    ...config,
                });
            })
            .then(response => {
                if (!response || !response.data || response.data.status !== 'OK') {
                    throw Error("An error occurred while fetching the table metadata");
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
                throw error;
            });
    }

    async startTsunami(data: TsunamiStartRequest): Promise<TsunamiStartResponse> {
        return this.getRequestConfig()
            .then(config => {
                return axios.post<TsunamiStartResponse>('/gui/api/tsunami', data, config);
            })
            .then(response => {
                if (!response || !response.data || response.data.status !== 'OK') {
                    throw Error("An error occurred while starting the tsunami");
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
                throw error;
            });
    }

    async overrideAutoscaling(): Promise<RestResponse> {
        return this.getRequestConfig()
            .then(config => {
                return axios.post<RestResponse>('/gui/api/autoscaling-override', {}, config);
            })
            .then(response => {
                if (!response || !response.data || response.data.status !== 'OK') {
                    throw Error("An error occurred while overriding autoscaling");
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
                throw error;
            });
    }
}
