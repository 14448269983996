import React from 'react';
import { NamedCheckBoxProps } from './NamedCheckBox.props';

import './NamedCheckBox.css';

const NamedCheckBox: React.FC<NamedCheckBoxProps> = ({ label, checked, onChange }) => {
    return (
        <div className="mgmt-named-checkbox">
            <label className="mgmt-named-checkbox-label">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                /> {label}
            </label>
        </div>
    );
};

export default NamedCheckBox;
