import React, { FC } from "react";

import './AccordionMenu.css';

import { AccordionProps } from "./AccordionMenu.types";
import { Direction } from "../icons";
import { ShowIf } from "../Condition";
import MenuCollapseIcon from "../icons/MenuCollapseIcon";

const Accordion: FC<AccordionProps> = ({isOpen, children, onOpen, onClose}) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleOpen = () => {
        if (onOpen) {
            onOpen();
        }
    }

    return (
        <>
            <ShowIf expression={isOpen}>
                <div className="mgmt-menu-collapsable">
                    <div className='mgmt-menu-section-title' onClick={() => handleClose()}>
                        <MenuCollapseIcon direction={Direction.Left}/>
                    </div>
                    <div className="mgmt-menu-section-body" hidden={!isOpen}>
                        {children}
                    </div>
                </div>
            </ShowIf>
            <ShowIf expression={!isOpen}>
                <div className="mgmt-menu-collapsable-closed" onClick={() => handleOpen()}>
                    <div className='mgmt-menu-section-title-closed'>
                        <MenuCollapseIcon direction={Direction.Right}/>
                    </div>
                </div>
            </ShowIf>
        </>
    );

};

export default Accordion;
