import React from "react";

export interface ShowIfProps {
    expression: boolean;
    children: React.ReactNode[] | React.ReactNode;
}

export const ShowIf: React.FC<ShowIfProps> = ({expression, children}) => {
    if (expression) {
        return (
            <>
                {children}
            </>
        );
    }
}
