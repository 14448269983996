export enum FullFeedType {
    CHANNEL_CONFIG = 'channel_config',
    YES = 'yes',
    NO = 'no',
}

export interface RunChannelProps {
    channelId?: string;
    prefix?: string;
    contextList?: string;
    productList?: string;
    modifiedSince?: string;
    fullFeedType: FullFeedType;
}