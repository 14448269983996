import { v4 as uuid } from 'uuid';

import { TransferHandler } from "../../../services";
import {
    AzureTransferHandlerConfig,
    S3TransferHandlerConfig,
    SftpTransferHandlerConfig,
    ZipTransferHandlerConfig
} from "../../../services/ots.types";
import { ConfigItemAction, TransferConfigItemView } from "./ConfigItemProps.types";
import { copyConfig } from "./utils";

function copyHandlersWithUpdate(handlers: TransferHandler[], handlerId: string, updateHandler: (handler: TransferHandler) => void) {
    return handlers.map(h => {
        if (h.id === handlerId) {
            const handler = { ...h };
            handler.transportConfiguration = { ...handler.transportConfiguration };
            updateHandler(handler);
            return handler;
        }
        return h;
    });
}

function editHandlerInState(state: TransferConfigItemView, handlerId: string, updateValue: (handler: TransferHandler) => void) {
    const handlers = copyHandlersWithUpdate(state.config.handlers, handlerId, (handler) => updateValue(handler));
    const config = copyConfig(state.config, handlers);
    return { config, isOpen: state.isOpen };
}

function editAzureHandlerConfig(state: TransferConfigItemView, handlerId: string, updateConfigValue: (config: AzureTransferHandlerConfig) => void) {
    const handlers = copyHandlersWithUpdate(state.config.handlers, handlerId, (handler) => {
        if ('azure' === handler.transportType) {
            updateConfigValue(handler.transportConfiguration);
        }
    });
    const config = copyConfig(state.config, handlers);
    return { config, isOpen: state.isOpen };
}

function editSftpHandlerConfig(state: TransferConfigItemView, handlerId: string, updateConfigValue: (config: SftpTransferHandlerConfig) => void) {
    const handlers = copyHandlersWithUpdate(state.config.handlers, handlerId, (handler) => {
        if ('sftp' === handler.transportType) {
            updateConfigValue(handler.transportConfiguration);
        }
    });
    const config = copyConfig(state.config, handlers);
    return { config, isOpen: state.isOpen };
}

function editS3HandlerConfig(state: TransferConfigItemView, handlerId: string, updateConfigValue: (config: S3TransferHandlerConfig) => void) {
    const handlers = copyHandlersWithUpdate(state.config.handlers, handlerId, (handler) => {
        if ('s3' === handler.transportType) {
            updateConfigValue(handler.transportConfiguration);
        }
    });
    const config = copyConfig(state.config, handlers);
    return { config, isOpen: state.isOpen };
}

function editZipHandlerConfig(state: TransferConfigItemView, handlerId: string, updateConfigValue: (config: ZipTransferHandlerConfig) => void) {
    const handlers = copyHandlersWithUpdate(state.config.handlers, handlerId, (handler) => {
        if ('zips3' === handler.transportType || 'zipsftp' === handler.transportType) {
            updateConfigValue(handler.transportConfiguration);
        }
    });
    const config = copyConfig(state.config, handlers);
    return { config, isOpen: state.isOpen };
}

export function itemConfigReducer(state: TransferConfigItemView, action: ConfigItemAction): TransferConfigItemView {
    switch (action.type) {
        case 'cancelConfigChanges': {
            return { config: action.payload, isOpen: true };
        }
        case 'editType': {
            const config = { ...state.config, type: action.payload };
            return { config, isOpen: state.isOpen };
        }
        case 'setOpen': {
            return { ...state, isOpen: action.payload };
        }
        case 'setEnabled': {
            const config = { ...state.config, enabled: action.payload };
            return { config, isOpen: state.isOpen };
        }
        case 'addNewHandler': {
            const handlers: TransferHandler[] = [];
            if (state.config.handlers.length > 0) {
                state.config.handlers.forEach(handler => {
                    const newHandlerState = { ...handler };
                    newHandlerState.transportConfiguration = { ...handler.transportConfiguration };
                    handlers.push(newHandlerState);
                });
            }
            handlers.push({
                id: uuid(),
                enabled: false,
                transportType: 'sftp',
                transportConfiguration: {} as SftpTransferHandlerConfig,
                filter: '',
                name: '',
                isOpen: true,
            });
            const config = copyConfig(state.config, handlers);
            return { config, isOpen: state.isOpen };
        }
        case 'deleteHandler': {
            const handlers: TransferHandler[] = [];
            if (state.config.handlers.length > 0) {
                state.config.handlers
                    .filter(handler => handler.id !== action.id)
                    .forEach(handler => handlers.push(handler));
            }
            const config = copyConfig(state.config, handlers);
            return { config, isOpen: state.isOpen };
        }
        case 'editHandlerName': {
            return editHandlerInState(state, action.id, (handler) => handler.name = action.newName);
        }
        case 'editHandlerOpen': {
            return editHandlerInState(state, action.id, (handler) => handler.isOpen = action.isOpen);
        }
        case 'editHandlerEnabled': {
            return editHandlerInState(state, action.id, (handler) => handler.enabled = action.enabled);
        }
        case 'editHandlerFilter': {
            return editHandlerInState(state, action.id, (handler) => handler.filter = action.filter);
        }
        case 'editHandlerTransportType': {
            return editHandlerInState(state, action.id, (handler) => {
                switch (action.transportType) {
                    case 'sftp':
                    case 'zipsftp':
                    case 's3':
                    case 'zips3':
                    case 'azure': {
                        handler.transportType = action.transportType;
                        break;
                    }
                    default: throw Error(`Failed to change transport type for Handler '${action.id}' with type ${action.transportType}`);
                }
            });
        }
        // Azure events
        case 'editHandlerAccountName': {
            return editAzureHandlerConfig(state, action.id, (config) => config.accountName = action.accountName);
        }
        case 'editHandlerAccountKey': {
            return editAzureHandlerConfig(state, action.id, (config) => config.accountKey = action.accountKey);
        }
        case 'editHandlerContainerName': {
            return editAzureHandlerConfig(state, action.id, (config) => config.containerName = action.containerName);
        }
        case 'editHandlerPath': {
            return editAzureHandlerConfig(state, action.id, (config) => config.path = action.path);
        }
        // S3 events
        case 'editHandlerBucket': {
            return editS3HandlerConfig(state, action.id, (config) => config.bucket = action.bucket);
        }
        case 'editHandlerRegion': {
            return editS3HandlerConfig(state, action.id, (config) => config.region = action.region);
        }
        case 'editHandlerKeyPrefix': {
            return editS3HandlerConfig(state, action.id, (config) => config.keyPrefix = action.keyPrefix);
        }
        case 'editHandlerRoleArn': {
            return editS3HandlerConfig(state, action.id, (config) => config.roleArn = action.roleArn);
        }
        // SFTP events
        case 'editHandlerPemFile': {
            return editSftpHandlerConfig(state, action.id, (config) => config.pemFile = action.pemFile);
        }
        case 'editHandlerHost': {
            return editSftpHandlerConfig(state, action.id, (config) => config.host = action.host);
        }
        case 'editHandlerPort': {
            return editSftpHandlerConfig(state, action.id, (config) => config.port = action.port);
        }
        case 'editHandlerUser': {
            return editSftpHandlerConfig(state, action.id, (config) => config.user = action.user);
        }
        case 'editHandlerWorkingDir': {
            return editSftpHandlerConfig(state, action.id, (config) => config.workingDir = action.workingDir);
        }
        case 'editHandlerAuthData': {
            return editSftpHandlerConfig(state, action.id, (config) => config.authData = action.authData);
        }
        case 'editHandlerAuthMethod': {
            return editSftpHandlerConfig(state, action.id, (config) => config.authMethod = action.authMethod);
        }
        case 'editHandlerZipFileName': {
            return editZipHandlerConfig(state, action.id, (config) => config.zipFileName = action.zipFileName);
        }
        default:
            throw Error(`Unsupported action: '${action}'`);
    }
}