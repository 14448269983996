import React from 'react';

import { DropdownItem, DropdownProps } from './Dropdown.types';

import './Dropdown.css';

const Dropdown: React.FC<DropdownProps> = ({ disabled, items, selectedItem, onSelect }: DropdownProps) => {

    return (
        <div className="mgmt-dropdown">
            <select disabled={disabled ?? false} name="dropdown_select" id="dropdown_select" value={selectedItem}
                    onChange={(event) => onSelect(event.target.value)}>
                {items.map((item: DropdownItem) => {
                    return (<option key={item.id} value={item.id}>{item.displayValue}</option>)
                })}
            </select>
        </div>
    );
}

export default Dropdown;