import React from 'react';

import './NotFoundPage.css';
import { useNavigate } from "react-router-dom";
import { PATHS } from '../../config/paths';

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className='mgmt-page'>
            <div className='mgmt-page-not-found'>
                <div className='mgmt-not-found-title'>
                    <h1>Page not found</h1>
                </div>
                <div className='mgmt-not-found-body'>
                    <p>The page you were looking for does not exist.</p>
                    <button onClick={() => { navigate(PATHS.HOME); }}>Go Home</button>
                </div>
            </div>
        </div>
    );
}

export default NotFoundPage;
