import React from 'react';

import { FormGroup } from '../../common';
import { ZipSftpTransferHandlerProps } from './ZipSftpTransferHandler.types';

import '../TransferHandler.css';
import {
    HOSTNAME_PATTERN,
    PEM_FILE_PATTERN,
    PORT_PATTERN,
    USER_PATTERN,
    WORKING_DIR_PATTERN,
    ZIP_FILENAME_PATTERN
} from '../constants';

const ZipSftpTransferHandler: React.FC<ZipSftpTransferHandlerProps> = ({ handler, dispatch, getFieldId }) => {
    return (
        <>
            <div className='mgmt-form-group'>
                <label className='mgmt-parameters-title'>Zip SFTP parameters</label>
            </div>
            <FormGroup id={getFieldId('pem_file')} label='Key File' inputType='text'
                value={handler.transportConfiguration.pemFile || ""}
                pattern={PEM_FILE_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerPemFile',
                    id: handler.id!,
                    pemFile: event.target.value,
                })} />
            <FormGroup id={getFieldId('host')} label='Host' inputType='text'
                value={handler.transportConfiguration.host}
                pattern={HOSTNAME_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerHost',
                    id: handler.id!,
                    host: event.target.value,
                })} />
            <FormGroup id={getFieldId('port')} label='Port' inputType='text'
                value={handler.transportConfiguration.port}
                pattern={PORT_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerPort',
                    id: handler.id!,
                    port: event.target.value,
                })} />
            <FormGroup id={getFieldId('user')} label='User' inputType='text'
                value={handler.transportConfiguration.user}
                pattern={USER_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerUser',
                    id: handler.id!,
                    user: event.target.value,
                })} />
            <FormGroup id={getFieldId('working_dir')} label='Working Directory' inputType='text'
                value={handler.transportConfiguration.workingDir}
                pattern={WORKING_DIR_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerWorkingDir',
                    id: handler.id!,
                    workingDir: event.target.value,
                })} />
            <FormGroup id={getFieldId('auth_data')} label='Auth Data' inputType='text'
                value={handler.transportConfiguration.authData}
                onChange={(event) => dispatch({
                    type: 'editHandlerAuthData',
                    id: handler.id!,
                    authData: event.target.value,
                })} />
            <FormGroup id={getFieldId('auth_method')} label='Auth Method' inputType='text'
                value={handler.transportConfiguration.authMethod}
                onChange={(event) => dispatch({
                    type: 'editHandlerAuthMethod',
                    id: handler.id!,
                    authMethod: event.target.value,
                })} />
            <FormGroup id={getFieldId('zip_file_name')} label='Zip File Name' inputType='text'
                value={handler.transportConfiguration.zipFileName}
                required={true}
                pattern={ZIP_FILENAME_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerZipFileName',
                    id: handler.id!,
                    zipFileName: event.target.value,
                })} />
        </>
    );
}

export default ZipSftpTransferHandler;
