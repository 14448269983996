import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { Accordion } from "../../../common";
import { ChannelActionType } from "../../ChannelComponent/ChannelComponent.types";
import { WebNavigationComponentProps } from "./WebNavigationComponent.types";

const WebNavigationComponent: React.FC<WebNavigationComponentProps> = (props: WebNavigationComponentProps) => {
    const {onChange, data} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }
    return (
        <Accordion title={"Web Navigation"} isOpen={isOpen} onOpen={toggleIsOpen} onClose={toggleIsOpen}>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|groups'>Group codes</label>
                <textarea id='channel|groups'
                          name='groups'
                          value={data?.groups ?? ""}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              onChange({
                                  type: ChannelActionType.SET_WEB_NAV_GROUPS,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|categories'>Category codes</label>
                <textarea id='channel|categories'
                          name='categories'
                          value={data?.categories ?? ""}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              onChange({
                                  type: ChannelActionType.SET_WEB_NAV_CATEGORIES,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|subCategories'>SubCategory codes</label>
                <textarea id='channel|subCategories'
                          name='subCategories'
                          value={data?.subCategories ?? ""}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              onChange({
                                  type: ChannelActionType.SET_WEB_NAV_SUB_CATEGORIES,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
        </Accordion>
    );
};

export default WebNavigationComponent;