import React from 'react';
import { ZipS3TransferHandlerProps } from './ZipS3TransferHandler.types';
import { FormGroup } from '../../common';

import '../TransferHandler.css';
import {
    BUCKET_NAME_PATTERN,
    REGION_NAME_PATTERN,
    ROLE_ARN_PATTERN,
    S3_KEY_PREFIX_PATTERN,
    ZIP_FILENAME_PATTERN
} from '../constants';

const ZipS3TransferHandler: React.FC<ZipS3TransferHandlerProps> = ({ handler, dispatch, getFieldId }) => {
    return (
        <>
            <div className='mgmt-form-group'>
                <label className='mgmt-parameters-title'>Zip S3 Parameters</label>
            </div>
            <FormGroup id={getFieldId('bucket')} label='Bucket' inputType='text'
                value={handler.transportConfiguration.bucket}
                pattern={BUCKET_NAME_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerBucket',
                    id: handler.id!,
                    bucket: event.target.value,
                })} />
            <FormGroup id={getFieldId('region')} label='Region' inputType='text'
                value={handler.transportConfiguration.region}
                pattern={REGION_NAME_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerRegion',
                    id: handler.id!,
                    region: event.target.value,
                })} />
            <FormGroup id={getFieldId('key_prefix')} label='Key Prefix' inputType='text'
                value={handler.transportConfiguration.keyPrefix}
                pattern={S3_KEY_PREFIX_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerKeyPrefix',
                    id: handler.id!,
                    keyPrefix: event.target.value,
                })} />
            <FormGroup id={getFieldId('role_arn')} label='Role ARN' inputType='text'
                value={handler.transportConfiguration.roleArn}
                pattern={ROLE_ARN_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerRoleArn',
                    id: handler.id!,
                    roleArn: event.target.value
                })} />
            <FormGroup id={getFieldId('zip_file_name')} label='Zip File Name' inputType='text'
                value={handler.transportConfiguration.zipFileName}
                required={true}
                pattern={ZIP_FILENAME_PATTERN}
                onChange={(event) => dispatch({
                    type: 'editHandlerZipFileName',
                    id: handler.id!,
                    zipFileName: event.target.value,
                })} />
        </>
    );
}

export default ZipS3TransferHandler;
