import React from 'react';

import { CellMapper, TableProps } from './Table.types';

import './Table.css';

const TTable = <T,>({ headers, items, cellMappers, customStyle }: TableProps<T>) => {
    return (
        <div className="mgmt-divTable" {...(customStyle && {style: customStyle})}>
            <div className="mgmt-divTableHeaders">
                <div className="mgmt-divTableRow">
                    {headers.map((header: string, idx: number) => {
                        const headerKey = `mgmt-TableHeader-${idx}`;
                        return (
                            <div key={headerKey} className="mgmt-divTableHeader">{header}</div>
                        )
                    })}
                </div>
            </div>
            <div className='mgmt-divTableBody'>
                {items.map((data: T, rowIdx) => {
                    const rowKey = `mgmt-TableRow-${rowIdx}`;
                    return (
                        <div key={rowKey} className="mgmt-divTableRow">
                            {cellMappers.map((mapper: CellMapper<T>, cellIdx: number) => {
                                const cellKey = `mgmt-TableCell-${rowIdx}-${cellIdx}`;
                                return (
                                    <div key={cellKey} className="mgmt-divTableCell">
                                        {mapper(data, rowIdx)}
                                    </div>
                                )
                            })
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default TTable;