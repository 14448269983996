import React from 'react';
import { AzureTransferHandlerProps } from './AzureTransferHandler.types';
import { FormGroup } from '../../common';

import '../TransferHandler.css';

const AzureTransferHandler: React.FC<AzureTransferHandlerProps> = ({ handler, dispatch, getFieldId }) => {
    return (
        <>
            <div className='mgmt-form-group'>
                <label className='mgmt-parameters-title'>Azure Parameters</label>
            </div>
            <FormGroup id={getFieldId('account_name')} label='Account Name' inputType='text'
                value={handler.transportConfiguration.accountName}
                onChange={(event) => dispatch({
                    type: 'editHandlerAccountName',
                    id: handler.id!,
                    accountName: event.target.value,
                })} />
            <FormGroup id={getFieldId('account_key')} label='Account Key' inputType='text'
                value={handler.transportConfiguration.accountKey}
                onChange={(event) => dispatch({
                    type: 'editHandlerAccountKey',
                    id: handler.id!,
                    accountKey: event.target.value,
                })} />
            <FormGroup id={getFieldId('container_name')} label='Container Name' inputType='text'
                value={handler.transportConfiguration.containerName}
                onChange={(event) => dispatch({
                    type: 'editHandlerContainerName',
                    id: handler.id!,
                    containerName: event.target.value,
                })} />
            <FormGroup id={getFieldId('path')} label='Path' inputType='text'
                value={handler.transportConfiguration.path}
                onChange={(event) => dispatch({
                    type: 'editHandlerPath',
                    id: handler.id!,
                    path: event.target.value,
                })} />
        </>
    );
}

export default AzureTransferHandler;
