import React, { FC } from 'react';
import { TimedTaskItemView, TimedTaskProps } from './TimedTask.types';
import { Accordion, ConfirmationDialog, FormGroup, ModalDialog, ShowIf } from '../../common';
import { timedTaskReducer } from './TimedTask.reducer';
import { TimedLog, TimedTask } from '../../../services/timed.types';
import { copyTask } from './utils';
import Table from '../TimedTable/TimedTable';
import { AppContext } from '../../../pages/context';
import { v4 as uuid } from 'uuid';
import './TimedTask.css';


const TimedTask: FC<TimedTaskProps> = ({ view, onCancel, onSave, onRun }) => {

    const [task] = React.useState(copyTask(view.task));
    const [currentView, dispatch] = React.useReducer(timedTaskReducer, { ...view } as TimedTaskItemView);
    const [isCancelDialogOpen, setCancelDialogOpen] = React.useState(false);
    const [isSaveDialogOpen, setSaveDialogOpen] = React.useState(false);
    const [isRunDialogOpen, setRunDialogOpen] = React.useState(false);
    const [isRunConfirmationDialogOpen, setRunConfirmationDialogOpen] = React.useState(false);
    const [isErrorDialogOpen, setErrorDialogOpen] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([] as string[]);


    const currentTask: TimedTask = currentView.task;
    const { services: { apiService } } = React.useContext(AppContext);
    const isOpenToggle = () => dispatch({ type: 'setOpen', payload: !currentView.isOpen });

    const getId = (fieldName: string): string => {
        return `${currentTask.taskName}|${fieldName}`;
    };

    const runTaskChangesConfirmationMessage = `Are you sure you want to run timed task '${currentTask.taskName}'?`;
    const cancelTaskChangesConfirmationMessage = `Are you sure you want to rollback all changes in timed task '${currentTask.taskName}'?`;
    const saveTaskChangesConfirmationMessage = `Are you sure you want to save all changes in timed task '${currentTask.taskName}'?`;

    function handleSubmit() {
    }

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
        dispatch({ type: 'cancelTaskChanges', payload: task });
    };
    const isDataChanged = JSON.stringify(copyTask(currentTask)) != JSON.stringify(task);
    const getTaskTitle = () => {
        if (isDataChanged) {
            return `${currentTask.taskName} *`;
        }
        return currentTask.taskName;
    };

    const runTaskFailedErrorMessage = `Failed to run timed task '${currentTask.taskName}'`;
    const handleRun = () => {
        if (onRun) {
            onRun();
        }
        apiService.runTimedTasks({ taskName: currentTask.taskName })
            .then((response) => {
                if (response.status === 'ERROR') {
                    const message = response.message || updateConfigFailedErrorMessage;
                    setErrorMessages([message]);
                    setErrorDialogOpen(true);
                } else {
                    setRunConfirmationDialogOpen(true);
                }
            }).catch(() => {
                setErrorMessages([runTaskFailedErrorMessage]);
                setErrorDialogOpen(true);
            })
    };

    const updateConfigFailedErrorMessage = `Failed to update timed task '${currentTask.taskName}'`;
    const handleSave = () => {
        apiService.updateTimedTasks({ taskName: currentTask.taskName, schedule: currentTask.schedule,
            image: currentTask.image, logGroup: currentTask.logGroup, serializerName: currentTask.serializerName })
            .then((response) => {
                if (response.status === 'ERROR') {
                    const message = response.message || updateConfigFailedErrorMessage;
                    setErrorMessages([message]);
                    setErrorDialogOpen(true);
                } else if (response.status === 'VALIDATION_FAILED') {
                    const messages = response.validationMessages;
                    setErrorMessages(messages || ['Failed to update transfer configuration']);
                    setErrorDialogOpen(true);
                } else if (onSave) {
                    onSave();
                }
            }).catch(() => {
                setErrorMessages([updateConfigFailedErrorMessage]);
                setErrorDialogOpen(true);
            })
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Accordion title={getTaskTitle()}
                    isOpen={currentView.isOpen}
                    onOpen={isOpenToggle}
                    onClose={isOpenToggle}>
                    <div className='mgmt-timed-task-inputs'>
                        <FormGroup id={getId('name')}
                            label='Name'
                            inputType='text'
                            required={true}
                            value={`${currentTask.taskName}`}
                            disabled={true}
                        />
                        <FormGroup id={getId('type')}
                            label='Type'
                            inputType='text'
                            required={true}
                            value={`${currentTask.type}`}
                            disabled={true}
                        />
                        <FormGroup id={getId('image')}
                            label='Image'
                            inputType='text'
                            required={true}
                            value={`${currentTask.image}`}
                            onChange={(event) => dispatch({
                                type: 'editImage',
                                payload: event.target.value,
                            })
                            }
                        />
                        <FormGroup id={getId('schedule')}
                            label='Schedule'
                            inputType='text'
                            required={true}
                            value={`${currentTask.schedule}`}
                            onChange={(event) => dispatch({
                                type: 'editSchedule',
                                payload: event.target.value,
                            })
                            }
                        />
                        <FormGroup id={getId('logGroup')}
                            label='Log Group'
                            inputType='text'
                            value={`${currentTask.logGroup}`}
                            onChange={(event) => dispatch({
                                type: 'editLogGroup',
                                payload: event.target.value,
                            })
                            }
                        />
                        <FormGroup id={getId('serializerName')}
                            label='Serializer Name'
                            inputType='text'
                            value={`${currentTask.serializerName}`}
                            onChange={(event) => dispatch({
                                type: 'editSerializerName',
                                payload: event.target.value,
                            })
                            }
                        />
                    </div>
                    <div className='mgmt-timed-task-panel'>
                        <input className='mgmt-timed-task-panel-btn'
                            disabled={isDataChanged} title={isDataChanged ? 'Save or rollback changes to run task' : undefined}
                            type='button' value='Run'
                            onClick={() => setRunDialogOpen(true)}></input>
                        <input className='mgmt-timed-task-panel-btn'
                            disabled={!isDataChanged}
                            type='button' value='Save'
                            onClick={() => setSaveDialogOpen(true)}></input>
                        <input className='mgmt-timed-task-panel-btn' type='button' value='Cancel'
                            onClick={() => setCancelDialogOpen(true)}></input>
                    </div>
                    <div className='mgmt-timed-task-history'>
                        <h2>History</h2>
                        <Table headers={["Timestamp", "Error"]} logs={currentTask.history} cellMappers={[
                            (log: TimedLog) => { return mapTimestamp(log) },
                            (log: TimedLog) => { return mapError(log) }
                        ]} />
                    </div>
                </Accordion>
            </form>
            <ShowIf expression={isRunDialogOpen}>
                <ConfirmationDialog message={runTaskChangesConfirmationMessage}
                    onClose={() => { setRunDialogOpen(false) }}
                    onConfirm={() => { handleRun() }}
                    isOpen={isRunDialogOpen} />
            </ShowIf>
            <ShowIf expression={isCancelDialogOpen}>
                <ConfirmationDialog message={cancelTaskChangesConfirmationMessage}
                    onClose={() => { setCancelDialogOpen(false) }}
                    onConfirm={() => { handleCancel() }}
                    isOpen={isCancelDialogOpen} />
            </ShowIf>
            <ShowIf expression={isSaveDialogOpen}>
                <ConfirmationDialog message={saveTaskChangesConfirmationMessage}
                    onClose={() => { setSaveDialogOpen(false) }}
                    onConfirm={() => { handleSave() }}
                    isOpen={isSaveDialogOpen} />
            </ShowIf>
            <ShowIf expression={isErrorDialogOpen}>
                <ModalDialog isOpen={isErrorDialogOpen} onClose={() => {
                    setErrorDialogOpen(false);
                    setErrorMessages([]);
                }}>
                    <p style={{ margin: 0 }}>Operation failed</p>
                    <div>
                        {errorMessages
                            && errorMessages.length > 0
                            && errorMessages.map(message => (<p key={uuid()}>{message}</p>))}
                    </div>
                </ModalDialog>
            </ShowIf>
            <ModalDialog isOpen={isRunConfirmationDialogOpen} onClose={() => setRunConfirmationDialogOpen(false)}>
                <div>
                    <p>Action run {currentTask.taskName} task executed successfully</p>
                </div>
            </ModalDialog>
        </div >
    );
}

export default TimedTask;
function mapTimestamp(log: TimedLog): string | JSX.Element {
    const lastUpdate = log.lastUpdate;
    if (lastUpdate === undefined) {
        return "";
    }
    const date: Date = new Date();
    date.setTime(lastUpdate);
    return date.toISOString();
}

function mapError(log: TimedLog): string | JSX.Element {
    const error = log.exit;
    if (error === undefined) {
        return "";
    }
    return error.toString();
}

