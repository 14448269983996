import React from "react";
import './ActionsButton.css'
import { ActionButtonsTypes } from "./ActionButtons.types";

const ActionButtons: React.FC<ActionButtonsTypes> = ({ onMoveUp, onMoveDown, onRemove }) => {
    const moveUp = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onMoveUp();
    }
    const moveDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onMoveDown();
    }
    const remove = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onRemove();
    }
    return (
        <div className="fab-container">
            <button aria-label="Move Up" onClick={moveUp}>🔼</button>
            <button aria-label="Move Down" onClick={moveDown}>🔽</button>
            <button aria-label="Delete item" onClick={remove}>🗑️</button>
        </div>
    );
};

export default ActionButtons;