import { BaseApiResponse, RestResponse } from "./api.types";

export enum ExportType {
    XUCDM_PRODUCT = "XUCDM_PRODUCT",
    XUCDM_FILTER = "XUCDM_FILTER",
    XUCDM_GDM = "XUCDM_GDM",
    XUCDM_NAVIGATION = "XUCDM_NAVIGATION",
    XUCDM_PRODUCTGROUP = "XUCDM_PRODUCTGROUP",
    XUCDM_CLASSIFICATION = "XUCDM_CLASSIFICATION",
}

export interface WebNavigationFilter {
    groups?: string;
    categories?: string;
    subCategories?: string;
}

export interface ProductHierarchyFilter {
    businessGroups?: string;
    business?: string;
    majorArticleGroups?: string;
    articleGroups?: string;
    type?: string;
}

export interface ProductTypes {
    accessory?: boolean;
    coil?: boolean;
    normal?: boolean;
    software?: boolean;
    softwareApplication?: boolean;
    softwarePlatform?: boolean;
    sparePart?: boolean;
    transducer?: boolean;
}

export interface ObjectTypes {
    product?: boolean;
    consumable?: boolean;
    minimumproduct?: boolean;
    salesproduct?: boolean;
    range?: boolean;
    variation?: boolean;
}

export interface OrganizationCodeFilter {
    type?: string;
    codes?: string;
}

export interface ChannelFilter {
    catalogs?: string;
    contexts?: string;
    trees?: string;
    sopOffsetInDays?: number | null;
    refurbished?: string;
    webNavigation?: WebNavigationFilter;
    productHierarchy?: ProductHierarchyFilter;
    sompOffsetInDays?: number | null;
    eopOffsetInDays?: number | null;
    productTypes?: ProductTypes;
    objectTypes?: ObjectTypes;
    audience?: string;
    organizationCode?: OrganizationCodeFilter;
}

export interface ChannelOutput {
    xucdmLevel?: string;
    xucdmVersion?: string;
    transportFormat?: string;
    fileNamePattern?: string;
    fileNamePatternInZip?: string;
    batchSize?: number | null;
    headerLine?: string;
    footerLine?: string;
    fullFeed?: boolean;
    consolidatedFeed?: boolean;
    localeMapping?: string;
    includeInactive?: boolean;
}

export interface AccessLevel {
    publicLevel?: boolean;
    secure?: boolean;
    internal?: boolean;
}

export interface AssetDefinition {
    types?: string;
    accessLevels?: AccessLevel;
}

export interface ChannelAsset {
    nativeLanguageOnly?: boolean;
    include?: AssetDefinition;
    exclude?: AssetDefinition;
}

export interface ChannelTransformations {
    apply?: string[];
    // params is a (newline, comma, whitespace) separated list of key/value pairs.
    // Each key/value pair has a colon as separator.
    // E.g., "foo:bar, bar:baz"
    params?: string;
}

export interface Channel {
    channelId?: string;
    name?: string;
    description?: string;
    targetFolder?: string;
    exportType?: ExportType;
    timing?: string;
    enabled?: boolean;
    parallel?: boolean;
    filter?: ChannelFilter;
    output?: ChannelOutput;
    assets?: ChannelAsset;
    transformations?: ChannelTransformations;
}

export type ChannelTypes = ExportType | ChannelFilter | ChannelOutput | ChannelAsset | ChannelTransformations | string | boolean | number;

export interface ListChannelsResponse extends BaseApiResponse {
    channels?: Channel[];
    // error message
    message?: string;
}

export interface RunChannelConfig {
    selectedChannelName: string,
    feedPrefix?: string,
    contextsList?: string,
    productList?: string,
    modifiedSince?: string,
    fullFeed: boolean;
}

export interface RunChannelResponse extends BaseApiResponse {
    channelId: string;
    // error message
    message?: string;
}

//Output section helper types
export const XUCDM_LEVELS = ["0", "2", "5", "6"];
export const XUCDM_VERSIONS = ["1.1", "Base version"];
export const TRANSPORT_FORMATS = ["XmlPerProductContext", "XmlPerContext", "ZipPerContext", "PlainTextPerContext"];

export const INCLUDE_EXCLUDE_TYPES = ["", "include", "exclude"];

export interface UpdateChannelResponse extends RestResponse {
    validationMessages?: string[]
}

export interface CreateChannelResponse extends RestResponse {
    validationMessages?: string[]
}