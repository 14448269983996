import { ChannelTransformations } from "../../../services/channel.types";
import { ChannelTransformationsAction } from "../../../pages/Channels/Channels.types";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";

export function transformationsReducer(state: ChannelTransformations, action: ChannelTransformationsAction): ChannelTransformations {
    switch (action.type) {
        case ChannelActionType.SET_TRANSFORMATIONS_APPLY_ARRAY: {
            return {...state, apply: action.payload};
        }
        case ChannelActionType.SET_TRANSFORMATIONS_PARAMETERS: {
            return {...state, params: action.payload};
        }
        default:
            return state;
    }
}