import React from 'react';

import { AppContext } from '../context';
import { UserRole } from '../../components/Table/Table.types';
import { EditUserResult, User } from '../../services/user.types';
import EditDialog from '../../components/common/EditDialog';
import { ModalDialog } from '../../components';
import TTable from '../../components/Table';
import Dropdown from '../../components/common/Dropdown';

import './Users.css';

const columns = [
    "Email",
    "Role",
    "Action"
]

const UsersPage = () => {

    const [users, setUsers] = React.useState([] as User[]);
    const [isLoading, setLoading] = React.useState(false);
    const [isUserEditOpen, setUserEditOpen] = React.useState(false);
    const [isUserDeleteOpen, setUserDeleteOpen] = React.useState(false);
    const [isUserCreateOpen, setUserCreateOpen] = React.useState(false);
    const [editedUser, setEditedUser] = React.useState({} as User);
    const [deletedUser, setDeletedUser] = React.useState({} as User);
    const [newUser, setNewUser] = React.useState({role: UserRole.Guest} as User);
    const [selectedRole, setSelectedRole] = React.useState({} as UserRole);
    const [isEditModalOpen, setEditedModalOpen] = React.useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [isNewUserModalOpen, setNewUserModalOpen] = React.useState(false);
    const [editUserResult, setEditUserResult] = React.useState({} as EditUserResult);
    const [deleteUserResult, setDeletedUserResult] = React.useState({} as EditUserResult);
    const [newUserResult, setNewUserResult] = React.useState({} as EditUserResult);
    const [loadUsers, setLoadUsers] = React.useState(0);

    const {
        services: {
            apiService,
        }
    } = React.useContext(AppContext);

    React.useEffect(() => {
        const getUsers = async () => {
            const promise: User[] = await apiService.listUsers();
            setUsers(promise);
        }
        getUsers();
    }, [loadUsers, apiService, setUsers]);

    function updateUser(user: User) {
        setUserEditOpen(true);
        setEditedUser(user);
        setSelectedRole(UserRole[user.role as UserRole])
    }

    function deleteUser(user: User) {
        setUserDeleteOpen(true)
        setDeletedUser(user);
    }

    function createUser(e: React.MouseEvent<HTMLInputElement>) {
        e.preventDefault();
        setUserCreateOpen(true)
        setNewUser({role: UserRole.Guest} as User)
    }

    const updateUserInDB = () => {
        try {
            setLoading(true);
            apiService.updateUser({email: editedUser.email, role: selectedRole}).then(() => {
                setLoading(false);
                setEditedModalOpen(true);
                setEditUserResult({
                    'status': 'OK',
                    'email': editedUser.email,
                    'role': selectedRole,
                } as EditUserResult);
            })
                .catch(error => {
                    setEditUserResult({
                        'status': 'ERROR',
                        'message': error.message,
                    } as EditUserResult);
                    setLoading(false);
                })

        } catch (error) {
           console.error(error);
           setEditUserResult({
                'status': 'ERROR',
                'message': 'Cannot update user, see log for details',
            } as EditUserResult);
            setLoading(false);
        }
        setLoading(false);
    };

    const createUserInDB = () => {
        try {
            setLoading(false);
            apiService.createUser({email: newUser.email, role: newUser.role}).then(response => {
                setLoading(false);
                if (response.status === "OK") {
                    setNewUserResult({
                        'status': 'OK',
                        'email': newUser.email,
                        'role': newUser.role,
                    } as EditUserResult);
                } else {
                    setNewUserResult({
                        'status': 'ERROR',
                        'message': response.message,
                    } as EditUserResult);
                }
                setNewUserModalOpen(true);
            })
                .catch(error => {
                    setNewUserResult({
                        'status': 'ERROR',
                        'message': error.message,
                    } as EditUserResult);
                    setLoading(false);
                    setNewUserModalOpen(true);
                })

        } catch (error) {
            console.error(error);
            setNewUserResult({
                'status': 'ERROR',
                'message': 'Cannot create user, see log for details',
            } as EditUserResult);
            setLoading(false);
        }
        setLoading(false);
    };

    const deleteUserInDB = () => {
        try {
            setLoading(true);
            apiService.deleteUser({email: deletedUser.email, role: deletedUser.role}).then(() => {
                setLoading(false);
                setDeleteModalOpen(true);
                setDeletedUserResult({
                    'status': 'OK',
                    'email': deletedUser.email,
                } as EditUserResult);
            })
                .catch(error => {
                    setDeletedUserResult({
                        'status': 'ERROR',
                        'message': error.message,
                    } as EditUserResult);
                    setLoading(false);
                })

        } catch (error) {
            console.error(error);
            setDeletedUserResult({
                'status': 'ERROR',
                'message': 'Cannot delete user, see log for details',
            } as EditUserResult);
            setLoading(false);
        }
        setLoading(false);
    };

    const onSelectEditDialog = (selected: string) => {
        setSelectedRole(UserRole[selected as UserRole])
    }

    const onSelectNewDialog = (selected: string) => {
        setNewUser({email: newUser.email, role: UserRole[selected as UserRole]} as User)
    }

    const onChangeNewDialog = (email: string) => {
        setNewUser({email: email, role: newUser.role} as User)
    }

    return (
        <div className={isLoading ? 'disabled' : ''}>
            <div>
                <h2>Users</h2>
                <div className='mgmt-table-group'>
                    <div className='mgmt-button'>
                        <input type='button' onClick={createUser} value={"Create User"}/>
                    </div>
                    <TTable headers={columns}
                            items={users}
                            cellMappers={[
                                (user: User) => {
                                    return user.email
                                },
                                (user: User) => {
                                    return user.role
                                },
                                (user: User) => {
                                    return (
                                        <div className="mgmt-user-actions">
                                            <a href="#users" onClick={() => updateUser(user)}>Edit</a>
                                            &nbsp;
                                            <a href="#users" onClick={() => deleteUser(user)}>Delete</a>
                                        </div>)
                                }]}/>
                </div>
                <EditDialog isOpen={isUserEditOpen} submitText="Update" cancelText="Close"
                            onSubmit={() => {
                                updateUserInDB();
                                setUserEditOpen(false);
                            }}
                            onCancel={() => setUserEditOpen(false)}
                >
                    <div className="mgmt-label">
                        <label>Email: <input type="text" disabled value={editedUser.email}/></label>
                        <label>Role: <Dropdown items={Object.keys(UserRole).map(role => {
                            return {id: role, displayValue: role}
                        })} selectedItem={selectedRole} onSelect={onSelectEditDialog}></Dropdown></label>
                    </div>
                </EditDialog>
                <EditDialog isOpen={isUserDeleteOpen} submitText="Delete" cancelText="Close"
                            onSubmit={() => {
                                deleteUserInDB();
                                setUserDeleteOpen(false);
                            }}
                            onCancel={() => setUserDeleteOpen(false)}
                >
                    <div className="mgmt-label">
                        <label className={"label-centered"}>Are you sure want to delete user?</label>
                        <label>Email: <input type="text" disabled value={deletedUser.email}/></label>
                        <label>Role: <Dropdown disabled items={Object.keys(UserRole).map(role => {
                            return {id: role, displayValue: role}
                        })} selectedItem={selectedRole} onSelect={onSelectEditDialog}></Dropdown></label>
                    </div>
                </EditDialog>
                <EditDialog isOpen={isUserCreateOpen} submitText="Create" cancelText="Close"
                            onSubmit={() => {
                                createUserInDB();
                                setUserCreateOpen(false);
                            }}
                            onCancel={() => setUserCreateOpen(false)}
                >
                    <div className="mgmt-label">
                        <label>Email: <input type="text" onChange={(e) => {
                            onChangeNewDialog(e.target.value)
                        }}/></label>
                        <label>Role: <Dropdown items={Object.keys(UserRole).map(role => {
                            return {id: role, displayValue: role}
                        })} selectedItem={newUser.role} onSelect={onSelectNewDialog}></Dropdown></label>
                    </div>
                </EditDialog>
                <ModalDialog isOpen={isEditModalOpen} onClose={() => {
                    setEditedModalOpen(false);
                    setEditedUser({} as User);
                    setLoadUsers((new Date()).getTime())
                }}>
                    {'OK' === editUserResult.status &&
                        <div>
                          <label>User successfully updated.</label>
                          <label>Email: {editUserResult.email}</label>
                          <label>Role: {editUserResult.role}</label>
                        </div>
                    }
                    {'ERROR' === editUserResult.status &&
                        <div>
                          <label>Failed to update user.</label>
                          <label>Error details: {editUserResult.message}</label>
                        </div>
                    }
                </ModalDialog>
                <ModalDialog isOpen={isDeleteModalOpen} onClose={() => {
                    setDeleteModalOpen(false);
                    setEditedUser({} as User);
                    setLoadUsers((new Date()).getTime())
                }}>
                    {'OK' === deleteUserResult.status &&
                        <div>
                          <label>User successfully deleted.</label>
                          <label>Email: {deleteUserResult.email}</label>
                        </div>
                    }
                    {'ERROR' === deleteUserResult.status &&
                        <div>
                          <label>Failed to delete user.</label>
                          <label>Error details: {deleteUserResult.message}</label>
                        </div>
                    }
                </ModalDialog>
                <ModalDialog isOpen={isNewUserModalOpen} onClose={() => {
                    setNewUserModalOpen(false);
                    setNewUser({} as User);
                    setLoadUsers((new Date()).getTime())
                }}>
                    {'OK' === newUserResult.status &&
                        <div>
                          <label>User successfully created.</label>
                          <label>Email: {newUserResult.email}</label>
                          <label>Role: {newUserResult.role}</label>
                        </div>
                    }
                    {'ERROR' === newUserResult.status &&
                        <div>
                          <label>Failed to create user.</label>
                        </div>
                    }
                </ModalDialog>
            </div>
        </div>)

};

export default UsersPage;
