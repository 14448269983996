export const basePath = '/gui';
export const managementBasePath = `${basePath}/management`;
export const monitoringBasePath = `${basePath}/monitoring`;

export const PATHS = {
    HOME: basePath,
    PAGE_NOT_FOUND: `${basePath}/page-not-found/`,
    LOGOFF: `${basePath}/logoff/`,
    MANAGEMENT: {
        BASE: managementBasePath,
        TIMED: `${managementBasePath}/timed/`,
        TRANSFER: `${managementBasePath}/transfer/`,
        USERS: `${managementBasePath}/users/`,
        SERIALIZER_CONTEXTS: `${managementBasePath}/serializer-contexts/`,
        REPROCESS_INGEST_PRODUCTS_BATCH: `${managementBasePath}/reprocess-ingest-products-batch/`,
        RUN_CHANNEL: `${managementBasePath}/run-channel/`,
        CHANNELS: `${managementBasePath}/channels/`,
        TSUNAMI: `${managementBasePath}/tsunami/`,
        AUTOSCALING_OVERRIDE: `${managementBasePath}/autoscaling-override/`
    },
    MONITORING: {
        BASE: monitoringBasePath,
        LOGS: `${monitoringBasePath}/logs/`
    }
};
