export enum Intent {
    SUCCESS = 0,
    WARNING = 1,
    ERROR = 2
}

export interface SnackbarProps {
    message: string;
    isShown: boolean;
    duration?: number; //milliseconds
    intent?: Intent;
    onClose?: () => void;
}

export type SnackbarState = {
    showSnackbar: boolean;
    message: string | undefined;
    intent: Intent | undefined;
};
