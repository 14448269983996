import { TimedLog, TimedTask } from "../../../services/timed.types";


export const copyTask = (task: TimedTask): TimedTask => {
    let logs: TimedLog[];
    if (task.history == undefined) {
        logs = []
    } else {
        logs = task.history.map(log => copyTimedLog(log))
    }
    return {
        taskName: task.taskName,
        image: task.image,
        type: task.type,
        schedule: task.schedule,
        logGroup: task.logGroup,
        serializerName: task.serializerName,
        history: logs
    } as TimedTask;
}

export const copyTimedLog = (timedLog: TimedLog): TimedLog => {
    return {
        lastUpdate: timedLog.lastUpdate,
        exit: timedLog.exit
    }
}
