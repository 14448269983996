import React from "react";

import { MenuProps } from './Menu.types';
import { ConfirmationDialog, ShowIf, AccordionMenu, AccordionMenuItem } from "../common";
import { useNavigate } from "react-router-dom";
import { PATHS } from '../../config/paths';

import './Menu.css';

const Menu: React.FC<MenuProps> = ({
    isMenuOpen,
    setMenuOpen,
    isManagementOpen,
    setIsManagementOpen,
    isMonitoringOpen,
    setMonitoringOpen,
    isLogoffConfirmationDialogOpen,
    setIsLogoffConfirmationDialogOpen
}) => {
    const navigate = useNavigate();

    return (
        <div className={`mgmt-home-menu`}>
            <AccordionMenu
                isOpen={isMenuOpen}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}>
                <AccordionMenuItem title="Management"
                    isOpen={isManagementOpen}
                    onOpen={() => setIsManagementOpen(true)}
                    onClose={() => setIsManagementOpen(false)}>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MANAGEMENT.TIMED)}>Timed</label>
                    </div>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MANAGEMENT.TRANSFER)}>Transfer</label>
                    </div>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MANAGEMENT.TSUNAMI)}>Tsunami</label>
                    </div>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MANAGEMENT.AUTOSCALING_OVERRIDE)}>Autoscaling Override</label>
                    </div>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MANAGEMENT.USERS)}>Users</label>
                    </div>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MANAGEMENT.SERIALIZER_CONTEXTS)}>Serializer Context</label>
                    </div>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MANAGEMENT.REPROCESS_INGEST_PRODUCTS_BATCH)}>Ingest Products Batch</label>
                    </div>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MANAGEMENT.RUN_CHANNEL)}>Run Channel</label>
                    </div>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MANAGEMENT.CHANNELS)}>Channels</label>
                    </div>
                </AccordionMenuItem>
                <AccordionMenuItem title="Monitoring"
                    isOpen={isMonitoringOpen}
                    onOpen={() => setMonitoringOpen(true)}
                    onClose={() => setMonitoringOpen(false)}>
                    <div>
                        <label className="mgmt-home-menu-label" onClick={() => navigate(PATHS.MONITORING.LOGS)}>Logs</label>
                    </div>
                </AccordionMenuItem>
                <div className="mgmt-home-logout">
                    <button onClick={() => setIsLogoffConfirmationDialogOpen(true)}>Log off</button>
                </div>
                <ShowIf expression={isLogoffConfirmationDialogOpen}>
                    <ConfirmationDialog
                        message="Are you sure you want to log off?"
                        onClose={() => setIsLogoffConfirmationDialogOpen(false)}
                        onConfirm={() => navigate(PATHS.LOGOFF)}
                        isOpen={isLogoffConfirmationDialogOpen} />
                </ShowIf>
            </AccordionMenu>
        </div>
    );
}

export default Menu;
