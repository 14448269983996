import React from 'react';

import { BaseModalDialog } from "../BaseModalDialog";
import { ModalDialogProps } from './ModalDialog.types';

const ModalDialog: React.FC<ModalDialogProps> = ({isOpen, onClose, children}: ModalDialogProps) => {
    const close = () => {
        if (onClose) {
            onClose();
        }
    }
    const onCancel = () => {
        close();
    }

    return (
        <BaseModalDialog isOpen={isOpen} onCancel={onCancel}>
            {children}
            <input type='button' value='Okay' onClick={close}></input>
        </BaseModalDialog>
    );
}

export default ModalDialog;
