import { Accordion, Dropdown, FormGroup, ShowIf } from "../../common";
import React, { ChangeEvent, useState } from "react";
import { TRANSPORT_FORMATS, XUCDM_LEVELS, XUCDM_VERSIONS } from "../../../services/channel.types";
import { OutputComponentProps } from "./OutputComponent.types";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";

import '../../../common.css';

const OutputComponent: React.FC<OutputComponentProps> = (props: OutputComponentProps) => {
    const {data, channelId, isNewChannel} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    const getId = (fieldName: string): string => {
        return `${channelId}|${fieldName}`;
    };

    return (
        <Accordion title={"Output"} isOpen={isOpen} onOpen={toggleIsOpen} onClose={toggleIsOpen}>
            <ShowIf expression={props.showProductRelatedItems}>
                <div className='mgmt-form-group'>
                    <label className={isNewChannel ? 'mgmt-required-field' : ''}
                           htmlFor='dropdown_select'>xUCDM level</label>
                    <Dropdown
                        items={XUCDM_LEVELS.map(value => {
                            return {id: value, displayValue: value}
                        })}
                        selectedItem={data.xucdmLevel!} onSelect={(selected) => {
                        props.onChange({
                            type: ChannelActionType.SET_OUTPUT_XUCDM_LEVEL,
                            payload: selected
                        });
                    }}/>
                </div>
                <div className='mgmt-form-group'>
                    <label htmlFor='dropdown_select'>xUCDM version</label>
                    <Dropdown
                        items={XUCDM_VERSIONS.map(value => {
                            return {id: value, displayValue: value}
                        })}
                        selectedItem={data.xucdmVersion!}
                        onSelect={(selected) => {
                            props.onChange({
                                type: ChannelActionType.SET_OUTPUT_XUCDM_VERSION,
                                payload: selected
                            });
                        }}/>
                </div>
            </ShowIf>
            <div className='mgmt-form-group'>
                <label className={isNewChannel ? 'mgmt-required-field' : ''}
                       htmlFor='dropdown_select'>Transport</label>
                <Dropdown
                    items={TRANSPORT_FORMATS.map(value => {
                        return {id: value, displayValue: value}
                    })}
                    selectedItem={data.transportFormat!}
                    onSelect={(selected) => {
                        props.onChange({
                            type: ChannelActionType.SET_OUTPUT_TRANSPORT_FORMAT,
                            payload: selected
                        });
                    }}/>
            </div>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|headerLine'>File header</label>
                <textarea id='channel|headerLine'
                          name='headerLine'
                          value={data.headerLine}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              props.onChange({
                                  type: ChannelActionType.SET_OUTPUT_HEADER_LINE,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|footerLine'>File footer</label>
                <textarea id='channel|footerLine'
                          name='footerLine'
                          value={data.footerLine}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              props.onChange({
                                  type: ChannelActionType.SET_OUTPUT_FOOTER_LINE,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <FormGroup id={getId('fileNamePattern')}
                       label="Filename Pattern"
                       inputType="text"
                       value={data.fileNamePattern}
                       requiredField={isNewChannel}
                       onChange={(event: ChangeEvent<HTMLInputElement>) => {
                           props.onChange({
                               type: ChannelActionType.SET_OUTPUT_FILENAME_PATTERN,
                               payload: event.target.value
                           });
                       }}
            />
            <FormGroup id={getId('batchSize')}
                       label="Batch Size"
                       inputType="number"
                       value={data.batchSize ?? ""}
                       min={0}
                       onChange={(event: ChangeEvent<HTMLInputElement>) => {
                           const value = event.target.value;
                           props.onChange({
                               type: ChannelActionType.SET_OUTPUT_BATCH_SIZE,
                               payload: value === "" ? null : Number(value)
                           });
                       }}
            />
            <FormGroup id={getId('fullFeed')}
                       label="Full Feed"
                       inputType="checkbox"
                       value={`${data.fullFeed}`}
                       onChange={() => {
                           props.onChange({
                               type: ChannelActionType.TOGGLE_OUTPUT_FULL_FEED
                           });
                       }}
            />
            <ShowIf expression={!props.showProductGroupRelatedItems}>
                <FormGroup id={getId('includeInactive')}
                           label="Include Inactive Products"
                           inputType="checkbox"
                           value={`${data.includeInactive}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_OUTPUT_INCLUDE_INACTIVE
                               });
                           }}
                />
            </ShowIf>
            <FormGroup id={getId('consolidatedFeed')}
                       label="Merge Exported Content Into One File"
                       inputType="checkbox"
                       value={`${data.consolidatedFeed}`}
                       onChange={() => {
                           props.onChange({
                               type: ChannelActionType.TOGGLE_OUTPUT_CONSOLIDATED_FEED
                           });
                       }}
            />
            <div className='mgmt-form-group'>
                <label htmlFor='channel|localeMapping'>Locale Mapping (source=target, one per line or comma separated)</label>
                <textarea id='channel|localeMapping'
                          name='localeMapping'
                          value={data.localeMapping}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              props.onChange({
                                  type: ChannelActionType.SET_OUTPUT_LOCALE_MAPPING,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
        </Accordion>
    );
}

export default OutputComponent;