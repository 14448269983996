import React from 'react';

export enum ManagementPageType {
    Timed,
    OTS,
    Tsunami,
    AutoscalingOverride,
    Users,
    SerializerContexts,
    IngestProductsBatch,
    RunChannel,
    Channels,
}

export interface ManagementProps {
    children: React.ReactNode[] | React.ReactNode;
    selectedItemType: ManagementPageType;
}

export interface MenuItemProps {
    label: string;
    path: string;
    selectedItemType: ManagementPageType;
    itemType: ManagementPageType;
}
