export enum ReprocessType {
    Batch = 1,
    Timestamp,
}

export interface ReprocessProductPageProps {
    batchId?: string;
    productId?: string;
    context?: string;
    timestamp?: string;
    type?: ReprocessType;
}
