import React from 'react';

import { EditDialogProps } from './EditDialog.types';

import './EditDialog.css';

const EditDialog: React.FC<EditDialogProps> = ({ isOpen, submitText, cancelText, onSubmit, onCancel, children }: EditDialogProps) => {
    const [isModalOpen, setModalOpen] = React.useState(isOpen);
    const modalRef = React.useRef<HTMLDialogElement | null>(null);

    React.useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    React.useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            if (isModalOpen) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }
    }, [isModalOpen]);

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit();
        }
        setModalOpen(false);
    };

    const handleCloseModal = () => {
        if (onCancel) {
            onCancel();
        }
        setModalOpen(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            handleCloseModal();
        }
    };

    return (
        <dialog ref={modalRef} className="mgmt-dialog-edit" onKeyDown={handleKeyDown}>
            {children}
            <input type='button' value={submitText} onClick={handleSubmit}></input>
            <input type='button' value={cancelText} onClick={handleCloseModal}></input>
        </dialog>
    );
}

export default EditDialog;