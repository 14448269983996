import React from 'react';

import { CreateItemDialogProps } from "./CreateItemDialog.types";
import { BaseModalDialog } from "../BaseModalDialog";

import './CreateItemDialog.css';

const CreateItemDialog: React.FC<CreateItemDialogProps> = ({
                                                               isOpen,
                                                               title,
                                                               label,
                                                               onClose,
                                                               customValidate,
                                                               create,
                                                           }) => {
    const inputRef = React.useRef({} as HTMLInputElement);
    const [value, setValue] = React.useState('');

    React.useEffect(() => {
        const element = inputRef.current;
        if (customValidate) {
            customValidate(element);
        }
        const handleValidate = () => {
            if (customValidate) {
                customValidate(element);
            }
        }
        element.addEventListener('input', handleValidate);
        return () => {
            element.removeEventListener('input', handleValidate);
        }
    }, [customValidate, value]);

    const close = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleCancel = () => {
        close();
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity()) {
            if (create) {
                create(value);
            }
            close();
        } else {
            form.reportValidity();
        }
    };

    return (
        <BaseModalDialog isOpen={isOpen} onCancel={handleCancel}>
            <form onSubmit={handleSubmit} noValidate={true}>
                <div>
                    <div>
                        <p className='mgmt-dialog-title'>{title}</p>
                    </div>
                    <div>
                        <div className='mgmt-create-item-dialog-content'>
                            <label htmlFor='mgmt-create-item-input'>{label}</label>
                            <input id='mgmt-create-item-input'
                                   ref={inputRef}
                                   type='text'
                                   value={value}
                                   required={true}
                                   onChange={onChange}></input>
                        </div>
                        <div className='mgmt-confirmation-dialog-btn-panel'>
                            <input type='button' value='Cancel' onClick={handleCancel}></input>
                            <input type='submit' value='Add'></input>
                        </div>
                    </div>
                </div>
            </form>
        </BaseModalDialog>
    );
};

export default CreateItemDialog;
