export function sortAlphanumericIgnoreCase(str1?: string, str2?: string) {
    const name1 = (str1 || "").toLowerCase();
    const name2 = (str2 || "").toLowerCase();

    if (name1 < name2) {
        return -1;
    }
    if (name1 > name2) {
        return 1;
    }
    return 0;
}

export function runFunctions<T extends undefined | (() => void)>(...funArgs: T[]) {
    funArgs.forEach((funArg) => {
        if (funArg) {
            funArg();
        }
    })
}
