import React from 'react';
import { SerializerSelectProps } from './SerializerSelect.props';
import { NamedCheckBox } from '../../../common';

import './SerializerSelect.css';

const SerializerSelect: React.FC<SerializerSelectProps> = ({ serializerSelectList, setSerializerSelectList }) => {
    const handleCheckboxChange = (index: number) => {
        const newList = [...serializerSelectList];
        newList[index].selected = !newList[index].selected;
        setSerializerSelectList(newList);
    };

    return (
        <div className="mgmt-tsunami-configuration-serializer-select">
            <label className="mgmt-tsunami-configuration-serializer-select-header">
                Select serializers that need to process updates generated by the tsunami
            </label>
            <div className="mgmt-tsunami-configuration-select-checkbox-group">
                {serializerSelectList.map((serializer, index) => (
                    <NamedCheckBox
                        key={serializer.name}
                        label={serializer.name}
                        checked={serializer.selected}
                        onChange={() => handleCheckboxChange(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default SerializerSelect;
