import React from 'react';
import { CreateSerializerConfigFormProps } from "./CreateSerializerConfigForm.props";
import { CreateItemDialog } from "../../common/CreateItemDialog";

const CreateSerializerConfigDialog: React.FC<CreateSerializerConfigFormProps> = ({
                                                                                     isOpen,
                                                                                     existingConfigs,
                                                                                     onCreate,
                                                                                     onClose
                                                                                 }) => {
    const handleValidate = (element: HTMLInputElement) => {
        const value = element.value;
        // cleanup validation result from previous execution
        element.setCustomValidity('');
        // validate
        existingConfigs.forEach(configName => {
            if (value === configName) {
                element.setCustomValidity(`Serializer configuration with name '${configName}' already exists`);
            }
        });
    };

    return (
        <CreateItemDialog title='Create new Serializer Configuration'
                          label='Name'
                          customValidate={handleValidate}
                          onClose={onClose}
                          create={(itemValue) => {
                              if (onCreate) {
                                  onCreate(itemValue);
                              }
                          }}
                          isOpen={isOpen}/>
    );
};

export default CreateSerializerConfigDialog;
