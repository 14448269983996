import React, { ReactNode } from 'react';
import { ApiService } from '../services';
import { Footer } from '../components/common/Footer'

interface ServicesProps {
    apiService: ApiService;
}

interface AppContextProps {
    services: ServicesProps;
    jwtSupplier?: string
}

const AppContext = React.createContext({} as AppContextProps);
const { Provider } = AppContext;

type Props = {
    children: ReactNode | undefined;
    jwtSupplier: () => Promise<string>;
}

const AppProvider = ({ children, jwtSupplier }: Props) => {
    const services = {
        apiService: new ApiService(jwtSupplier),
    }
    return (
        <Provider value={{ services: services }}>
            {children}
            <Footer />
        </Provider>
    );
};

export { AppContext, AppProvider };
