import React from 'react';
import { LoadingSpinner, ModalDialog, ShowIf } from '../../components/common';
import { AppContext } from '../context';

import './SerializerContexts.css';
import { sortAlphanumericIgnoreCase } from "../../utils";
import { SerializerConfiguration } from "../../services/serializer-contexts.types";
import { SerializerConfigView } from "../../components/SerializerContexts";
import { CreateSerializerConfigForm } from "../../components/SerializerContexts/CreateSerializerConfigDialog";
import { v4 as uuid } from "uuid";

const SerializerContextsPage = () => {
    const [loading, setLoading] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = React.useState('');
    const [lastLoadTime, setLastLoadTime] = React.useState((new Date()).getTime());
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showErrorDialog, setShowErrorDialog] = React.useState(false);

    const [createSerializerConfigDialogOpen, setCreateSerializerConfigDialogOpen] = React.useState(false);

    const [serializerConfigs, setSerializerConfigs] = React.useState([] as SerializerConfiguration[]);

    const {services: {apiService}} = React.useContext(AppContext);

    React.useEffect(() => {
        setLoadingMessage('Loading...');
        setLoading(true);
        apiService.listSerializerConfigs()
            .then(response => {
                if (response.status === 'OK') {
                    const configs = response.serializerConfigurationModels || [];
                    configs.sort((c1, c2) => sortAlphanumericIgnoreCase(c1.name, c2.name));
                    setSerializerConfigs(configs);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setErrorMessage(`Failed to load Serializer Configurations. ${response.message}`);
                    setShowErrorDialog(true);
                }
            }).catch(err => {
            setLoading(false);
            setErrorMessage(err.message);
            setShowErrorDialog(true);
        })

    }, [apiService, setLoading, setSerializerConfigs, lastLoadTime]);

    const reloadConfigs = () => {
        setLastLoadTime((new Date()).getTime());
    };

    const serializerConfigListView = [] as React.ReactNode[];
    serializerConfigs.forEach((config: SerializerConfiguration) => {
        const configId = `${config.name}` as string;
        serializerConfigListView.push((
            <SerializerConfigView key={configId} config={config} onSave={reloadConfigs} onDelete={reloadConfigs}/>
        ));
    });

    return (
        <div>
            <h2>Serializer contexts</h2>
            <ShowIf expression={loading}>
                <LoadingSpinner message={loadingMessage}/>
            </ShowIf>
            <ShowIf expression={!loading}>
                <div className='mgmt-serializer-contexts'>
                    <div className='mgmt-serializer-contexts-top-btn-group'>
                        <input type='button' onClick={() => {
                            setCreateSerializerConfigDialogOpen(true);
                        }} value='Create Serializer Context'/>
                    </div>
                    {serializerConfigListView}
                </div>
            </ShowIf>
            <ShowIf expression={createSerializerConfigDialogOpen}>
                <CreateSerializerConfigForm isOpen={createSerializerConfigDialogOpen}
                                            existingConfigs={serializerConfigs.map(config => config.name)}
                                            onCreate={(configName) => {
                                                const configs = [...serializerConfigs];
                                                configs.push({
                                                    name: configName,
                                                    contexts: [],
                                                    catalogs: [],
                                                    customParams: [],
                                                    isNew: true,
                                                } as SerializerConfiguration);
                                                setSerializerConfigs(configs);
                                                setCreateSerializerConfigDialogOpen(false);
                                            }}
                                            onClose={() => setCreateSerializerConfigDialogOpen(false)}/>
            </ShowIf>
            <ShowIf expression={showErrorDialog}>
                <ModalDialog isOpen={showErrorDialog} onClose={() => {
                    setShowErrorDialog(false);
                    setErrorMessage('');
                }}>
                    <p style={{margin: 0}}>Operation failed</p>
                    <div>
                        <p key={uuid()}>{errorMessage ?? 'Unexpected error happened. Please contact technical support.'}</p>
                    </div>
                </ModalDialog>
            </ShowIf>
        </div>
    )
}

export default SerializerContextsPage;
