import * as React from 'react';
import { useState } from 'react';
import { Accordion, FormGroup, ShowIf } from "../../../common";
import { ChannelActionType } from "../../ChannelComponent/ChannelComponent.types";
import { ObjectTypesComponentProps } from "./ObjectTypesComponent.types";

const ObjectTypesComponent: React.FC<ObjectTypesComponentProps> = (props: ObjectTypesComponentProps) => {
    const { getId, data } = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }
    return (
        <Accordion title={"Object Types"} isOpen={isOpen} onOpen={toggleIsOpen} onClose={toggleIsOpen}>
            <ShowIf expression={!props.showProductGroupRelatedItems}>
                <FormGroup id={getId('product')}
                           label="Product"
                           inputType="checkbox"
                           value={`${data?.product}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_OBJECT_TYPES_PRODUCT
                               });
                           }}/>
                <FormGroup id={getId('consumable')}
                           label="Consumable"
                           inputType="checkbox"
                           value={`${data?.consumable}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_OBJECT_TYPES_CONSUMABLE
                               });
                           }}/>
                <FormGroup id={getId('minimum_product')}
                           label="Minimum product"
                           inputType="checkbox"
                           value={`${data?.minimumproduct}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_OBJECT_TYPES_MIN_PRODUCT
                               });
                           }}/>
                <FormGroup id={getId('sales_product')}
                           label="Sales product"
                           inputType="checkbox"
                           value={`${data?.salesproduct}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_OBJECT_TYPES_SALES_PRODUCT
                               });
                           }}/>
            </ShowIf>
            <ShowIf expression={props.showProductGroupRelatedItems}>
                <FormGroup id={getId('range')}
                           label="Range"
                           inputType="checkbox"
                           value={`${data?.range}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_OBJECT_TYPES_RANGE
                               });
                           }}/>
                <FormGroup id={getId('variation')}
                           label="Variation"
                           inputType="checkbox"
                           value={`${data?.variation}`}
                           onChange={() => {
                               props.onChange({
                                   type: ChannelActionType.TOGGLE_OBJECT_TYPES_VARIATION
                               });
                           }}/>
            </ShowIf>
        </Accordion>
    );
};

export default ObjectTypesComponent;