import { Configuration, PublicClientApplication } from "@azure/msal-browser";

interface AuthSettings {
  clientId: string;
  authority: string;
}

const AUTH_SETTINGS_URI = "/gui/api/auth/settings";
const AUTH_REDIRECT_URI = "/gui/spa/signin";

export const createMsalClient = async () => {
  const authProps: AuthSettings = await fetch(AUTH_SETTINGS_URI)
    .then((response) => response.json())
    .catch((error) => console.error(error));

  const msalConfig: Configuration = {
    auth: {
      clientId: authProps.clientId,
      authority: authProps.authority,
      redirectUri: AUTH_REDIRECT_URI,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
    },
  };
  return new PublicClientApplication(msalConfig);
};
