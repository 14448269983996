import React from 'react';
import { PaginationBarProps } from './PaginationBar.props';

import './PaginationBar.css';

const PaginationBar: React.FC<PaginationBarProps> = ({ currentPage, pageCount, paginate }) => {
    const maxButtons = 10;
    const halfMaxButtons = Math.floor(maxButtons / 2);

    const getPageNumbers = () => {
        let startPage = 1;
        let endPage = pageCount;

        if (pageCount > maxButtons) {
            if (currentPage <= halfMaxButtons) {
                endPage = maxButtons;
            } else if (currentPage + halfMaxButtons >= pageCount) {
                startPage = pageCount - maxButtons + 1;
            } else {
                startPage = currentPage - halfMaxButtons + 1;
                endPage = currentPage + halfMaxButtons;
            }
        }

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const pageNumbers = getPageNumbers();

    return (
        <div className="mgmt-paginated-table-pagination-bar">
            <input type="button" value="<<" onClick={() => paginate(1)} />
            <input type="button" value="<" onClick={() => paginate(Math.max(1, currentPage - 1))} />
            {pageNumbers.map(number => (
                <input
                    key={number}
                    type="button"
                    value={number.toString()}
                    onClick={() => paginate(number)}
                    className={currentPage === number ? 'selected' : ''}
                />
            ))}
            <input type="button" value=">" onClick={() => paginate(Math.min(pageCount, currentPage + 1))} />
            <input type="button" value=">>" onClick={() => paginate(pageCount)} />
        </div>
    );
};

export default PaginationBar;
