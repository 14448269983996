import React from 'react';
import { createRoot } from 'react-dom/client';
import { FutureConfig, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './components';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { createAppRouter } from "./router";
import { createMsalClient } from "./auth";
import { AuthenticationResult, EventMessage, EventType, InteractionType } from "@azure/msal-browser";
import { AuthLoading } from './components/common';
import { AuthErrorComponent } from './components/common/Auth';

const PHILIPS_EMAIL_DOMAIN = 'philips.com';

createMsalClient().then(msalClient => {
    msalClient.initialize().then(() => {
        const accounts = msalClient.getAllAccounts();
        if (accounts.length > 0) {
            const account = accounts.find(acc => acc.username.endsWith(PHILIPS_EMAIL_DOMAIN));
            if (account !== undefined) {
                msalClient.setActiveAccount(account);
            }
        }

        msalClient.addEventCallback((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                msalClient.setActiveAccount(account);
            }
        });

        const router = createAppRouter();
        const authRequest = {
            scopes: [`${msalClient.getConfiguration().auth.clientId}/.default`]
        }
        const routerFutureFlags : Partial<FutureConfig> = {
            v7_startTransition: true,
        };

        createRoot(document.getElementById("root")!)
            .render(
                <MsalProvider instance={msalClient}>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        errorComponent={AuthErrorComponent}
                        loadingComponent={AuthLoading}
                        authenticationRequest={authRequest}>
                        <AuthProvider>
                            <RouterProvider router={router} future={routerFutureFlags} />
                        </AuthProvider>
                    </MsalAuthenticationTemplate>
                </MsalProvider>
            );
    }).catch(err => {
        // TODO: Handle errors
        console.log(err);
    });
});
