import { ChannelAsset } from "../../../services/channel.types";
import { ChannelAssetsAction } from "../../../pages/Channels/Channels.types";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";

export function assetReducer(state: ChannelAsset, action: ChannelAssetsAction): ChannelAsset {
    switch (action.type) {
        case ChannelActionType.TOGGLE_ASSETS_NATIVE_LANGUAGE_ONLY: {
            return {...state, nativeLanguageOnly: !state.nativeLanguageOnly};
        }
        case ChannelActionType.SET_ASSETS_INCLUDED_TYPES: {
            return {
                ...state,
                include: {...state.include, types: action.payload}
            };
        }
        case ChannelActionType.SET_ASSETS_EXCLUDED_TYPES: {
            return {
                ...state,
                exclude: {...state.exclude, types: action.payload}
            };
        }
        case ChannelActionType.TOGGLE_ASSETS_INCLUDED_LEVELS_PUBLIC: {
            return {
                ...state,
                include: {
                    ...state.include,
                    accessLevels: {
                        ...state.include?.accessLevels,
                        publicLevel: !state.include?.accessLevels?.publicLevel
                    }
                }
            };
        }
        case ChannelActionType.TOGGLE_ASSETS_INCLUDED_LEVELS_SECURE: {
            return {
                ...state,
                include: {
                    ...state.include,
                    accessLevels: {
                        ...state.include?.accessLevels,
                        secure: !state.include?.accessLevels?.secure
                    }
                }
            };
        }
        case ChannelActionType.TOGGLE_ASSETS_INCLUDED_LEVELS_INTERNAL: {
            return {
                ...state,
                include: {
                    ...state.include,
                    accessLevels: {
                        ...state.include?.accessLevels,
                        internal: !state.include?.accessLevels?.internal
                    }
                }
            };
        }
        case ChannelActionType.TOGGLE_ASSETS_EXCLUDED_LEVELS_PUBLIC: {
            return {
                ...state,
                exclude: {
                    ...state.exclude,
                    accessLevels: {
                        ...state.exclude?.accessLevels,
                        publicLevel: !state.exclude?.accessLevels?.publicLevel
                    }
                }
            };
        }
        case ChannelActionType.TOGGLE_ASSETS_EXCLUDED_LEVELS_SECURE: {
            return {
                ...state,
                exclude: {
                    ...state.exclude,
                    accessLevels: {
                        ...state.exclude?.accessLevels,
                        secure: !state.exclude?.accessLevels?.secure
                    }
                }
            };
        }
        case ChannelActionType.TOGGLE_ASSETS_EXCLUDED_LEVELS_INTERNAL: {
            return {
                ...state,
                exclude: {
                    ...state.exclude,
                    accessLevels: {
                        ...state.exclude?.accessLevels,
                        internal: !state.exclude?.accessLevels?.internal
                    }
                }
            };
        }
        default:
            return state;
    }
}