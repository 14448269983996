import React from 'react';

export enum UserRole {
    Admin = "Admin",
    Helpdesk = "Helpdesk",
    Guest = "Guest"
}

export type CellMapper<T> = {(data: T, rowIdx: number): string | React.JSX.Element};

export interface TableProps<T> {
    headers: string[];
    items: T[];
    cellMappers: CellMapper<T>[];
    customStyle?: object
}
