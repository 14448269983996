import React, { useState } from 'react';
import { PaginatedTableProps } from './PaginatedTable.props';
import { PaginationBar } from './PaginationBar';
import { CollapseIcon, Direction } from "../../common/icons";

import './PaginatedTable.css';

const PaginatedTable: React.FC<PaginatedTableProps> = ({ logs, currentPage, itemsPerPage, paginate }) => {
    const [expandedMessageIndex, setExpandedMessageIndex] = useState<number | null>(null);

    const toggleMessage = (index: number) => {
        setExpandedMessageIndex(prevIndex => prevIndex === index ? null : index);
    };

    const pageCount = logs ? Math.ceil(logs.length / itemsPerPage) : 0;
    const currentLogs = logs?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div>
            <table className="mgmt-paginated-table">
                <thead>
                    <tr>
                        <th>DateTime</th>
                        <th>Layer</th>
                        <th>Component</th>
                        <th>State</th>
                        <th>Context</th>
                        <th>Object ID</th>
                        <th>Update ID</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {currentLogs?.map((log, index) => (
                        <tr key={index} className={`log-entry-${log.state.toLowerCase()}`}>
                            <td>{log.timestamp}</td>
                            <td>{log.layer}</td>
                            <td>{log.component}</td>
                            <td>{log.state}</td>
                            <td>{log.context}</td>
                            <td>{log.objectId}</td>
                            <td>{log.updateId}</td>
                            <td className={`message-cell ${expandedMessageIndex === index ? 'expanded' : 'collapsed'}`}>
                                {expandedMessageIndex === index ? (
                                    log.message.split('\n').map((item, key) => (
                                        <React.Fragment key={key}>
                                            {item}
                                            <br />
                                        </React.Fragment>
                                    ))
                                ) : (
                                    log.message.split('\n')[0]
                                )}
                                <div className="collapse-icon-wrapper" onClick={() => toggleMessage(index)}>
                                    <CollapseIcon
                                        direction={expandedMessageIndex === index ? Direction.Up : Direction.Down}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <PaginationBar
                currentPage={currentPage}
                pageCount={pageCount}
                paginate={paginate}
            />
        </div>
    );
};

export default PaginatedTable;
