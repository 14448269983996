import { Channel } from "../../../services/channel.types";
import {
    ChannelAction,
    ChannelAssetsAction,
    ChannelFilterAction,
    ChannelOutputAction,
    ChannelTransformationsAction
} from "../../../pages/Channels/Channels.types";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";
import { filtersReducer } from "./Filters.reducer";
import { outputReducer } from "./Output.reducer";
import { assetReducer } from "./Assets.reducer";
import { transformationsReducer } from "./Transformations.reducer";

export function channelsMainReducer(state: Channel, action: ChannelAction): Channel {
    switch (action.type) {
        case ChannelActionType.TOGGLE_ENABLED: {
            return {...state, enabled: !state.enabled};
        }
        case ChannelActionType.TOGGLE_PARALLEL: {
            return {...state, parallel: !state.parallel};
        }
        case ChannelActionType.SET_ID: {
            return {...state, channelId: action.payload};
        }
        case ChannelActionType.SET_NAME: {
            return {...state, name: action.payload};
        }
        case ChannelActionType.SET_DESCRIPTION: {
            return {...state, description: action.payload};
        }
        case ChannelActionType.SET_TARGET_FOLDER: {
            return {...state, targetFolder: action.payload};
        }
        case ChannelActionType.SET_EXPORT_TYPE: {
            return {...state, exportType: action.payload};
        }
        case ChannelActionType.SET_TIMING: {
            return {...state, timing: action.payload};
        }
        case ChannelActionType.RESET: {
            return {...action.payload}
        }
    }

    return {
        ...state,
        enabled: state.enabled,
        parallel: state.parallel,
        exportType: state.exportType,
        timing: state.timing,
        filter: filtersReducer(state.filter!, action as ChannelFilterAction),
        output: outputReducer(state.output!, action as ChannelOutputAction),
        assets: assetReducer(state.assets!, action as ChannelAssetsAction),
        transformations: transformationsReducer(state.transformations!, action as ChannelTransformationsAction)
    }
}