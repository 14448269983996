import React, { ChangeEvent } from 'react';
import { ConfigEntryViewProps } from './ConfigEntryView.types';

import './ConfigEntryView.css';

const ConfigEntryView: React.FC<ConfigEntryViewProps> = ({id, value, onChange, onDelete}) => {
    return (
        <div className='mgmt-serializer-ctx-config-entry'>
            <input type="text"
                   key={id}
                   id={id}
                   value={value}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => {
                       if (onChange) {
                           onChange(event);
                       }
                   }}/>
            <input type='button' value='Delete' onClick={onDelete}/>
        </div>
    );
}

export default ConfigEntryView;
