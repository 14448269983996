import React, { useEffect, useState } from 'react';
import { Intent, SnackbarProps, SnackbarState } from './Snackbar.types';
import './Snackbar.css';

const Snackbar: React.FC<SnackbarProps> = ({ message, isShown, duration = 3000,
                                               intent = Intent.ERROR, onClose}) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (isShown) {
            setIsActive(true);
            const timer = setTimeout(() => {
                setIsActive(false);
                if (onClose) {
                    onClose();
                }
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [isShown, duration, onClose]);

    return (
        <div className={`snackbar ${isActive ? 'show' : ''} ${intent === Intent.SUCCESS ? 'success' : ''}`}>
            {message}
        </div>
    );
}

export default Snackbar;

export const initialSnackbarState: SnackbarState = {showSnackbar: false, message: undefined, intent: undefined};