import { ChannelOutput } from "../../../services/channel.types";
import { ChannelOutputAction } from "../../../pages/Channels/Channels.types";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";

export function outputReducer(state: ChannelOutput, action: ChannelOutputAction): ChannelOutput {
    switch (action.type) {
        case ChannelActionType.SET_OUTPUT_XUCDM_LEVEL: {
            return {...state, xucdmLevel: action.payload};
        }
        case ChannelActionType.SET_OUTPUT_XUCDM_VERSION: {
            return {...state, xucdmVersion: action.payload};
        }
        case ChannelActionType.SET_OUTPUT_TRANSPORT_FORMAT: {
            return {...state, transportFormat: action.payload};
        }
        case ChannelActionType.SET_OUTPUT_HEADER_LINE: {
            return {...state, headerLine: action.payload};
        }
        case ChannelActionType.SET_OUTPUT_FOOTER_LINE: {
            return {...state, footerLine: action.payload};
        }
        case ChannelActionType.SET_OUTPUT_FILENAME_PATTERN: {
            return {...state, fileNamePattern: action.payload};
        }
        case ChannelActionType.SET_OUTPUT_BATCH_SIZE: {
            return {...state, batchSize: action.payload};
        }
        case ChannelActionType.TOGGLE_OUTPUT_FULL_FEED: {
            return {...state, fullFeed: !state.fullFeed};
        }
        case ChannelActionType.TOGGLE_OUTPUT_INCLUDE_INACTIVE: {
            return {...state, includeInactive: !state.includeInactive};
        }
        case ChannelActionType.TOGGLE_OUTPUT_CONSOLIDATED_FEED: {
            return {...state, consolidatedFeed: !state.consolidatedFeed};
        }
        case ChannelActionType.SET_OUTPUT_LOCALE_MAPPING: {
            return {...state, localeMapping: action.payload};
        }
        default:
            return state;
    }
}