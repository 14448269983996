import React from 'react';
import { NamedCheckBox } from '../../common';
import { SearchBarProps } from './SearchBar.props';
import { FlagsType } from '../../../pages/Logs/Logs.types';

import './SearchBar.css';

const SearchBar: React.FC<SearchBarProps> = ({
    objectId,
    setObjectId,
    updateId,
    setUpdateId,
    context,
    setContext,
    flags,
    handleFlagChange,
    selectAll,
    handleRefresh
}) => {
    return (
        <div className="mgmt-logs-search-bar">
            <input
                type="text"
                value={objectId}
                onChange={e => setObjectId(e.target.value)}
                placeholder="Object Id"
            />
            <input
                type="text"
                value={updateId}
                onChange={e => setUpdateId(e.target.value)}
                placeholder="Update Id"
            />
            <input
                type="text"
                value={context}
                onChange={e => setContext(e.target.value)}
                placeholder="Context"
            />
            <NamedCheckBox
                label="ALL"
                checked={selectAll}
                onChange={() => handleFlagChange('All')}
            />
            {Object.keys(flags).map((flag) => (
                <NamedCheckBox
                    key={flag}
                    label={flag}
                    checked={flags[flag as keyof FlagsType]}
                    onChange={() => handleFlagChange(flag as keyof FlagsType)}
                />
            ))}
            <input
                type="button"
                value="Refresh"
                onClick={handleRefresh}
            />
        </div>
    );
};

export default SearchBar;
