import React, { FC, useReducer, useState } from 'react';

import { ChannelActionType, ChannelProps } from './ChannelComponent.types';
import { Accordion, ConfirmationDialog, ModalDialog, ShowIf } from '../../common';
import { AppContext } from '../../../pages/context';
import { v4 as uuid } from 'uuid';
import { Channel, ExportType } from "../../../services/channel.types";
import ChannelProperties from "../ChannelProperties";
import { ChannelAction } from "../../../pages/Channels/Channels.types";
import Snackbar, { initialSnackbarState, Intent, SnackbarState } from "../../common/Snackbar";
import Filters from "../Filters";
import './ChannelComponent.css';
import '../../../common.css';
import OutputComponent from "../Output";
import { channelsMainReducer } from "../reducers/ChannelComponent.reducer";
import AssetsComponent from "../Assets";
import TransformationsComponent from "../Transformations";
import { validateNewChannel } from "../../../util/channelUtil";

const ChannelComponent: FC<ChannelProps> = ({view, onCancel, onSave}) => {
    const [currentChannel, dispatch] = useReducer(channelsMainReducer, {...view.channel} as Channel);
    const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
    const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
    const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(initialSnackbarState);
    const [isErrorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorMessages, setErrorMessages] = useState([] as string[]);
    const {services: {apiService}} = React.useContext(AppContext);
    const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(view.isOpen);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen)
    }
    const saveChangesMsg = `Are you sure you want to save changes for channel '${currentChannel.channelId}'?`;
    const createChangesMsg = `Are you sure you want to create a new channel with ID '${currentChannel.channelId}'?`;
    const deleteChannelConfirmationMsg = `Are you sure you want to delete channel '${currentChannel.channelId}'?`;
    const cancelChangesMsg = `Are you sure? All changes you've made for channel '${currentChannel.channelId}' will be lost.`;

    const handleCancel = () => {
        if (onCancel) {
            onCancel(currentChannel.channelId!);
        }
        dispatch({type: ChannelActionType.RESET, payload: view.channel});
        setButtonsDisabled(false);
        setIsOpen(false);
    };

    const isDataChanged = JSON.stringify(view.channel) !== JSON.stringify(currentChannel);
    const getChannelTitle = () => {
        if (isDataChanged) {
            return `${currentChannel.channelId!} *`;
        }
        return currentChannel.channelId !== "" ? currentChannel.channelId! : "new-channel";
    };

    const openSaveDialog = (): void => {
        setSaveDialogOpen(true);
    }

    const closeSaveDialog = (): void => {
        setSaveDialogOpen(false);
    }

    const openCreateDialog = (): void => {
        setCreateDialogOpen(true);
    }

    const closeCreateDialog = (): void => {
        setCreateDialogOpen(false);
    }

    const openDeleteDialog = (): void => {
        setDeleteDialogOpen(true);
    }

    const closeDeleteDialog = (): void => {
        setDeleteDialogOpen(false);
    }

    const openCancelDialog = (): void => {
        setCancelDialogOpen(true);
    }

    const closeCancelDialog = (): void => {
        setCancelDialogOpen(false);
    }

    const handleChannelPropertiesChange = (action: ChannelAction) => {
        dispatch(action);
    }

    const updateChannelFailedErrorMessage = `Failed to update channel '${currentChannel.channelId}'`;
    const handleSave = () => {
        setButtonsDisabled(true);
        apiService.updateChannel(currentChannel)
            .then((response) => {
                if (response.status === "VALIDATION_FAILED") {
                    setErrorMessages(response.validationMessages || [updateChannelFailedErrorMessage])
                    setErrorDialogOpen(true);
                    setButtonsDisabled(false);
                } else {
                    setSnackbarState({
                        showSnackbar: true,
                        message: "Channel is updated successfully",
                        intent: Intent.SUCCESS
                    });
                }
            })
            .catch((exception) => {
                console.error("Exception during the update channel API call:", exception);
                setErrorMessages([updateChannelFailedErrorMessage]);
                setErrorDialogOpen(true);
                handleCancel();
            });
        if (snackbarState.showSnackbar) {
            setSnackbarState(initialSnackbarState);
        }
    }

    const createChannelFailedErrorMessage = `Failed to create a channel '${currentChannel.channelId}'`;
    const handleCreate = () => {
        const validationErrors = validateNewChannel(currentChannel);
        if (validationErrors.length > 0) {
            setErrorMessages(validationErrors);
            setErrorDialogOpen(true);
            return;
        }

        setButtonsDisabled(true);
        apiService.addChannel(currentChannel)
            .then((response) => {
                if (response.status === "VALIDATION_FAILED") {
                    setErrorMessages(response.validationMessages || [createChannelFailedErrorMessage])
                    setErrorDialogOpen(true);
                    setButtonsDisabled(false);
                } else {
                    setSnackbarState({
                        showSnackbar: true,
                        message: "Channel is created successfully",
                        intent: Intent.SUCCESS
                    });
                }
            })
            .catch((exception) => {
                console.error("Exception during the create channel API call:", exception);
                setErrorMessages([createChannelFailedErrorMessage]);
                setErrorDialogOpen(true);
                handleCancel();
            });
        if (snackbarState.showSnackbar) {
            setSnackbarState(initialSnackbarState);
        }
    }

    const deleteChannelFailedErrorMessage = `Failed to delete channel '${currentChannel.channelId}'`;
    const handleDelete = (): void => {
        setButtonsDisabled(true);
        apiService.deleteChannel(currentChannel)
            .then(() => {
                setSnackbarState({
                    showSnackbar: true,
                    message: `Channel ${currentChannel.channelId} was deleted successfully`,
                    intent: Intent.SUCCESS
                });
            })
            .catch((exception) => {
                console.error("Exception during the delete channel API call:", exception);
                setErrorMessages([deleteChannelFailedErrorMessage]);
                setErrorDialogOpen(true);
                handleCancel();
            });
        if (snackbarState.showSnackbar) {
            setSnackbarState(initialSnackbarState);
        }
    }

    const handleSnackbarClose = () => {
        toggleIsOpen();
        setButtonsDisabled(false);
        onSave!();
    };

    const showProductRelatedItems = currentChannel.exportType === ExportType.XUCDM_PRODUCT;
    const showProductGroupRelatedItems = currentChannel.exportType === ExportType.XUCDM_PRODUCTGROUP;
    const showClassificationRelatedItems = currentChannel.exportType === ExportType.XUCDM_CLASSIFICATION;

    const isNewChannel = view.isNew ?? false;

    return (
        <div>
            <form>
                <Accordion title={getChannelTitle()}
                           isOpen={isOpen}
                           onOpen={toggleIsOpen}
                           onClose={toggleIsOpen}>
                    <ChannelProperties channelId={currentChannel.channelId!}
                                       name={currentChannel.name!}
                                       description={currentChannel.description!}
                                       targetFolder={currentChannel.targetFolder!}
                                       enabled={currentChannel.enabled!}
                                       parallel={currentChannel.parallel!}
                                       exportType={currentChannel.exportType!}
                                       timing={currentChannel.timing!}
                                       isNewChannel={isNewChannel}
                                       onChange={handleChannelPropertiesChange}
                    />
                    <Filters channelId={currentChannel.channelId!}
                             showProductRelatedItems={showProductRelatedItems}
                             showProductGroupRelatedItems={showProductGroupRelatedItems}
                             showClassificationRelatedItems={showClassificationRelatedItems}
                             isNewChannel={isNewChannel}
                             filter={currentChannel.filter!}
                             onChange={handleChannelPropertiesChange}/>
                    <OutputComponent channelId={currentChannel.channelId!}
                                     showProductRelatedItems={showProductRelatedItems}
                                     showProductGroupRelatedItems={showProductGroupRelatedItems}
                                     data={currentChannel.output!}
                                     isNewChannel={isNewChannel}
                                     onChange={handleChannelPropertiesChange}/>
                    <ShowIf expression={!showClassificationRelatedItems}>
                        <AssetsComponent channelId={currentChannel.channelId!}
                                         data={currentChannel.assets!}
                                         onChange={handleChannelPropertiesChange}/>
                    </ShowIf>
                    <TransformationsComponent channelId={currentChannel.channelId!}
                                              data={currentChannel.transformations!}
                                              onChange={handleChannelPropertiesChange}/>
                    <div className='mgmt-channels-panel'>
                        <input className='mgmt-channels-panel-btn' type='button' value='Cancel'
                               disabled={buttonsDisabled}
                               title={'Discard changes and close the Channel'}
                               onClick={openCancelDialog}/>
                        <ShowIf expression={!isNewChannel}>
                            <input className='mgmt-channels-panel-btn'
                                   type='button' value='Delete'
                                   disabled={buttonsDisabled}
                                   title={'Delete this Channel'}
                                   onClick={openDeleteDialog}></input>
                        </ShowIf>
                        <ShowIf expression={!isNewChannel}>
                            <input className='mgmt-channels-panel-btn'
                                   disabled={buttonsDisabled}
                                   title={'Save changes'}
                                   type='button' value='Save'
                                   onClick={openSaveDialog}/>
                        </ShowIf>
                        <ShowIf expression={isNewChannel}>
                            <input className='mgmt-channels-panel-btn'
                                   disabled={buttonsDisabled}
                                   title={'Create channel'}
                                   type='button' value='Create'
                                   onClick={openCreateDialog}/>
                        </ShowIf>
                    </div>
                </Accordion>
            </form>
            <ShowIf expression={isSaveDialogOpen}>
                <ConfirmationDialog message={saveChangesMsg}
                                    onClose={closeSaveDialog}
                                    onConfirm={handleSave}
                                    isOpen={isSaveDialogOpen}/>
            </ShowIf>
            <ShowIf expression={isCreateDialogOpen}>
                <ConfirmationDialog message={createChangesMsg}
                                    onClose={closeCreateDialog}
                                    onConfirm={handleCreate}
                                    isOpen={isCreateDialogOpen}/>
            </ShowIf>
            <ShowIf expression={isCancelDialogOpen}>
                <ConfirmationDialog message={cancelChangesMsg}
                                    onClose={closeCancelDialog}
                                    onConfirm={handleCancel}
                                    isOpen={isCancelDialogOpen}/>
            </ShowIf>
            <ShowIf expression={isDeleteDialogOpen}>
                <ConfirmationDialog message={deleteChannelConfirmationMsg}
                                    onClose={closeDeleteDialog}
                                    onConfirm={handleDelete}
                                    isOpen={isDeleteDialogOpen}/>
            </ShowIf>

            <ShowIf expression={isErrorDialogOpen}>
                <ModalDialog isOpen={isErrorDialogOpen} onClose={() => {
                    setErrorDialogOpen(false);
                    setErrorMessages([]);
                }}>
                    <p style={{margin: 0}}>Operation failed</p>
                    <div>
                        {errorMessages
                            && errorMessages.length > 0
                            && errorMessages.map(message => (<p key={uuid()}>{message}</p>))}
                    </div>
                </ModalDialog>
            </ShowIf>
            <Snackbar message={snackbarState.message!} isShown={snackbarState.showSnackbar}
                      duration={3000} intent={snackbarState.intent}
                      onClose={handleSnackbarClose}/>
        </div>
    );
}

export default ChannelComponent;
