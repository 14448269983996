import React, { useState } from 'react';
import { PresetBarProps } from './PresetBar.props';

import './PresetBar.css';

const PresetBar: React.FC<PresetBarProps> = ({ presets, onPresetSelected }) => {
    const [selectedPreset, setSelectedPreset] = useState<string>('');

    const handlePresetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPreset(event.target.value);
    };

    const handleUsePreset = () => {
        onPresetSelected(selectedPreset);
    };

    return (
        <div>
            <label className="mgmt-tsunami-preset-bar-label" htmlFor="presetSelection">Choose a preset</label>
            <select id="presetSelection" value={selectedPreset} onChange={handlePresetChange}>
                <option value="">Select a preset</option>
                {presets.map(preset => (
                    <option key={preset} value={preset}>{preset}</option>
                ))}
            </select>
            <button onClick={handleUsePreset}>Use preset</button>
        </div>
    );
};

export default PresetBar;
