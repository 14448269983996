import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="philips-footer">
            <img className="philips-footer-img" src="/gui/react/footer_text.png" />
        </footer>
    );
};

export default Footer;