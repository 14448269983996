import { Accordion } from "../../common";
import React, { ChangeEvent, useRef, useState } from "react";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";
import { TransformationsComponentTypes } from "./TransformationsComponent.types";
import TTable from "../../Table";
import ActionButtons from "./ActionsButton";
import './TransformationsComponent.css'

const TransformationsComponent: React.FC<TransformationsComponentTypes> = (props: TransformationsComponentTypes) => {
    const {data} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }
    const [isXsltFilesPanelOpen, setIsXsltFilesPanelOpen] = useState(false);
    const toggleIsXsltFilesPanelOpen = () => {
        setIsXsltFilesPanelOpen(!isXsltFilesPanelOpen);
    }
    const newTransformationRef = useRef<string>("");

    const updateRows = (newRows: string[]) => {
        props.onChange({
            type: ChannelActionType.SET_TRANSFORMATIONS_APPLY_ARRAY,
            payload: newRows,
        })
    };

    const addRow = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (newTransformationRef.current) {
            const newRows = [...(data.apply ?? [])];
            newRows.push(newTransformationRef.current);
            updateRows(newRows);
            newTransformationRef.current = "";
        }
    }

    const moveRow = (direction: 'up' | 'down', index: number) => {
        if ((direction === 'up' && index > 0) || (direction === 'down' && index < (data.apply ?? []).length)) {
            const newRows = [...(data.apply ?? [])];
            const [removed] = newRows.splice(index, 1);
            newRows.splice(direction === 'up' ? index - 1 : index + 1, 0, removed);
            updateRows(newRows);
        }
    }

    const removeRow = (index: number) => {
        const newRows = [...(data.apply ?? [])];
        newRows.splice(index, 1);
        updateRows(newRows);
    }

    const headers = ["Stylesheet"]
    const cellMappers = [(data: string, rowIdx: number) => {
        if (data === undefined || data === null || data === "") {
            return (
                <div className='mgmt-channels-form-container'>
                    <label>New Stylesheet:</label>
                    <div className='mgmt-channels-form-container'>
                        <input
                            type='text'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                newTransformationRef.current = event.target.value;
                            }}
                            className='mgmt-channels-form-input'
                        />
                        <button onClick={addRow}>✅ Add</button>
                    </div>
                </div>
            );
        }
        return (
            <div className="mgmt-channels-form-container">
                <div>{data}</div>
                <div className="mgmt-channels-transform-actions">
                    <ActionButtons
                        onMoveUp={() => moveRow('up', rowIdx)}
                        onMoveDown={() => moveRow('down', rowIdx)}
                        onRemove={() => removeRow(rowIdx)}
                    />
                </div>
            </div>
        )
    }];

    return (
        <Accordion title={"Transformations"} isOpen={isOpen} onOpen={toggleIsOpen} onClose={toggleIsOpen}>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|parameters'>Parameters (key=value, one per line or comma separated)</label>
                <textarea id='channel|parameters'
                          name='parameters'
                          value={data?.params ?? ""}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              props.onChange({
                                  type: ChannelActionType.SET_TRANSFORMATIONS_PARAMETERS,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <Accordion title={"XSLT Files"} isOpen={isXsltFilesPanelOpen}
                       onOpen={toggleIsXsltFilesPanelOpen} onClose={toggleIsXsltFilesPanelOpen}>
                <div className='mgmt-channels-centered'>
                    <TTable headers={headers} items={[...(data?.apply ?? []), ""]} cellMappers={cellMappers}
                            customStyle={{maxWidth: '50em'}}/>
                </div>
            </Accordion>
        </Accordion>
    );
}

export default TransformationsComponent;
