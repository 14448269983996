import React from "react";

import { useNavigate } from "react-router-dom";
import { PATHS } from '../../config/paths';
import { Menu } from "../../components";

import "../../common.css";
import "./Home.css";

const HomePage: React.FC = () => {
    const [isMenuOpen, setMenuOpen] = React.useState(false);
    const [isManagementOpen, setIsManagementOpen] = React.useState(true);
    const [isMonitoringOpen, setMonitoringOpen] = React.useState(true);
    const [isLogoffConfirmationDialogOpen, setIsLogoffConfirmationDialogOpen] = React.useState(false);

    const navigate = useNavigate();

    return (
        <div className="mgmt-home-page">
            <Menu
                isMenuOpen={isMenuOpen}
                setMenuOpen={setMenuOpen}
                isManagementOpen={isManagementOpen}
                setIsManagementOpen={setIsManagementOpen}
                isMonitoringOpen={isMonitoringOpen}
                setMonitoringOpen={setMonitoringOpen}
                isLogoffConfirmationDialogOpen={isLogoffConfirmationDialogOpen}
                setIsLogoffConfirmationDialogOpen={setIsLogoffConfirmationDialogOpen}
            />
            <div className="mgmt-home-main-panel">
                <div className="mgmt-home-title">
                    <label>PCP Management Dashboard</label>
                </div>
                <div className="mgmt-home-body">
                    <button onClick={() => navigate(PATHS.MONITORING.LOGS)}>Logs</button>
                    <button onClick={() => navigate(PATHS.MANAGEMENT.CHANNELS)}>Channels</button>
                    <button onClick={() => navigate(PATHS.MANAGEMENT.RUN_CHANNEL)}>Run Channel</button>
                    <button onClick={() => navigate(PATHS.MANAGEMENT.TRANSFER)}>Transfer</button>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
