import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context';
import { SerializerConfiguration } from "../../services/serializer-contexts.types";
import { TsunamiStartRequest } from '../../services/api.types';
import { Configuration, PresetBar } from '../../components/Tsunami';
import { ConfirmationDialog, ShowIf } from '../../components/common';
import { ModalDialog } from '../../components';
import Snackbar from "../../components/common/Snackbar";
import { sortAlphanumericIgnoreCase } from '../../utils';

import './Tsunami.css';

const TsunamiPage = () => {
    const [serializerConfigs, setSerializerConfigs] = useState<SerializerConfiguration[]>([]);
    const [tableSelectList, setTableSelectList] = useState<string[]>([]);
    const [tableClearList, setTableClearList] = useState<string[]>([]);
    const [objectIdContextsList, setObjectIdContextsList] = useState<string>('');
    const [contextsList, setContextsList] = useState<string>('');
    const [serializerSelectList, setSerializerSelectList] = useState<{ name: string; selected: boolean }[]>([]);
    const [reason, setReason] = useState<string>('');
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalDialogMessage, setModalDialogMessage] = useState('');
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const { services: { apiService } } = useContext(AppContext);

    useEffect(() => {
        const fetchPresets = async () => {
            setIsError(false);
            try {
                const response = await apiService.listSerializerConfigs();
                const configs = response.serializerConfigurationModels || [];
                configs.push({ name: 'cache', contexts: [], catalogs: [], customParams: [] });
                configs.push({ name: 'canonical', contexts: [], catalogs: [], customParams: [] });
                configs.sort((c1, c2) => sortAlphanumericIgnoreCase(c1.name, c2.name));
                setSerializerSelectList(
                    configs
                        .filter(config => config.name !== 'cache' && config.name !== 'canonical')
                        .map(config => ({ name: config.name, selected: false }))
                );
                setSerializerConfigs(configs);
            } catch (err) {
                setIsError(true);
                setErrorMessage((err as Error).message);
            }
        };

        fetchPresets();
    }, [apiService]);

    const handlePresetSelected = (presetName: string) => {
        selectPreset(presetName);
    };

    const selectPreset = async (presetName: string) => {
        if (!presetName) {
            setIsModalOpen(true);
            setModalDialogMessage('Please select a preset');
            return;
        }
        setIsError(false);
        setTableSelectList([]);
        setTableClearList([]);

        try {
            const response = await apiService.listTablesForLayer(presetName);
            const tables = response.tables.filter(table => !isExcluded(table, presetName));
            setTableSelectList(tables);

            if (presetName === "cache") {
                const canonicalResponse = await apiService.listTablesForLayer("canonical");
                setTableClearList(canonicalResponse.tables);
            }
        } catch (err) {
            setIsError(true);
            setErrorMessage((err as Error).message);
        }
    };

    const isExcluded = (table: string, presetName: string) => {
        const tablesToExcludePerLayer: { [key: string]: string[] } = {
            "canonical": [
                "changelist",
                "link",
                "propagation",
                "denormalizedtree",
                "denormalizedproduct",
                "denormalizedfamily",
                "denormalizedlogisticalproduct"
            ]
        };

        const tablesToExclude = tablesToExcludePerLayer[presetName];
        if (tablesToExclude) {
            return tablesToExclude.some(excludedTable => table.endsWith(excludedTable));
        }
        return false;
    };

    const handleTsunamiRun = () => {
        if (!reason) {
            setIsModalOpen(true);
            setModalDialogMessage('Please provide a valid reason to run the tsunami');
            return;
        }
        setIsConfirmationDialogOpen(true);
    };

    const launchTsunami = async () => {
        const selectedSerializers = serializerSelectList
            .filter(serializer => serializer.selected)
            .map(serializer => serializer.name);

        const tsunamiStartRequest: TsunamiStartRequest = {
            tableSelectList,
            tableClearList,
            serializerSelectList: selectedSerializers,
            reason,
            objectIdContextsList,
            contextsList
        };

        try {
            const response = await apiService.startTsunami(tsunamiStartRequest);
            setTableSelectList([]);
            setTableClearList([]);
            setSerializerSelectList(serializerSelectList.map(serializer => ({ ...serializer, selected: false })));
            setReason('');
            setObjectIdContextsList('');
            setContextsList('');
            setIsModalOpen(true);
            setModalDialogMessage(`Tsunami with id '${response.tsunamiId}' started successfully!`);
        } catch (err) {
            setIsError(true);
            setErrorMessage((err as Error).message);
        }
    };

    function closeConfirmationDialog() {
        setIsConfirmationDialogOpen(false);
    }

    return (
        <div>
            <h2>Tsunami</h2>
            <div className="warning">
                Running a tsunami against a PCP environment can potentially cause a lot of events to be triggered within the system.
                This can result in both a downgrade of PCP performance and additional costs being incurred because of higher
                resource usage.
            </div>
            <PresetBar
                presets={serializerConfigs.map(config => config.name)}
                onPresetSelected={handlePresetSelected}
            />
            <Configuration
                tableSelectList={tableSelectList}
                setTableSelectList={setTableSelectList}
                tableClearList={tableClearList}
                setTableClearList={setTableClearList}
                objectIdContextsList={objectIdContextsList}
                setObjectIdContextsList={setObjectIdContextsList}
                contextsList={contextsList}
                setContextsList={setContextsList}
                serializerSelectList={serializerSelectList}
                setSerializerSelectList={setSerializerSelectList}
            />
            <div className="mgmt-tsunami-reason">
                <label className="mgmt-tsunami-textarea-label">Reason for running tsunami</label>
                <textarea
                    className="mgmt-tsunami-textarea"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    cols={100}
                    rows={3}
                />
            </div>
            <div className="mgmt-tsunami-run">
                <input type="button" value="Run Tsunami" onClick={handleTsunamiRun} />
            </div>
            <Snackbar message={errorMessage} isShown={isError} />
            <ModalDialog
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}>
                <div>
                    <p>{modalDialogMessage}</p>
                </div>
            </ModalDialog>
            <ShowIf expression={isConfirmationDialogOpen}>
                <ConfirmationDialog
                    isOpen={isConfirmationDialogOpen}
                    message={'Would you like to start a Tsunami with the given configuration? (Additional costs and performance degradation might result from this action)'}
                    onClose={closeConfirmationDialog}
                    onConfirm={launchTsunami}
                />
            </ShowIf>
        </div>
    );
};

export default TsunamiPage;
