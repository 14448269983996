import React from 'react';

import { ReprocessProductPageProps, ReprocessType } from './ReprocessProduct.types';
import { ReprocessIngestProductsProps, ReprocessIngestProductsResponse } from '../../services';

import { FormGroup, ModalDialog } from '../../components';

import './ReprocessProduct.css';
import { AppContext } from '../context';
import { ShowIf } from "../../components/common";

const emptyProps = () => {
    return {
        batchId: '',
        productId: '',
        context: '',
        timestamp: '',
        type: ReprocessType.Batch,
    } as ReprocessProductPageProps;
}

const ReprocessProductPage = () => {
    const [props, setProps] = React.useState(emptyProps());
    const [reprocessResult, setReprocessResult] = React.useState({} as ReprocessIngestProductsResponse);
    const [isLoading, setLoading] = React.useState(false);
    const [isModalOpen, setModalOpen] = React.useState(false);
    const {
        services: {
            apiService,
        }
    } = React.useContext(AppContext);

    const isBatchOptionDisabled = ReprocessType.Batch !== props.type

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        try {
            setLoading(true);
            let data: ReprocessIngestProductsProps;
            if (ReprocessType.Batch === props.type as ReprocessType) {
                data = {
                    batchId: props.batchId!, // cast is safe since the property is required
                    productId: props.productId,
                    context: props.context,
                };
            } else {
                const timestamp = Date.parse(props.timestamp as string); // cast is safe since the property is required
                data = {timestamp: timestamp};
            }
            apiService.reprocessIngestProducts(data).then(response => {
                setReprocessResult(response);
                setLoading(false);
                setModalOpen(true);
                setProps(emptyProps());
            })
                .catch(error => {
                    setReprocessResult({
                        'status': 'ERROR',
                        'message': error.message,
                    });
                    setLoading(false);
                    setModalOpen(true);
                });

        } catch (error) {
            setReprocessResult({
                'status': 'ERROR',
                'message': error,
            } as ReprocessIngestProductsResponse);
            setLoading(false);
            setModalOpen(true);
        }
    }

    return (
        <div className={isLoading ? 'disabled' : ''}>
            <h2>Ingest Products Batch</h2>
            <div className='mgmt-form'>
                <form onSubmit={handleSubmit}>
                    <div className='mgmt-form-section-title'>
                        <label>Reprocess failed Ingest Denormalized Product(s)</label>
                    </div>
                    <div className='mgmt-form-section-body'>
                        <div className='mgmt-form-subsection'>
                            <label>
                                <input type='radio'
                                       style={{'marginRight': '0.5em'}}
                                       value={ReprocessType.Batch}
                                       checked={ReprocessType.Batch === props.type}
                                       onClick={() => setProps({...props, type: ReprocessType.Batch})}
                                       readOnly={true}></input>
                                Reprocess failed ingest product(s)
                            </label>

                            <FormGroup id='reimport-ingest-batch-id'
                                       label='Batch ID *'
                                       inputType='text'
                                       name='batchId'
                                       placeholder='Please enter Batch ID'
                                       value={props.batchId}
                                       required={true}
                                       onChange={(event) => setProps({...props, batchId: event.target.value})}
                                       disabled={isBatchOptionDisabled}/>
                            <FormGroup id='reimport-ingest-product-id'
                                       label='Product ID'
                                       inputType='text'
                                       name='productId'
                                       placeholder='Please enter Product ID'
                                       value={props.productId}
                                       onChange={(event) => setProps({...props, productId: event.target.value})}
                                       disabled={isBatchOptionDisabled}/>
                            <FormGroup id='reimport-ingest-context'
                                       label='Context'
                                       inputType='text'
                                       name='context'
                                       value={props.context}
                                       placeholder='Please enter language context like en_AA'
                                       pattern='^[a-z]{2}_[A-Z]{2}$'
                                       onChange={(event) => setProps({...props, context: event.target.value})}
                                       disabled={isBatchOptionDisabled}/>
                        </div>
                        <div className='mgmt-form-subsection'>
                            <div className='form-group'>
                                <label>
                                    <input type='radio'
                                           style={{'marginRight': '0.5em'}}
                                           value={ReprocessType.Timestamp}
                                           checked={ReprocessType.Timestamp === props.type}
                                           onClick={() => setProps({...props, type: ReprocessType.Timestamp})}
                                           readOnly={true}></input>
                                    Reprocess all failed failed ingest products submitted after
                                </label>
                            </div>
                            <FormGroup id='reimport-ingest-timestamp'
                                       label='Timestamp (UTC) *'
                                       inputType='datetime-local'
                                       name='timestamp'
                                       value={props.timestamp}
                                       required={true}
                                       step='1'
                                       onChange={(event) => setProps({...props, timestamp: event.target.value})}
                                       disabled={ReprocessType.Timestamp !== props.type}/>
                        </div>
                        <input type='hidden' name='type' value={props.type}></input>
                        <p className='mgmt-form-note'>Note: All fields marked with * are required</p>

                        <input type='submit' value='Submit'></input>
                    </div>
                </form>
                <ModalDialog isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                    <ShowIf expression={'OK' === reprocessResult.status}>
                        <div>
                            <p>Product(s) reprocessing is successfully triggered.</p>
                            <p>requestId: {reprocessResult.requestId}</p>
                            <p>updateId: {reprocessResult.updateId}</p>
                        </div>
                    </ShowIf>
                    <ShowIf expression={'ERROR' === reprocessResult.status}>
                        <div>
                            <p>Failed to trigger product(s) reprocessing.</p>
                            <p>Error details: {reprocessResult.message}</p>
                        </div>
                    </ShowIf>
                </ModalDialog>
            </div>
        </div>
    )
};

export default ReprocessProductPage;
