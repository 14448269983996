import React, { FC } from "react";

import './AccordionMenuItem.css';

import { AccordionMenuItemProps } from "./AccordionMenuItem.types";
import { CollapseIcon, Direction } from "../icons";

const AccordionMenuItem: FC<AccordionMenuItemProps> = ({ title, isOpen, children, onOpen, onClose }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleOpen = () => {
        if (onOpen) {
            onOpen();
        }
    }

    return (
        <div>
            <div className="mgmt-menu-item-collapsable">
                <div className='mgmt-menu-item-section-title' style={isOpen ? { backgroundColor: '#37659DFF' } : {}}
                    onClick={() => isOpen ? handleClose() : handleOpen()}>
                    <label>{title}</label>
                    <CollapseIcon direction={isOpen ? Direction.Down : Direction.Left} />
                </div>
                <div className="mgmt-menu-section-item-body" hidden={!isOpen}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AccordionMenuItem;
