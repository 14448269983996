import React from "react";
import { ConfirmationDialogProps } from "./ConfirmationDialog.types";

import './ConfirmationDialog.css';
import { runFunctions } from "../../../utils";
import { BaseModalDialog } from "../BaseModalDialog";

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
                                                                   title,
                                                                   message,
                                                                   isOpen,
                                                                   onCancel,
                                                                   onConfirm,
                                                                   onClose
                                                               }) => {
    const [open, setOpen] = React.useState(isOpen);
    const dialogTitle = title || 'Confirmation';

    const handleCancel = () => runFunctions(onCancel, onClose, () => {
        setOpen(false);
    });

    const handleConfirmation = () => runFunctions(onConfirm, onClose, () => {
        setOpen(false);
    });

    return (
        <BaseModalDialog isOpen={open} onCancel={handleCancel}>
            <div className="mgmt-confirmation-dialog">
                <div>
                    <p className='mgmt-confirmation-dialog-title'>{dialogTitle}</p>
                </div>
                <div>
                    <p>{message}</p>
                    <div className='mgmt-confirmation-dialog-btn-panel'>
                        <input type='button' value='No' onClick={handleCancel}></input>
                        <input type='button' value='Yes' onClick={handleConfirmation}></input>
                    </div>
                </div>
            </div>
        </BaseModalDialog>
    );
};

export default ConfirmationDialog;
