import React from "react";

import './LoadingSpinner.css';

export interface LoadingSpinnerProps {
    message?: string
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message = 'Loading...' }) => {
    return (
        <div className='mgmt-loading'>
            <div className="mgmt-loading-spinner">
            </div>
            <p style={{ textAlign: 'center' }}>{message}</p>
        </div>
    );
}

export default LoadingSpinner;