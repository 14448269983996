import React, { useContext, useEffect, useState } from "react";
import "./Channels.css";
import { Channel, ExportType } from "../../services/channel.types";
import { sortAlphanumericIgnoreCase } from "../../utils";
import { AppContext } from "../context";
import { LoadingSpinner, ShowIf } from "../../components/common";
import ChannelComponent from "../../components/Channels/ChannelComponent";
import Snackbar, { initialSnackbarState, Intent, SnackbarState } from "../../components/common/Snackbar";

const ChannelsPage = () => {
    const [loadingMessage, setLoadingMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isNewChannelCreated, setIsNewChannelCreated] = useState(false);
    const [channels, setChannels] = useState([] as Channel[]);
    const {services: {apiService}} = useContext(AppContext);
    const [refresh, setRefresh] = useState(true);
    const [snackbarState, setSnackbarState] = useState<SnackbarState>(initialSnackbarState);

    const doRefresh = (): void => {
        setRefresh(true);
    }

    const closeNewChannel = (channelId: string): void => {
        setIsNewChannelCreated(false);
        setChannels(channels.filter((value: Channel) => value.channelId !== channelId));
    }

    const getChannelViewModels = () => {
        return channels.map((channel: Channel) => {
            const isNew = "" === channel.channelId;
            const isOpen = isNew;
            return <ChannelComponent key={channel.channelId}
                                     view={{ channel, isOpen, isNew }}
                                     onSave={doRefresh}
                                     onCancel={isNew ? closeNewChannel : undefined}
            />
        });
    };

    useEffect(() => {
        if (refresh) {
            setLoadingMessage('Loading...');
            setLoading(true);
            apiService.listChannels()
                .then(response => {
                    const loadedChannels = response.channels || [];
                    loadedChannels.sort((c1, c2) => sortAlphanumericIgnoreCase(c1.channelId, c2.channelId));
                    setChannels(loadedChannels);
                    setLoading(false);
                }).catch(() => {
                setLoading(false);
                setSnackbarState({showSnackbar: true, message: "Cannot list Channels", intent: Intent.ERROR});
            });
            setRefresh(false);
            setIsNewChannelCreated(false);
        }
    }, [apiService, setLoading, setChannels, refresh]);

    const handleNewChannel = () => {
        const newChannel = { channelId: "",
            timing: "",
            exportType: ExportType.XUCDM_PRODUCT,
            assets: { nativeLanguageOnly: false },
            output: { xucdmLevel: "0",
                transportFormat: "XmlPerProductContext"},
            filter: { audience: "B2B",
                productTypes: { accessory: false } },
            transformations: { apply: [] }
        };
        const updatedChannels = channels;
        updatedChannels.unshift(newChannel);

        setIsNewChannelCreated(true);
        setChannels(updatedChannels);
    };

    return (
        <div>
            <h2>Channels</h2>
            <ShowIf expression={loading}>
                <LoadingSpinner message={loadingMessage}/>
            </ShowIf>
            <ShowIf expression={!loading}>
                <div className='mgmt-channel-form-container'>
                    <button
                        onClick={handleNewChannel}
                        disabled={isNewChannelCreated}
                        className='mgmt-channel-form-item'>
                        Create New Channel
                    </button>
                </div>
            {getChannelViewModels()}
            </ShowIf>
            <Snackbar message={snackbarState.message!}
                      isShown={snackbarState.showSnackbar}
                      intent={snackbarState.intent} />
        </div>
    )
}

export default ChannelsPage;