import React, { useContext, useState } from 'react';
import { AppContext } from '../context';
import { ModalDialog } from '../../components';
import { ConfirmationDialog, ShowIf } from '../../components/common';
import Snackbar from "../../components/common/Snackbar";

const AutoscalingOverridePage = () => {
    const { services: { apiService } } = useContext(AppContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalDialogMessage, setModalDialogMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    const overrideAutoscaling = async () => {
        try {
            await apiService.overrideAutoscaling();
            setIsModalOpen(true);
            setModalDialogMessage('Tsunami Mode started');
        } catch (error) {
            setIsError(true);
            setErrorMessage((error as Error).message);
        }
    };

    const handleOverrideAutoscaling = () => {
        setIsConfirmationDialogOpen(true);
    };

    function closeConfirmationDialog() {
        setIsConfirmationDialogOpen(false);
    }

    return (
        <div>
            <h2>Autoscaling</h2>
            <div className="warning">
                Overriding autoscaling in a PCP environment consumes a lot of the AWS account&apos;s capacity units. Other environments,
                such as production, might run into performance issues as a result of this. Furthermore additional costs will be
                incurred when scaling the system if not required.
            </div>
            <div>
                <input type="button" value="Override autoscaling" onClick={handleOverrideAutoscaling} />
            </div>
            <Snackbar message={errorMessage} isShown={isError} />
            <ModalDialog
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}>
                <div>
                    <p>{modalDialogMessage}</p>
                </div>
            </ModalDialog>
            <ShowIf expression={isConfirmationDialogOpen}>
                <ConfirmationDialog
                    isOpen={isConfirmationDialogOpen}
                    message={'Would you like to override autoscaling?'}
                    onClose={closeConfirmationDialog}
                    onConfirm={overrideAutoscaling}
                />
            </ShowIf>
        </div>
    );
};

export default AutoscalingOverridePage;
