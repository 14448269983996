import React, { useState } from 'react';
import { TableSelectProps } from './TableSelect.props';

import './TableSelect.css';

const TableSelect: React.FC<TableSelectProps> = ({ tableList, setTableList }) => {
    const [tableToAdd, setTableToAdd] = useState<string>('');

    const addTable = () => {
        if (tableToAdd && tableList.indexOf(tableToAdd) === -1) {
            setTableList([...tableList, tableToAdd]);
            setTableToAdd('');
        }
    };

    const deleteTable = (tableName: string) => {
        setTableList(tableList.filter(table => table !== tableName));
    };

    return (
        <table className="mgmt-tsunami-configuration-table-select">
            <thead>
                <tr>
                    <th>Table Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {tableList.map(table => (
                    <tr key={table}>
                        <td>{table}</td>
                        <td>
                            <input type="button" onClick={() => deleteTable(table)} value="Delete" />
                        </td>
                    </tr>
                ))}
                <tr>
                    <td>
                        <input
                            type="text"
                            name="tableName"
                            value={tableToAdd}
                            onChange={(e) => setTableToAdd(e.target.value)}
                        />
                    </td>
                    <td>
                        <input type="button" onClick={addTable} value="Add" />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default TableSelect;
