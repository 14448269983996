import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { Accordion } from "../../../common";
import { ChannelActionType } from "../../ChannelComponent/ChannelComponent.types";
import { ProductHierarchyComponentProps } from "./ProductHierarchyComponent.types";

const ProductHierarchyComponent: React.FC<ProductHierarchyComponentProps> = (props: ProductHierarchyComponentProps) => {
    const {onChange, data} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }
    return (
        <Accordion title={"Product Hierarchy"} isOpen={isOpen} onOpen={toggleIsOpen} onClose={toggleIsOpen}>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|prodHierarchyType'>Type</label>
                <select id='channel|prodHierarchyType'
                        name='prodHierarchyType'
                        value={data?.type ?? ""}
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            props.onChange({
                                type: ChannelActionType.SET_PRODUCT_HIERARCHY_TYPE,
                                payload: event.target.value
                            });
                        }}>
                    <option value=""></option>
                    <option value={"include"}>include</option>
                    <option value={"exclude"}>exclude</option>
                </select>
            </div>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|businessGroups'>Business Group Codes</label>
                <textarea id='channel|businessGroups'
                          name='businessGroups'
                          value={data?.businessGroups ?? ""}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              onChange({
                                  type: ChannelActionType.SET_PRODUCT_HIERARCHY_BG,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|business'>Business Unit Codes</label>
                <textarea id='channel|business'
                          name='business'
                          value={data?.business ?? ""}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              onChange({
                                  type: ChannelActionType.SET_PRODUCT_HIERARCHY_BU,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|majorArticleGroups'>Major Article Group Codes</label>
                <textarea id='channel|majorArticleGroups'
                          name='subCategories'
                          value={data?.majorArticleGroups ?? ""}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              onChange({
                                  type: ChannelActionType.SET_PRODUCT_HIERARCHY_MAG,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
            <div className='mgmt-form-group'>
                <label htmlFor='channel|articleGroups'>Article Group Codes</label>
                <textarea id='channel|articleGroups'
                          name='articleGroups'
                          value={data?.articleGroups ?? ""}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              onChange({
                                  type: ChannelActionType.SET_PRODUCT_HIERARCHY_AG,
                                  payload: event.target.value
                              });
                          }}/>
            </div>
        </Accordion>
    );
};

export default ProductHierarchyComponent;