import { Accordion, Dropdown, ShowIf } from "../../common";
import React, { ChangeEvent, useState } from "react";
import { ChannelActionType } from "../ChannelComponent/ChannelComponent.types";
import { FiltersProps } from "./Filters.types";
import FormGroup from "../../common/FormGroup";
import ObjectTypesComponent from "./ObjectTypes";
import ProductTypesComponent from "./ProductTypes";
import WebNavigationComponent from "./WebNavigation";
import ProductHierarchyComponent from "./ProductHierarchy";
import { INCLUDE_EXCLUDE_TYPES } from "../../../services/channel.types";

import '../../../common.css';

const Filters: React.FC<FiltersProps> = (props: FiltersProps) => {
    const {
        audience, contexts, trees, catalogs, refurbished, sopOffsetInDays, eopOffsetInDays, sompOffsetInDays,
        organizationCode, objectTypes, productTypes, productHierarchy, webNavigation
    } = props.filter;

    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    const [catalogsOpen, setCatalogsOpen] = useState(false);
    const toggleCatalogsOpen = () => {
        setCatalogsOpen(!catalogsOpen);
    }

    const [organizationCodeOpen, setOrganizationCodeOpen] = useState(false);
    const toggleOrganizationCodeOpen = () => {
        setOrganizationCodeOpen(!organizationCodeOpen);
    }

    const getId = (fieldName: string): string => {
        return `${props.channelId}|${fieldName}`;
    };

    const setAudience = (audience: string): void => {
        props.onChange({type: ChannelActionType.SET_AUDIENCE, payload: audience});
    }

    const setContexts = (contexts: string): void => {
        props.onChange({type: ChannelActionType.SET_CONTEXTS, payload: contexts});
    }

    const setTrees = (trees: string): void => {
        props.onChange({type: ChannelActionType.SET_TREES, payload: trees});
    }

    const getCatalogCodeLabelClass = (): string => {
        return props.isNewChannel && props.showProductRelatedItems ? 'mgmt-required-field' : ''
    }

    return (
        <Accordion title={"Filters"} isOpen={isOpen} onOpen={toggleIsOpen} onClose={toggleIsOpen}>
            <ShowIf expression={!props.showClassificationRelatedItems}>
                <div className='mgmt-form-group'>
                    <label className={props.isNewChannel ? 'mgmt-required-field' : ''}
                           htmlFor='channel|audience'>Audience</label>
                    <select id='channel|audience'
                            required={true}
                            name='audience'
                            value={audience}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                setAudience(event.target.value);
                            }}>
                        <option value={"B2B"}>B2B</option>
                        <option value={"B2C"}>B2C</option>
                    </select>
                </div>
            </ShowIf>
            <div className='mgmt-form-group'>
                <label className={props.isNewChannel ? 'mgmt-required-field' : ''}
                       htmlFor='channel|contexts'>Contexts (line or comma separated, e.g. en_AA,en_US)</label>
                <textarea id='channel|contexts'
                          name='contexts'
                          value={contexts}
                          cols={20}
                          rows={4}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                              setContexts(event.target.value);
                          }}/>
            </div>
            <ShowIf expression={props.showClassificationRelatedItems}>
                <div className='mgmt-form-group'>
                    <label className={props.isNewChannel ? 'mgmt-required-field' : ''}
                           htmlFor='channel|trees'>Objects to export (line or comma separated, e.g. GDM-ProductTree,HealthSystemsNavigation</label>
                    <textarea id='channel|trees'
                        name='trees'
                        value={trees}
                        cols={20}
                        rows={4}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                            setTrees(event.target.value);
                        }}/>
                </div>
            </ShowIf>
            <ShowIf expression={props.showProductRelatedItems}>
                <div className='mgmt-form-group'>
                    <label htmlFor='channel|refurbished'>Refurbished products</label>
                    <select id='channel|refurbished'
                            required={true}
                            name='refurbished'
                            value={refurbished}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                props.onChange({
                                    type: ChannelActionType.SET_REFURBISHED,
                                    payload: event.target.value
                                });
                            }}>
                        <option value={"includeAll"}>Include all products</option>
                        <option value={"include"}>Include refurbished only</option>
                        <option value={"exclude"}>Exclude refurbished</option>
                    </select>
                </div>
            </ShowIf>
            <Accordion title={"Catalogs"} isOpen={catalogsOpen} onOpen={toggleCatalogsOpen}
                       onClose={toggleCatalogsOpen}>
                <div className='mgmt-form-group'>
                    <label className={getCatalogCodeLabelClass()}
                        htmlFor='channel|catalogs'>Catalog codes (line or comma separated, e.g. CONSUMER,CARE)</label>
                    <textarea id='channel|catalogs'
                              name='catalogs'
                              value={catalogs}
                              cols={20}
                              rows={4}
                              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                  props.onChange({
                                      type: ChannelActionType.SET_CATALOGS,
                                      payload: event.target.value
                                  });
                              }}/>
                </div>
                <FormGroup id={getId('sopOffsetInDays')}
                           label="SOP Offset (days)"
                           inputType="number"
                           min={0}
                           value={sopOffsetInDays ?? ""}
                           onChange={(event: ChangeEvent<HTMLInputElement>) => {
                               const value = event.target.value;
                               props.onChange({
                                   type: ChannelActionType.SET_SOP_OFFSET,
                                   payload: value === "" ? null : Number(value)
                               });
                           }}
                />
                <ShowIf expression={!props.showProductGroupRelatedItems}>
                    <FormGroup id={getId('sompOffsetInDays')}
                               label="SOMP Offset (days)"
                               inputType="number"
                               min={0}
                               value={sompOffsetInDays ?? ""}
                               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                   const value = event.target.value;
                                   props.onChange({
                                       type: ChannelActionType.SET_SOMP_OFFSET,
                                       payload: value === "" ? null : Number(value)
                                   });
                               }}
                    />
                </ShowIf>
                <FormGroup id={getId('eopOffsetInDays')}
                           label="EOP Offset (days)"
                           inputType="number"
                           min={0}
                           value={eopOffsetInDays ?? ""}
                           onChange={(event: ChangeEvent<HTMLInputElement>) => {
                               const value = event.target.value;
                               props.onChange({
                                   type: ChannelActionType.SET_EOP_OFFSET,
                                   payload: value === "" ? null : Number(value)
                               });
                           }}
                />
            </Accordion>
            <ShowIf expression={!props.showProductGroupRelatedItems}>
                <Accordion title={"Organization Code"} isOpen={organizationCodeOpen} onOpen={toggleOrganizationCodeOpen}
                           onClose={toggleOrganizationCodeOpen}>
                    <div className='mgmt-form-group'>
                        <label htmlFor='channel|orgCodeType'>Type</label>
                        <Dropdown
                            items={INCLUDE_EXCLUDE_TYPES.map(value => {
                                return {id: value, displayValue: value};
                            })}
                            selectedItem={organizationCode?.type || "none"}
                            onSelect={(selected) => {
                            props.onChange({
                                type: ChannelActionType.SET_ORGANIZATION_CODE_TYPE,
                                payload: selected
                            });
                        }}/>
                    </div>
                    <div className='mgmt-form-group'>
                        <label htmlFor='channel|orgCodeCodes'>Codes (comma separated, e.g. R8989, R6843)</label>
                        <textarea id='channel|orgCodeCodes'
                                  name='codes'
                                  value={organizationCode?.codes || ""}
                                  cols={20}
                                  rows={4}
                                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                      props.onChange({
                                          type: ChannelActionType.SET_ORGANIZATION_CODE_CODES,
                                          payload: event.target.value
                                      });
                                  }}/>
                    </div>
                </Accordion>
            </ShowIf>
            <ObjectTypesComponent data={objectTypes!} showProductGroupRelatedItems={props.showProductGroupRelatedItems} getId={getId} onChange={props.onChange} />
            <ShowIf expression={!props.showProductGroupRelatedItems}>
                <ProductTypesComponent data={productTypes!} getId={getId} onChange={props.onChange} />
            </ShowIf>
            <ShowIf expression={props.showProductRelatedItems}>
                <WebNavigationComponent data={webNavigation!} onChange={props.onChange} />
                <ProductHierarchyComponent data={productHierarchy!} getId={getId} onChange={props.onChange} />
            </ShowIf>
        </Accordion>
    );
}

export default Filters;